<template>
  <div class="loading-wrapper" v-if="isActive">
      <div v-if="type == 'request'" class="loading-popup popup-request">
          <div class="h-100 mt-1 ml-5 mr-5 mb-1" style="padding: 10px 15px; display: flex;">
              <div v-if="title" class="my-4">
                  <div style="display: flex; align-items: flex-start;">
                    <img src="../assets/icons/meet-notification.svg" alt="">
                    <h2 class="popup-title">{{title}}</h2>
                  </div>
              </div>

              <div style="align-self: center; text-align: left;">
                  <span class="message"><span style="font-weight: 700;">{{client}}</span> wants to <br> join the call </span>
              </div>
              <div style="display: flex; margin-left: 20px;">
                <button class="circle-button-close" @click="decline()"><img src="../assets/icons/meet-close.svg" width="30" alt=""></button>
                <button class="circle-button-confirm" @click="confirm()"><img src="../assets/icons/meet-tick.svg" width="30" alt=""></button>
              </div>
          </div>
      </div>
      <div v-if="type == 'recording-start'" class="loading-record popup-record" :style="isMobileView ? 'min-width: 350px' : ''">
        <div><iconClose :color="'#000'" @click="cancelRecording()" :size="isMobileView ? 18 : 22" style="position: absolute; right: 10px; top: 10px"/></div>
          <div class="h-100 mt-1 ml-5 mr-5 mb-1" style="padding: 10px 15px; display: grid; justify-items: center;">
                <button :disabled="!hasAudio && !hasVideo && !recordingNow" :style="!hasAudio && !hasVideo && !recordingNow ? 'opacity: 0.5;' : ''" class="button-record" @click="toggleRecording()">{{ recordingNow ? cancelButton : confirmationButton }}</button>
              <div class="my-4">
                  <div style="display: flex; align-items: flex-start;">
                    <h2 class="popup-message">{{!hasAudio && !hasVideo && !recordingNow ? 'Recording cannot be activated when both Video & Audio are disabled' : message}}</h2>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="type == 'disclaimer'" class="popup-disclaimer popup-record" :style="isMobileView ? 'min-width: 350px' : ''">
        <div><iconClose :color="'#000'" @click="closeDisclaimer()" :size="isMobileView ? 18 : 22" style="position: absolute; right: 10px; top: 10px"/></div>
          <div class="h-100 mt-1 ml-5 mr-5 mb-1" style="padding: 10px 15px; display: grid; justify-items: center;">
              <div class="my-4">
                  <div style="max-height: 75vh; overflow-y: auto;">
                    <Disclaimer :textColor="'#000'" />
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import iconClose from './icons/close.vue'
import Disclaimer from "@/components/LbrxDisclaimer";
  export default {
      name: 'LbrxPopupMeet',
      props: {
          active: Boolean,
          title: String,
          message: String,
          hint: String,
          type: String,
          confirmationButton: String,
          cancelButton: String,
          client: String,
          recordingNow: Boolean,
          hasAudio: Boolean,
          hasVideo: Boolean,
          isMobileView: Boolean
      },
      methods: {
          confirm(){
              this.$emit('confirm');
          },
          decline(){
              this.$emit('decline');
          },
          toggleRecording() {
            if(this.recordingNow) {
                this.stopRecording()
            } else {
                this.startRecording()
            }
          },
          startRecording(){
              this.$emit('startRecording');
          },
          stopRecording(){
              this.$emit('stopRecording');
          },
          cancelRecording(){
              this.$emit('cancelRecording');
          },
          closeDisclaimer(){
              this.$emit('closeDisclaimer');
          }
      },
      computed: {
          isActive(){
              return this.active;
          }
      },
      components: {
        iconClose,
        Disclaimer
      }
  }
</script>

<style>
  .loading-wrapper{
      background: rgba(0, 0, 0, 0.70);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999999999999999999999999998;
  }

  .loading-popup{
      /* width: 90%; */
      min-width: 320px;
      max-width: 500px;
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #264076;
      z-index: 9999999999999999999999999999999;
  }

  .loading-record{
      /* width: 90%; */
      min-width: 420px;
      max-width: 500px;
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #264076;
      z-index: 9999999999999999999999999999999;
  }

  .popup-disclaimer{
      /* width: 90%; */
      /* min-width: 420px;
      max-width: 500px; */
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #264076;
      z-index: 9999999999999999999999999999999;
  }

  .loader{
      width: 50px;
      height: 50px;
  }

  .popup-request .popup-title {
    color: var(--Medium-Gray, #8A8A8A);
    font-size: 24px;
    font-weight: 300;
    margin: 0 15px;
  }

  .popup-request  .icon {
      width: 50px;
      margin: 10px 10px;
  }

  .popup-request  .message {
    color: var(--Dark-Gray, #444);
    font-size: 20px;
    font-weight: 200;
    line-height: 25px;
    text-align: left;
  }

  .popup-request  .hint {
      color: #333;
      font-weight: bold;
      font-size: 16px;
  }

  .popup-request .btn-confirm {
    color: var(--primary);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    border: none;
    background-color: #fff;
    padding: 0;
  }

  .popup-request .btn-cancel {
    color: var(--offline);
    font-size: 24px;
    font-weight: 300;
    border: none;
    background-color: #fff;
    padding: 0;
  }

  .popup-request .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
      color: #fff;
      background-color: #72b53a;
      border-color: #72b53a;
  }

  .popup-request .btn-confirm:focus, .btn-confirm.focus {
      box-shadow: none;
  }

  .circle-button-confirm {
    width: 50px; /* Set the width and height to create a circle */
    height: 50px;
    margin: 7px 7px;
    border: none;
    background-color: var(--success); /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}
  .circle-button-close {
    width: 50px; /* Set the width and height to create a circle */
    height: 50px;
    margin: 7px 7px;
    border: none;
    background-color: var(--danger); /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}

.popup-record .popup-message {
    color: var(--Medium-Gray, #8A8A8A);
    font-size: 18px;
    font-weight: 400;
    margin: 0 15px;
  }

.button-record {
    margin: 20px 7px 0 7px;
    padding: 10px 15px;
    border: none;
    background-color: var(--danger); /* Button background color */
    color: #fff;
    border-radius: 5px; /* Make the button a perfect circle */
    cursor: pointer;
    width: fit-content;
}

</style>