<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 12.0001C24 12.0001 18.1081 20 15.9999 20C13.8918 20 8 12 8 12" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>

export default {
  name: 'arrowDownIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>