<template>
  <div v-if="date" class="booking-single-day">
    <div class="booking-single-day-header" :class="{'todayHeader': isToday}" :style="{'border-right': $i18n.locale === 'ar' ? '3px solid #008FCA': '','border-left': $i18n.locale !== 'ar' ? '3px solid #008FCA': ''}" >
      <div class="day-tag">{{ moment(date).locale($i18n.locale).format('dddd') }}</div>
      <div class="day-date-tag">{{ moment(date).locale($i18n.locale).format('D') }},
        {{ moment(date).locale($i18n.locale).format('MMMM') }}
      </div>
    </div>

    <div v-if="date.bookings" class="bookings">
        <div @click="openDetails(booking)"  :id="'booking-'+booking.id" v-for="(booking, index) in date.bookings" :key="index" class="single-booking">
            <div class="data-container">
                <div v-if="booking.fname ||  booking.lname" class="booking-user">{{ booking.fname }} {{ booking.lname }}</div>
                <div v-else class="booking-user">{{ $t('Teller.Anonyme') }}</div>
                <div class="booking-service">{{ booking.service_names }}</div>
            </div>
            <div class="booking-time"
                 :style="{'margin-left': $i18n.locale !== 'ar' ? '-30%': '', 'margin-right': $i18n.locale === 'ar' ? '-30%': ''}">
                {{ moment(booking.start_datetime).locale($i18n.locale).format('H:mm') }}
            </div>
        </div>
    </div>
  </div>

  <lbrx-booking-details-popup v-if="showBookingsDetails && clickedBooking" @close="closeDetails"
                              v-model="showBookingsDetails" :booking="clickedBooking" :date="stringDate"
                              :agency-info="agency"></lbrx-booking-details-popup>
</template>

<script>
import LbrxBookingDetailsPopup from "@/components/LbrxBookingDetailsPopup.vue";
import moment from "moment/moment";
import {businessService} from "@/_services";

export default {
  name: 'LbrxCalendarDay',
  components: {LbrxBookingDetailsPopup},
  props: {
    date: Object,
  },
  emits: ['details-shown'],
  computed: {
    moment() {
      return moment
    },
    isToday() {
      return new Date().toDateString() === this.date.toDateString();
    }

  },
  methods: {
    openDetails(booking) {
      if (this.closing)
        return;

      businessService.BookingById(booking.id).then((res) => {
        this.$emit('details-shown', true);

        this.clickedBooking = res.booking;

        if (this.clickedBooking.member){
          let deps = this.clickedBooking.member.department_relation.filter((dep) => {
            return dep.id === this.clickedBooking.department_id
          })

          if (deps.length > 0) {
            this.clickedBooking.dep = deps[0]
          }
        }


        this.showBookingsDetails = true;
      })
    },
    closeDetails() {
      this.closing = true;
      this.showBookingsDetails = false;
      this.clickedBooking = null;
      setTimeout(() => {
        this.$emit('details-shown', false)
        this.closing = false;
      }, 1);
    },
    getServicesString(booking) {
      if (!booking.services)
        return '';
      let s = '';
      for (let i = 0; i < booking.services.length; i++) {
        s += booking.services[i].label;
        if (i + 1 < booking.services.length) {
          s += ", ";
        }
      }
      return s;
    }
  },
  created() {
    if (this.date) {
      this.stringDate = this.date.toLocaleDateString();
      // this.clickedBooking = this.date.bookings[0];
      // this.showBookingsDetails =  true;
    }

  },
  data() {
    return {
      closing: false,
      agency: {department: 'Guichet', agent: {name: 'Rami Henia'}},
      stringDate: '',
      showBookingsDetails: false,
      clickedBooking: null,
      monthNames: ["Janvier", "Février", "Mars", 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", 'Jeudi', 'Vendredi', 'Samedi'],
    }
  }
}
</script>

<style scoped>

@media (max-width: 815px) {
    .booking-single-day-header {
        width: 100% !important;
        position: static !important;
    }
    .bookings {
        padding-top: 3vmin !important;
        overflow-y: auto !important;
        max-height: 70vh !important;
        width: 100% !important;
    }
}
.day-date-tag {
  display: flex;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.day-tag {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.4vmin;
  line-height: 2.4vmin;
  /* identical to box height */


  /* Dark Gray */

  color: #444444;
}

.booking-single-day {
  min-width: 100% !important;
  min-height: 100% !important;
}

.booking-single-day-header {
  height: 9.5vmin;
  padding: 2vmin;
  position: relative;
  width: 100%;
  background: rgba(242, 242, 242, 0.5);
}

.todayHeader {
  background: linear-gradient(127.19deg, rgba(0, 143, 202, 0.8) 0.56%, rgba(75, 176, 218, 0.8) 76.01%);
}

.todayHeader .day-date-tag {
  color: white !important;
}

.todayHeader .day-tag {
  color: white !important;
}

.bookings {
  padding-top: 10vmin;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.single-booking {

  cursor: pointer;
  background: #FFFFFF;
  /* Gray Light */
  padding: 1vmin;
  border: 1px solid #E3E3E3;
  height: 7vmin;
  margin-top: 0.7vmin;
  margin-bottom: 0.7vmin;
  border-radius: 10px;
  display: flex;
}

.single-booking:hover {
  opacity: 0.8;
}

.booking-user {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2vmin;
  line-height: 2.3vmin;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 65%;
  color: #008FCA;
}

.booking-service {
  margin-top: 0.5vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.7vmin;
  line-height: 1.7vmin;
  height: 70%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8A8A8A;

}

.booking-time {
  margin-left: -2vmin;
  margin-right: -2vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2vmin;
  line-height: 2vmin;
  /* identical to box height */


  /* Dark Gray */

  color: #444444;
}

.data-container {
  width: 100%;
}
</style>