<template>
    <svg :width="size" :height="size" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.3268 10.6194L47.7529 21.5612C48.4928 23.0844 50.4659 24.5453 52.1308 24.8251L61.9655 26.4726C68.2548 27.5295 69.7346 32.13 65.2026 36.6684L57.5568 44.3774C56.262 45.6829 55.5529 48.2008 55.9537 50.0037L58.1426 59.5467C59.8691 67.1003 55.892 70.0222 49.2636 66.0745L40.0454 60.5725C38.3806 59.5778 35.6367 59.5778 33.9411 60.5725L24.723 66.0745C18.1253 70.0222 14.1175 67.0692 15.8439 59.5467L18.0329 50.0037C18.4336 48.2008 17.7246 45.6829 16.4297 44.3774L8.78388 36.6684C4.28271 32.13 5.73172 27.5295 12.021 26.4726L21.8558 24.8251C23.4898 24.5453 25.4629 23.0844 26.2028 21.5612L31.6289 10.6194C34.5885 4.68221 39.398 4.68221 42.3268 10.6194Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>