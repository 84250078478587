function server() {
    if (
        process.env.VUE_APP_EDIT_HOST === "true" &&
        localStorage.getItem("host") != null
    ) {
        return localStorage.getItem("host");
    } else {
        switch (localStorage.getItem("server")) {
            case "tn":
                return process.env.VUE_APP_API_URL_TN;
            case "ci":
                return process.env.VUE_APP_API_URL_CI;
            default:
                return process.env.VUE_APP_API_URL_TN;
        }
    }
}

export const ApiConfigs = {
    base_kiosk_url: server() + "/kiosk",
    base_url: server() + "/business",
    base_user_url: server() + "/user",
    broadcast_url: server() + "/broadcasting",
    openvidu_url: server(),
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth",
    },
    // ACCOUNT
    accounts: {
        captcha: process.env.VUE_APP_CAPTCHA_LINK,
        login: "/account/auth",
        login_no_recaptcha: "/account/auth/old",
        logout: "/account/logout",
        update_password: "/account/password/update",
        reset_password: "/account/password/reset",
        reset_update_password: "/account/password/reset/update",
        check_email: "/account/checkemail/:email",
        update: "/account/update",
        register: "/account/register",
        getBusinessInformations: "/account/informations"
    },

    /* -------------------------------- */
    // CUSTOMERS
    customers: {
        getAll: "/customer?pagination=1000",
        get: "/customer/:id",
        pagination: "/customer?page=",
        filter: "/customer/filter/",
        create: "/customer/create",
        update: "/customer/:id/update",
        _delete: "/customer/:id/delete",
    },
    /* -------------------------------- */
    // PRIVATE SERVICES
    kiosks: {
        getAll: "/kiosk?pagination=off",
        pagination: "/kiosk?page=",
        get: "/kiosk/:id",
        filter: "/kiosk/filter/:keyword",
        create: "/kiosk/create",
        update: "/kiosk/:id/update",
        _delete: "/kiosk/:id/delete",
        resetPassword: "/kiosk/:id/reset",
        // Kiosk APP
        login: "/auth",
        autoLogin: "/auth/:key/:token",
        logout: "/logout",
        findCustomerByPhone: "/customer/search",
        createCustomer: "/customer/create",
        joinQueue: "/queue/push",
        refresh: "/refresh",
        createBooking: "/booking/create",
    },
    /* -------------------------------- */
    // BUSINESS
    business: {
        update: "/update",
        updateLocation: "/location/update",
        update_working_days: "/workingdays/update",
        getCounterAgentStats: "/statistic/tellerStat",
        getKiosksStatus: "/kiosk/status",
        getDisplaysStatus: "/display/status",
        refreshKiosk: "/kiosks/:id/refresh",
        refreshDisplay: "/displays/:id/refresh",
        refresh:"/account/update",
        getTopics:"/meet-topic",
    },
    /* -------------------------------- */
    // HELPERS
    helpers: {
        getAllServices: "/helpers/service?pagination=1000",
        filterServices: "/helpers/setting/filter/:keyword",
        getIndustries: "/industry",
        getCountries: "/countries",
        getPaymentTypes:"/type-payment"
    },
    /* -------------------------------- */
    // QUEUES
    queues: {
        displayCall: "/queue/:id/displayCall",
        moveToLast: "/queue/:id/moveToLast",
        absent: "/queue/:id/absent",
        // QUEUE RELATED
        getAll: "/queue?pagination=off",
        getServicesQueues:"/queue/getforservice",
        getAllWithSummary: "/queue/summary?pagination=off",
        get: "/queue/:id",
        getWithSummary: "/queue/:id/summary",
        pagination: "/queue?page=",
        paginationWithSummary: "/queue/summary?page=",
        create: "/queue/create",
        update: "/queue/:id/update",
        _delete: "/queue/:id/delete",
        getInService: "/queue/:id/inservice",
        getWaitingList: "/queue/:id/waitinglist",
        addDuration: "/queue/:queue_id/ticket/:ticket_id",
        updateServices: "/queue/:queue_id/ticket/:ticket_id",
        onHold: "/queue/:queue_id/hold/:ticket_id",
        recallOnHold: "/queue/:queue_id/recall/:ticket_id",
        getOnHoldTickets: "/queue/:queue_id/onhold",
        // TICKET RELATED
        push: "/queue/:id/push",
        revoke: "/queue/:queue_id/revoke/:customer_id",
        swap: "/queue/:queue_id/swap/:customer_1/:customer_2",
        pushBack: "/queue/:queue_id/pushback/:ticket_id",
        updateMember: "/queue/:queue_id/member/update/:customer_id",
        noshow: "/queue/:queue_id/noshow/:customer_id",
        redirect: "/queue/:queue_id/redirect/:customer_id/:target_id",
        callNext: "/queue/:queue_id/call",
        endTicket: "/queue/endTicket",
        getTicketByUid: "/queue/uid/:uid",
        getArchivedTicketByUid: "/queue/archive/uid/:uid",
        getArchivedTickets: "/queue/:queue_id/archive",
        submitFeedbackByUid: "/queue/uid/:uid/feedback",
        cancelByUid: "/queue/uid/:uid/cancel",
        // DELAY RELATED
        createDelay: "/queue/:id/delay",
        pause: "/queue/:id/pause",
        resumeService: "/queue/:id/resume",
        ticketPrice: "/queue/:queue_id/ticket/:ticket_id/price",
        ticketNote: "/queue/:queue_id/ticket/:ticket_id/notes"
    },
    // QUEUE REQUESTS
    queue_requests: {
        getAll: "/queue/:queue_id/requests?pagination=off",
        get: "/queue/:queue_id/request/:id",
        pagination: "/queue/:queue_id/requests?page=",
        update: "/queue/:queue_id/request/:id/update",
        approve: "/queue/:queue_id/request/:id/approve",
        decline: "/queue/:queue_id/request/:id/decline",
        _delete: "/queue/:queue_id/request/:id/delete",
    },
    // COUNTERS
    counters: {
        getAll: "/queue/:queue/counter",
        get: "/queue/:queue/counter/:counter",
        create: "/queue/:queue/counter/create",
        update: "/queue/:queue/counter/:counter/update",
        _delete: "/queue/:queue/counter/:counter/delete",
        open: "/queue/:queue/counter/:counter/open",
        close: "/queue/:queue/counter/:counter/close",
    },

    // BOOKINGS
    bookings: {
        getAll: "/booking",
        getByRange: "/booking/range?pagination=off",
        getConfirmed: "/booking",
        getPending: "/booking/requests",
        getArchive: "/booking/archived",
        get: "/booking/:id",
        availability: "/booking/availability",
        create: "/booking/create",
        cancelBooking: "/booking/cancel",
        cancelRequest: "/booking/requests/:id/cancel",
        getServicesByDepartement: "/getBydepartment",
        getSlots:"/booking/available/:date?dep=:dep",
        getDepServices:"/department/:dep_id/services"
    },

    meet:{
        join:"/meet/join",
        checkRoomName: "/meet/:room",
        checkPinCode: "/meet/pin-check",
        getByRange: "/meet/range",
        acceptParticipant: "/meet/accept-participant",
        getMeetMessages: "/meet/:id/messages",
        sendMeetMessage: "/meet/message/send",
        sendMeetMessageParticipant: "/meet/message/send",
        sendFeedback: "/meet/:id/feedback",
        endCallbyId: "/meet/:id/end-call",
        changeCamStatus: "/meet/:id/change-cam-status/",
        changeMicStatus: "/meet/:id/change-mic-status/",
        downloadFile: "/meet/message/:id/download-file",
        downloadFileParticipant: "/meet/message/:id/download-file"
    },

    openvidu:{
        createToken:"/sessions/:id/connection",
        join:"/meet/join",
        checkRoomName: "/sessions/:room",
        checkPinCode: "/meet/pin-check",
        startRecording: "/recordings/start",
        stopRecording: "/recordings/stop/:id",
        getByRange: "/meet/range",
        acceptParticipant: "/meet/accept-participant",
        getMeetMessages: "/meet/:id/messages",
        sendMeetMessage: "/meet/message/send",
        sendMeetMessageParticipant: "/meet/message/send",
        sendFeedback: "/meet/:id/feedback",
        endCallbyId: "/meet/:id/end-call",
        changeCamStatus: "/meet/:id/change-cam-status/",
        changeMicStatus: "/meet/:id/change-mic-status/",
        downloadFile: "/meet/message/:id/download-file",
        downloadFileParticipant: "/meet/message/:id/download-file"
    },

    vendors: {
        getVendorById: "/vendors/:id"
    }
};
