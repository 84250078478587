<template>
    <svg :width="sizeW" :height="sizeH" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5413 1.79165C26.9103 2.31284 28.5566 2.9723 30.0542 3.77974C32.2691 4.97393 33.2187 7.12583 32.958 9.20229C32.8478 10.0799 31.999 10.4995 31.0148 10.2725L28.7927 9.75996C27.0315 9.3537 26.1509 9.15058 25.6139 8.61327C25.077 8.07596 25.0138 7.33467 24.8874 5.85209L24.5413 1.79165ZM24.5413 1.79165C19.7462 0.736728 14.2594 0.735503 9.45871 1.79165M9.45871 1.79165C7.08969 2.31284 5.44338 2.9723 3.9458 3.77974C1.73092 4.97393 0.781349 7.12583 1.04205 9.20229C1.15223 10.0799 2.00101 10.4995 2.98525 10.2725L5.20727 9.75996C6.96851 9.3537 7.84913 9.15058 8.38609 8.61327C8.92305 8.07596 8.98623 7.33467 9.11261 5.85209L9.45871 1.79165Z" :stroke="color" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    sizeH: Number,
    sizeW: Number,
    color: String
  }
}
</script>