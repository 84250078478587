import messageSound from '../assets/sounds/message.mp3';
import requestSound from '../assets/sounds/request.mp3';

function playSound(sound: string) {
    const audio = new Audio(sound);
    audio.play();
}

export const notificationPlay = {
    message: () => playSound(messageSound),
    request: () => playSound(requestSound),
};