import {ApiConfigs, authHeader, authHeaderOpenvidu} from "../_helpers";

export const openviduService = {
    createToken,
    joinMember,
    joinParticipant,
    checkRoomName,
    startRecording,
    stopRecording,
    checkPinCode,
    getBookingByRange,
    acceptParticipant,
    getMeetMessages,
    sendMeetMessage,
    sendMeetMessageParticipant,
    endCallbyId,
    logoutParticipant,
    sendFeedback,
    changeCamStatus,
    changeMicStatus,
    downloadFile,
    downloadFileParticipant,
};

function createToken(room_name: string, payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {...authHeaderOpenvidu(), "Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };

    return fetch(
        `${ApiConfigs.openvidu_url + ApiConfigs.openvidu.createToken.replace(':id', room_name)}`,
        requestOptions
    )
        .then(handleResponse)
        .catch(handleRejected);
}

function joinMember(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.meet.join}`,
        requestOptions
    )
        .then(handleResponse)
        .catch(handleRejected);
}

function joinParticipant(payload: object) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.join}`,
        requestOptions
    )
        .then(handleResponse)
        .catch(handleRejected);
}


function checkRoomName(room_name: string, webrtc=null, pendingConn=null) {
    const requestOptions = {
        method: "GET",
        headers: {...authHeaderOpenvidu(), "Content-Type": "application/json"},
    };
    let params = webrtc ? 'webRtcStats='+webrtc : '';
    params += (!webrtc && pendingConn) ? '?' : (webrtc && pendingConn) ? '&' : '';
    params += pendingConn ? 'pendingConnections='+pendingConn : '';

    return fetch(
        `${ApiConfigs.openvidu_url + ApiConfigs.openvidu.checkRoomName.replace(':room', room_name) + params}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}


function startRecording(payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeaderOpenvidu(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    // return fetch(`${ApiConfigs.openvidu_url+ApiConfigs.openvidu.startRecording}`, requestOptions).then(handleResponse).catch(handleRejected);
    return fetch(`https://rtc.liberrex.com/openvidu/api/recordings/start`, requestOptions).then(handleResponse).catch(handleRejected);
}

function stopRecording(sessionId: string) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeaderOpenvidu(), 'Content-Type': 'application/json' }
    };

    // return fetch(`${ApiConfigs.openvidu_url+ApiConfigs.openvidu.stopRecording.replace(':id', sessionId)}`, requestOptions).then(handleResponse).catch(handleRejected);
    return fetch(`https://rtc.liberrex.com/openvidu/api/recordings/stop`, requestOptions).then(handleResponse).catch(handleRejected);
}

function checkPinCode(payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.checkPinCode}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getBookingByRange(start: string, end: string) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({start: start, end: end})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.meet.getByRange}`, requestOptions).then(handleResponse)
}

function acceptParticipant(payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.meet.acceptParticipant}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getMeetMessages(id: string, per_page: number, current_page: number) {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.getMeetMessages.replace(':id', id)}?per_page=${per_page}&current_page=${current_page}`,
        requestOptions
    ).then(handleResponse).catch(handleRejected);
}

function sendMeetMessage(payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.meet.sendMeetMessage}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function sendMeetMessageParticipant(payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.sendMeetMessage}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function endCallbyId(id: string) {
    const requestOptions = {
        method: "GET",
        headers: { ...authHeader(), "Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.endCallbyId.replace(':id', id)}`,
        requestOptions
    ).then(handleResponse).catch(handleRejected);
}

function sendFeedback(id: string, payload: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.sendFeedback.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function logoutParticipant() {
    // remove user from local storage to log user out
    localStorage.removeItem("customer");
}

function changeCamStatus(id: string, from: string, status: boolean) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };
    const path = from == 'agent' ? ApiConfigs.base_url : ApiConfigs.base_user_url;
    return fetch(`${path + ApiConfigs.meet.changeCamStatus.replace(':id', id)+status}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function changeMicStatus(id: string, from: string, status: boolean) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };
    const path = from == 'agent' ? ApiConfigs.base_url : ApiConfigs.base_user_url;
    return fetch(`${path + ApiConfigs.meet.changeMicStatus.replace(':id', id)+status}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function downloadFile(id: string) {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.downloadFile.replace(':id', id)}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}

function downloadFileParticipant(id: string) {
    const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.downloadFileParticipant.replace(':id', id)}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}

function handleResponse(response: any) {
    // console.log('fdfdfdfd', response)
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data) || response.statusText;
            return Promise.reject(error);
        } else {
            return data;
        }
    });
}

function handleRejected(exception: any) {
    console.log(exception);
    return "error";
}
