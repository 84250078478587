<template>
  <div class="home-container">
    <div class="home-header justify-content-between" :class="{'right-class': $i18n.locale === 'ar'}">
      <div :class="{'right-class': $i18n.locale === 'ar'}" id="userName" class="header-user mx-3">
        {{ $t('Teller.HelloCustomer') }} <b>{{ user.name }},</b><br>
        <span class="greetings">{{ $t('Teller.HelloWord') }}</span>
      </div>
      <div class="header-tools" :class="{'right-class': $i18n.locale === 'ar'}">


        <div class="setting-icon-container">
          <div class="icon-container mx-auto" style="align-self: flex-end;">
            <img @click="logout" src="../../assets/icons/logout-icon2.svg" class="setting-icon"/>
          </div>
          

        </div>
      </div>
    </div>

    <div class="second-home-header justify-content-between" :class="{'right-class': $i18n.locale === 'ar'}"></div>
    

    <div id="loader" v-if="(sending && queue)" :style="{'background':(sending && queue)?'rgba(255,255,255,0.87)':'unset'}"
         style="position: absolute;width: 100%;height: 100%;z-index: 10001522283">
      <div id="secondLoader" v-if="(sending && queue)" class="spinner-border" :style="{'color': '#008fca'}"
           style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin;position: absolute; top: 50%; right: 50%;"
           role="status">
      </div>
    </div>
    <div class="main-vue">
      <div class="row">
        <div class="col-md-9 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-md-9 m-0 p-0">
              <div v-touch:swipe="swipeHandler" class="box mobile-container vertical-ticket-h h-100">
                <lbrx-calls-list-box v-if="toDisplay == 1 " v-model="bookings" class="m-2 m-md-0"
                  :stat="customerStats"
                  v-on:payment="showPaymentPopup = true"
                  v-on:appointment="appointmentType = null;
                  showAppointmentPopup = true"
                  v-on:confirm-payment="payment($event)"
                  @enterCallRoom="enterCallRoom"
                  >
                </lbrx-calls-list-box>
                <div v-else style="height: 95% !important;display: flex;background: white;margin: 9px !important;">
                  <div v-if="toDisplay === 2" style="height: 100%;width: 100%;background: white;display: flex;justify-content: center">
                    <div style="margin: auto">
                    <lbrx-statistics-table :stat="customerStats"></lbrx-statistics-table>
                    </div>
                  </div>
                  <div v-else-if="toDisplay === 3" style="margin-top: auto;margin-bottom: auto;width: 99%">
                <lbrx-ticket-on-hold style="margin-top: auto;margin-bottom: auto" class="m-2 m-md-0"  v-model="counter_config" :waiting_list="onHoldTickets"
                                     v-on:endTicket="endTicket($event,'hold')"></lbrx-ticket-on-hold>
                  </div>
                <lbrx-queue-summary style="margin-top: auto;margin-bottom: auto" class="m-2 m-md-0" v-else-if="toDisplay === 4" v-model="queueSummary"></lbrx-queue-summary>
                <lbrx-statistics-box style="margin-top: auto;margin-bottom: auto;width: 100%;height: 100%" class="m-2 m-md-0" v-else-if="toDisplay === 5" v-model="counterStats" :has-amount="hasAmount"></lbrx-statistics-box>
                </div>
                  <div v-if="items" class="pointers home-pointers my-2">
                  <div v-for="item in items" :key="item" class="pointer" :class="{'active-pointer': item === toDisplay}"></div>
                </div>
              </div>
            </div>
         
          </div>
          
        </div>

        <div class="col-md-3 m-0 p-0">
          <div class="row h-100 m-0 p-0 right-boxes">
            <div class="h-auto m-0 p-0 d-none d-lg-block box vertical-ticket stat-container">
              
            </div>
            <div class="h-auto m-0 p-0 box console vertical-ticket">
      
            </div>
          </div>
        </div>
      </div>
    </div>
    <lbrx-toast id="lbrxToast" v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
                :nb_notifications="notifications"
                @close="showToastPopup = false"></lbrx-toast>
    <div class="footer-container" ref="footerContainer">
      <the-footer :internet="onLine"></the-footer>
    </div>
    <lbrx-session-configuration-popup v-if="showConfigPopup && queues.length>0"
                                      @close="configClosed()"
                                      v-model="showConfigPopup"
                                      :queue_lines="queues"></lbrx-session-configuration-popup>
    <lbrx-booking-popup v-if="showBookingPopup" @close="showBookingPopup = false"
                        v-model="showBookingPopup"></lbrx-booking-popup>
    <lbrx-queue-popup v-if="showQueuePopup" @close="showQueuePopup = false" v-model="showQueuePopup"
                      :type="queueType" :queue="queue"></lbrx-queue-popup>
    <lbrx-redirect-popup v-if="showRedirectPopup && queues.length>0"
                         v-model="showRedirectPopup" @close="showRedirectPopup = false"
                         :queue_lines="queues"
                         v-on:Redirect-trigger="Redirect($event)"></lbrx-redirect-popup>

    <lbrx-confirm-popup v-if="showConfirmPopup"
                        v-model="showConfirmPopup" @close="showConfirmPopup = false"
                        v-on:confirm="endTicket(currentTicket.item ? currentTicket.item.public_identifier : null,null)"></lbrx-confirm-popup>

    <lbrx-note-popup v-if="showNotePopup" :note="currentTicket.item.notes"
                     v-model="showNotePopup" @close="showNotePopup = false"
                     v-on:confirm-note="updateComment($event)"></lbrx-note-popup>

    <lbrx-payment-popup v-if="showPaymentPopup" :types="this.paymentTypes" :ticket="this.currentTicket.item"
                        v-model="showPaymentPopup" @close="showPaymentPopup = false"
                        v-on:confirm-payment="payment($event)"></lbrx-payment-popup>

    <lbrx-appointment-popup v-if="showAppointmentPopup"
                            v-model="showAppointmentPopup" :current_client="currentTicket.item" :queues="queues"
                            :type="appointmentType"
                            @close="showAppointmentPopup = false;appointmentType='new'"></lbrx-appointment-popup>

    <lbrx-devices-popup v-if="showDevicesPopup" @close="showDevicesPopup = false" v-model="showDevicesPopup"
                        :type="devicesType" :items="devicesType === 'displays'?displays:kiosks"></lbrx-devices-popup>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import {businessService, counterService, queueService, meetService} from "../../_services";
import LbrxCallsListBox from "@/components/LbrxCallsListBox.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxStatisticsBox from "@/components/LbrxStatisticsBox.vue";
import LbrxQueueSummary from "@/components/LbrxQueueSummary.vue";
import LbrxTicketOnHold from "@/components/LbrxTicketOnHold.vue";
import LbrxSessionConfigurationPopup from "@/components/LbrxSessionConfigurationPopup.vue";
import LbrxBookingPopup from "@/components/LbrxBookingPopup.vue";
import LbrxQueuePopup from "@/components/LbrxQueuePopup.vue";
import router from "@/router";
import moment from "moment/moment";
import LbrxDevicesPopup from "@/components/LbrxDevicesPopup.vue";
import LbrxRedirectPopup from "@/components/LbrxRedirectPopup.vue";
import LbrxToast from "@/components/LbrxToast.vue";
import LbrxConfirmPopup from "@/components/LbrxConfirmPopup.vue";
import LbrxNotePopup from "@/components/LbrxNotePopup.vue";
import LbrxPaymentPopup from "@/components/LbrxPaymentPopup.vue";
import LbrxAppointmentPopup from "@/components/LbrxAppointmentPopup.vue";
import LbrxStatisticsTable from "@/components/LbrxStatisticsTable.vue";
import store from "@/store";


export default {
  name: "HomePage",
  inject: ['echo'],
  components: {
    LbrxStatisticsTable,
    LbrxAppointmentPopup,
    LbrxToast,
    LbrxRedirectPopup,
    LbrxDevicesPopup,
    LbrxQueuePopup,
    LbrxBookingPopup,
    LbrxSessionConfigurationPopup,
    LbrxTicketOnHold,
    LbrxQueueSummary,
    LbrxStatisticsBox,
    TheFooter,
    LbrxCallsListBox,
    LbrxConfirmPopup,
    LbrxNotePopup,
    LbrxPaymentPopup
  },
  data() {
    return {
      noStatus: null,
      user: {},
      teller_config: {},
      hasAmount: false,
      counter_config: null,
      queues: [],
      counters: [],
      counterStats: {},
      currentTicket: {
        item: null
      },
      showConfigPopup: false,
      showBookingPopup: false,
      showQueuePopup: false,
      showDevicesPopup: false,
      showRedirectPopup: false,
      showToastPopup: false,
      showConfirmPopup: false,
      showNotePopup: false,
      showPaymentPopup: false,
      showAppointmentPopup: false,
      showCreateCustomerPopup: false,
      appointmentType: 'new',
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      devicesType: 'displays',
      queueType: 'queue', //to show tickets queue
      status: true,
      items: [1, 2, 3, 4, 5],
      toDisplay: 1,
      queue: null,
      queueServices: [],
      queueSummary: {
        totalWaiting: null,
        servedToday: null,
        totalWaitingBooking: null,
      },
      customerStats: {
        visit_freq: "--",
        satisfaction: 0,
        consumed_service: {
          percentage: '--',
          title: '--'
        },
        pub: null
      },
      servedThisMonth: null,
      waitingList: [],
      onHoldTickets: [],
      kiosks: [],
      displays: [],
      onLine: navigator.onLine,
      sending: true,
      notifications: [],
      paymentTypes: [],
      // 
      bookings: []
    };
  },
  watch: {
    currentTicket: {
      handler: function (newVal, oldVal) {
        //this.sending = newVal.item === null;
      },
      deep: true
    }
  },
  computed: {
    getStatus() {
      return localStorage.getItem('counter') !== null
    },
  },
  created() {
    // initializing variables with the actual session
    try {
      this.teller_config = JSON.parse(localStorage.getItem("user") || "{}");
      this.counter_config = JSON.parse(localStorage.getItem("counter") || "{}");
      businessService.getBusinessInformations().then((business) => {
        this.teller_config.business = business.business
        localStorage.setItem('user', JSON.stringify(this.teller_config))
      })

      localStorage.setItem('member', JSON.stringify(this.teller_config.user))
      this.user.name = this.teller_config.user.fname + " " + this.teller_config.user.lname;
      this.hasAmount = this.teller_config.business.ticket_price_status !== 0
    } catch (err) {
      console.log(err);
    }

    
  },
  mounted() {

    // if (JSON.parse(localStorage.getItem('user')).user.maintenance === 1) {
    //   router.push('/maintenance');
    // }

    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    businessService.BookingByRange(startDate, endDate).then((res) => {
      this.bookings = res.bookings;
    }).catch((err) => {
        console.log(err)
    })

  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {

    ...mapActions("authentication", ["logout"]),

    enterCallRoom(room_name) {
      localStorage.removeItem("customer");
      localStorage.setItem("isAgent", JSON.stringify(true));
      router.push({ path: "/room/"+room_name });
    },

    logout() {
      store.dispatch('authentication/logout')
    },

    openToast(type, data) {

      this.toastData = data
      this.toastType = type

      console.log('show')
      this.showToastPopup = true

    },
    payment(data) {
      queueService.updateTicketAmount(this.counter_config.queue_id, this.currentTicket.item.ticket_id, data.amount, data.type).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.sub-title'
        })
        this.currentTicket.item.type_payment = data.type
        this.currentTicket.item.price = data.amount
        console.log(res)
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    updateOnlineStatus(e) {
      const {type} = e
      this.onLine = type === 'online'
    },
    getQueueData() {
      this.counter_config = JSON.parse(localStorage.getItem("counter") || "{}");

      businessService
          .getCounterAgentStats()
          .then((res) => {
            this.counterStats = res.queue;
            // this.queueSummary.servedToday = res.queue.today;
            //this.servedThisMonth = res.queue.this_month;
          })
          .catch((err) => {
            console.log(err);
          });

      queueService
          .getByIdWithSummary(this.counter_config.queue_id)
          .then((res) => {
            this.queue = res.queue;
            this.queueServices = res.services;
            this.currentTicket.waitingTime = res.waiting_time;
            this.currentTicket.totalWaiting = res.totalWaiting;
          })

      queueService
          .getWaitingList(this.counter_config.queue_id)
          .then((res) => {
            this.queueSummary.servedToday = res.totalServed;
            this.queueSummary.totalWaiting = res.totalWaiting;

            let firstWaitingTicket = res.waiting_list.filter((ticket) => {
              return ticket.status === 'waiting'
            })

            let currentCounterTicket = res.waiting_list.filter((ticket) => {
              return ticket.status === 'inservice' && ticket.counter_id === this.counter_config.id
            })

            if (currentCounterTicket.length > 0) {
              currentCounterTicket[0].estimatedTimeToSpend *= 60;
              currentCounterTicket[0].customer_id = currentCounterTicket[0].id;
              this.currentTicket.item = currentCounterTicket[0]
              this.currentTicket.item.customer_id = this.currentTicket.item.id;
              console.log(this.currentTicket.item)
            } else if (firstWaitingTicket.length > 0) {
              firstWaitingTicket[0].estimatedTimeToSpend *= 60;
              firstWaitingTicket[0].customer_id = firstWaitingTicket[0].id;
              this.currentTicket.item = firstWaitingTicket[0]
              this.currentTicket.item.customer_id = this.currentTicket.item.id;
              console.log(this.currentTicket.item)
            } else {
              this.currentTicket.item = null
              this.currentTicket.waitingTime = 0;
              this.waitingList = []
            }

            this.waitingList = res.waiting_list
          })
          .catch((err) => {
            console.log(err);
          });
      queueService.getOnHoldTickets(this.counter_config.queue_id).then((res) => {
        this.onHoldTickets = res.waiting_list
      })

      this.sending = false;
      // connect to pusher for queue updates
    },
    configClosed() {
      this.showConfigPopup = false;
      if (localStorage.getItem("counter") !== null) {
        this.getQueueData()
        this.openCounter()
      } else {
        this.currentTicket.item = null
        this.currentTicket.waitingTime = 0;
        this.waitingList = []
        this.queueSummary = {
          totalWaiting: null,
          servedToday: null,
          totalWaitingBooking: null,
        }
        this.user = {}
        this.counter_config = null
        this.queue = null
        this.onHoldTickets = []
      }

    },
    openCounter() {
      let flag = false
      if (this.counter_config.queue_id !== undefined && this.counter_config.id !== undefined) {
        counterService.open(this.counter_config.queue_id, this.counter_config.id).then((data) => {
          flag = true
          console.log('opened')
          location.reload()
        }).catch((err) => {
          counterService.close(this.counter_config.queue_id, this.counter_config.id).then((res) => {
            console.log('closed')
            counterService.open(this.counter_config.queue_id, this.counter_config.id).then(() => {
              flag = true
              if (flag) {
                console.log('opened')
                location.reload()
              }
            })
          })
        }).then(function () {
          flag = true
          if (flag) {
            console.log('opened')
            location.reload()
          }
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        });
      }
    },
    resetCustomerStat() {
      this.customerStats = {
        visit_freq: "--",
        satisfaction: 0,
        consumed_service: {
          percentage: '--',
          title: '--'
        },
        pub: null
      }
    },
    updateCurrentTicketData(queue) {
      if (queue !== null) {
        let firstTicket = queue.customer.customer;
        firstTicket.public_identifier = queue.customer.public_identifier
        firstTicket.ticket_id = queue.customer.id
        firstTicket.notes = queue.customer.notes
        firstTicket.customer_id = firstTicket.id
        firstTicket.service_names = queue.customer.service_names
        firstTicket.estimatedTimeToSpend = queue.customer.estimated_service_time_in_seconds
        firstTicket.status = queue.customer.status
        this.currentTicket.item = firstTicket
        this.currentTicket.waitingTime = queue.customer.expected_waiting_time

      } else {
        this.currentTicket.item = null
        this.currentTicket.waitingTime = 0;
        this.waitingList = []
      }
      this.sending = false;
    },
    recallTicket() {
      queueService.displayCall(this.currentTicket.item.ticket_id).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    ticketAbsent() {
      if (this.currentTicket.item) {
        queueService.absent(this.counter_config.queue_id).then((res) => {
          this.updateCurrentTicketData(res)
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      }
    },
    holdTicket() {
      if (this.currentTicket.item) {
        queueService.onHold(this.counter_config.queue_id, this.currentTicket.item.ticket_id).then((res) => {
          console.log(res)
          this.getQueueData()
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },
    recallHoldTicket() {
      if (this.currentTicket.item) {
        console.log(this.counter_config.queue_id, this.currentTicket.item.ticket_id)
        queueService.recallOnHold(this.counter_config.queue_id, this.currentTicket.item.ticket_id).then((res) => {
          console.log(res)
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },
    endTicket(public_identifier, origin) {
      if (public_identifier) {
        this.sending = true;
        console.log(this.counter_config.queue_id, public_identifier)
        queueService.endTicket(this.counter_config.queue_id, public_identifier).then((res) => {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.Delete'
          })
          if (origin !== null) {
            queueService.getOnHoldTickets(this.counter_config.queue_id).then((res) => {
              this.onHoldTickets = res.waiting_list
            })
          } else {
            this.getQueueData()
          }
        }).catch((err) => {
          console.log(err)
          this.getQueueData()
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        }).then(() => {
          this.sending = false;
        })
      }
    },
    Redirect(queue_line) {
      let body = {
        customer_id: this.currentTicket.item.customer_id,
        queue_id: this.counter_config.queue_id,
        target_id: queue_line.id,
        keepTicket: queue_line.keepTicket ? 1 : 0
      }
      queueService.redirect(body).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.Redirect'
        })
        this.getQueueData()
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    updateComment(comment) {
      queueService.updateTicketNote(this.counter_config.queue_id, this.currentTicket.item.ticket_id, comment).then((res) => {
        this.openToast('success', {
          title: 'Toast.Success.title',
          details: 'Toast.Success.sub-title'
        })
        this.currentTicket.item.notes = comment
      }).catch((err) => {
        console.log(err)
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      })
    },
    swipeHandler(event) {
      console.log(window.innerWidth);
      if(window.innerWidth > 767) {
        return
      }
        event === 'left' ? this.toDisplay < this.items.length ? this.toDisplay++ : this.toDisplay = 1: this.toDisplay > 1 ? this.toDisplay-- : this.toDisplay = this.items.length;
    }
  },
};
</script>


<style scoped>

@media (max-width: 767px) {
  .footer-container {
    background: white !important;
    margin-top: 0 !important;
    height: auto !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    font-size: 20px !important;
  }

  .mobile-container{
    min-height: 46vh !important;
    max-height: 46vh !important;
  }

}
@media (max-width: 680px) {

  .vertical-ticket.box {
    padding: unset !important;
  }

  .console.box {
    padding: unset !important;
  }

  .right-boxes {
    width: 100% !important;
  }

  .vertical-ticket-h {
    height: 100% !important;
  }
}

.vertical-ticket-h {
  height: 92.5%;
}

.home-pointers {
  display: none !important;
}

.box {
  padding: 15px !important;
  padding-left: 30px !important;
}

.vertical-ticket {
  padding-left: unset !important;
}

.stat-container {
  padding-bottom: unset !important;
}

.right-boxes {
  padding-right: 15px !important;
}

.pointers {
  display: flex;
  margin-top: -0.5vmin;
  margin-bottom: 2vmin;
  margin-left: 2.8vmin;
}

.pointer {
  background: #444444 !important;
  opacity: 0.1 !important;
  width: 25px;
  height: 5px;
}

.active-pointer {
  opacity: 0.3 !important;
  background: #444444 !important;
}

.bottom-containers {
  padding-top: unset !important;
}

@media (max-width: 680px) {
  .home-container {
    overflow-y: auto;
  }

  .main-vue {
    height: fit-content !important;
    overflow-x: clip;
  }

  .home-pointers {
    display: flex !important;
  }

  .screen-settings {
    display: none !important;
  }

  .mobile-settings {
    display: inherit !important;
  }
  .box{
    padding-left: 15px!important;
  }
}

@media (min-height: 820px) {
  .main-vue {
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
}

/*main vue*/
.main-vue {
  width: 100%;
  height: 100% !important;
  margin: 0;
}

.footer-container {
  min-height: 7vh !important;
  max-height: 7vh !important;
  margin-top: 0;
}


/*headers*/
.right-buttons {
  max-width: 45% !important;
  display: flex;
  justify-content: left;
}

.second-header-buttons {
  margin-top: -0.5vmin;
  width: auto !important;
  display: flex;
  justify-content: left;
}

.setting-data {
  color: #ffffff !important;
}

.mobile-settings {
  display: none;
}

.setting-title {
  white-space: nowrap;
  font-family: "Exo 2", serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4vmin;
  line-height: 3.2vmin;
  /* identical to box height */

  text-align: left;

  /* Success Light */

  color: #93f383;
}

.setting-item {
  display: flex;
  margin-right: 5vmin;
  flex-direction: column;
}

.screen-settings {
  display: flex;
  width: 100%;
}

.settings-items-container {
  height: 77%;
}

.header-tools {
  padding-top: 1.5vmin;
  display: flex;
  margin-right: 0;
  height: 100%;
}

.icon-container {
  display: flex;
  justify-content: right;
  height: 77%;
}

.setting-icon {
  cursor: pointer;
  height: 48px;
  width: 48px;
}

.setting-icon-decoration {
  background: #00648d;
  opacity: 0.3;
  margin-top: 1vmin;
  height: 1vmin;
  width: 100%;
}

.setting-decoration {
  opacity: 0.3;
  height: 1vmin;
  margin-top: 1vmin;
  margin-left: -5% !important;
  width: 105%;
}

.setting-container {
  width: 36vmin;
  display: flex;
  flex-direction: column;
}

.setting-icon-container {
  width: 11vmin;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  height: inherit;
}

.second-home-header {
  height: 5vmin;
}

.home-header {
  display: flex;
  width: 100%;
  background: linear-gradient(127.19deg, #008fca 0.56%, #4bb0da 76.01%);
  height: 9vmin;
}

.header-user {
  margin-left: 2.5vmin;
  padding-top: 3vmin;

  font-family: "Exo 2";
  font-style: normal;
  font-weight: 400;
  font-size: calc(0.9 * (1vh + 1vw));
  line-height: 2vmin;
  color: #ffffff;
}

.greetings {
  font-size: calc(0.58 * (1vh + 1vw));
}

.home-container {
  overflow-y: hidden;
  overflow-x: clip;
  background: rgba(246, 246, 246, 1);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  left: 0;
  top: 0;
  width: 100%;
  height: auto !important;
}

@media (max-width: 680px) {
  .setting-icon {
    cursor: pointer;
    height: 22px !important;
  }
}
</style>
