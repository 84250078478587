<template>
    <div class="disclaimer-text">
        <div>
            <h5>Disclaimer :</h5>
        </div>
        <div>
            <span>Before you proceed with the video chat-based virtual branch service, please review the following terms and
                conditions:</span>
            <div class="text-block">
                <p><span class="text-title">Privacy and Security</span>: The virtual branch video chat service is designed
                    to adhere to strict security standards. However, it is your responsibility to ensure that you are in a
                    private and secure location during the chat to protect your personal and financial information.</p>
                <p><span class="text-title">Technical Requirements</span>: For optimal experience, please ensure that your
                    device has a stable internet connection, functioning camera, and microphone. The bank is not responsible
                    for disruptions caused due to technical issues on the user's side.</p>
                <p><span class="text-title">Recording</span>: This video chat may be recorded for quality assurance,
                    training, and compliance purposes. By continuing, you consent to this recording.</p>
                <p><span class="text-title">Personal Information</span>: During the chat, do not share sensitive personal
                    information unless specifically requested by the representative for service purposes. Avoid displaying
                    personal documents or information to the camera unless instructed.</p>
                <p><span class="text-title">Professional Conduct</span>: Please maintain a respectful and professional
                    demeanor during the chat. The bank reserves the right to terminate the chat session if a user behaves
                    inappropriately.</p>
                <p><span class="text-title">Limitation of Liability</span>: While we strive to provide accurate and
                    up-to-date information, the bank is not liable for any inaccuracies or errors that may arise during the
                    chat. For official banking matters, always refer to written documentation or formal channels.</p>
                <p><span class="text-title">Service Hours</span>: The virtual branch video chat service is designed to
                    adhere to strict security standards. However, it is your responsibility to ensure that you are in a
                    private and secure location during the chat to protect your personal and financial information.</p>
            </div>
        </div>
        <div><span>By proceeding with the virtual branch video chat service, you acknowledge and agree to the terms
                mentioned above.</span></div>
    </div>
</template>
<script>
export default {
    name: 'LbrxDisclaimer',
    props: {
        textColor: {
            required: false,
            value: String
        }
    },
    computed: {
        getTextColor() {
            return this.textColor ? this.textColor : '#fff'
        }
    },
    emits: ['update:modelValue'],
}
</script>

<style scoped>
.disclaimer-text {
    font-size: 13px;
    font-weight: 200;
    color: v-bind(getTextColor);
    text-align: left;
}

.text-title {
    font-weight: 600;
}

.text-block {
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-block p {
    display: list-item;
    margin-left: 30px;
    line-height: 15px;
    margin-bottom: 5px;
}</style>