<template>
  <div v-if="modelValue" class="popup-container" :dir="this.direction" :class="'text-'+this.text_direction">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon mx-3" src="../assets/icons/assistance-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.help') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.help_details') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
          <div class="row">
            <div v-if="!loading" class="popup-body">
              <div class="welcome-message">
                {{ $t('Teller.HelloCustomer') }}<br><br>
                {{ $t('Teller.help_details_2') }}
              </div>

              <div class="call-us-box">+216 36 36 43 45</div>
              <div class="call-us-box">support@liberrex.com</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>


export default {
  name: 'LbrxTechnicialAssistancePopup',
  inject: ['direction', 'text_direction'],
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      showPopup: false,
      loading: true,
    }
  },
  methods: {
    setLanguage(lang) {
      console.log(lang);
      this.closeThis();
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
}
</script>

<style scoped>
@media (max-width: 1200px) {
  .popup-box {
    width: 70% !important;
  }
}

.popup-box {
  width: 35%;
}

.call-us-icon img {
  height: 8vmin;
}

.call-us-box {
  display: flex;
  justify-content: center;
  margin-top: 4vmin;
  width: 100%;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 4.3vmin;
  line-height: 4vmin;

  /* Primary */

  color: #008FCA;

}

.welcome-message {
  margin-top: 4vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 200;
  font-size: 3.5vmin;
  line-height: 4vmin;

  /* Medium Gray */

  color: #8A8A8A;
}
</style>