<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.9998 19.9999C23.9998 19.9999 18.1078 12 15.9997 12C13.8915 12 7.99976 20 7.99976 20" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>

export default {
  name: 'arrowUpIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>