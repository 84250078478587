<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon" :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}" src="../assets/icons/044-uparrow.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.Redirect_ticket') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.Redirect_details') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>

          <div class="row">
            <div class="popup-body">
              <div class="popup-elements">
                <div class="popup-title-text pop-up-body-title my-3">
                  {{ $t('Teller.Queue') }}
                </div>
                <div v-if="queues" style="margin-bottom: 5vmin" class="queues-list">
                      <span v-for="(queue, index) in queues" :key="index">
                      <lbrx-radio-button :id="'checkQueue-'+queue.id" @select="checkQueue(queue)" :item="queue"></lbrx-radio-button>
                      </span>
                </div>
                <lbrx-checkbox id="keepTicket" v-model="keepTicketValue" @update:model-value="this.keepTicketValue === $event"
                               :label="$t('Teller.Redirect_keep_ticket')"></lbrx-checkbox>
              </div>

              <div class="popup-buttons">
                <lbrx-button id="validateRedirect" v-model="sending" icon="tick-mark-icon" :base-button="true" @clicked="triggerPopupAction()"
                             :label="$t('Teller.RedirectBTN')"
                             color="#FFFFFF" background="#00CC6A" height="9vmin" width="93%"
                             font-size="1.1"></lbrx-button>
                <lbrx-button id="cancelRedirect" v-model="close" icon="delete-red-icon" :base-button="true" @clicked="closeThis()" :label="$t('Teller.Close')"
                             color="#FF5757" border="1px solid #FF5757" background="white" height="9vmin"
                             width="93%" font-size="1.1"></lbrx-button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxRadioButton from "@/components/LbrxRadioButton.vue";
import LbrxCheckbox from "@/components/LbrxCheckbox.vue";

export default {
  name: 'LbrxRedirectPopup',
  inject: ['direction', 'text_direction'],
  components: {LbrxCheckbox, LbrxRadioButton, LbrxButton},
  props: {
    modelValue: Boolean,
    queue_lines: Array
  },
  data() {
    return {
      queues: [],
      sending: true,
      close: false,
      showPopup: false,
      selected_queue: null,
      keepTicketValue: false
    }
  },
  methods: {
    triggerPopupAction() {
      this.selected_queue.keepTicket = this.keepTicketValue
      this.$emit('Redirect-trigger', this.selected_queue)
      this.closeThis()
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    checkQueue(queue) {
      this.queues.forEach((queue) => queue.selected = false);
      queue.selected = true;
      this.selected_queue = queue
      this.sending = false
    }
  },
  mounted() {
    this.queues = this.queue_lines
    let current_counter = JSON.parse(localStorage.getItem("counter") || "{}")
    this.queues.forEach((queue, index) => {
      if (queue.id === current_counter.queue_id) {
       queue.disabled = true
      }
      queue.selected = false
    });
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  }
}
</script>

<style scoped>
.queues-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2vmin;
}

.popup-buttons{
  padding-right: 15px;
  padding-left: 15px;
}


.offices-list {
  width: 100%;
  margin-top: 2vmin;
  padding: 0 !important;
  overflow-x: hidden;
}

.single-office {
  width: 24.9vmin;
  margin-bottom: 2vmin;
}

@media (max-width: 1200px) {
  .single-office {
    width: 30.5vmin;
  }
}

.popup-elements {
  overflow-x: hidden;
}
</style>