<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div :class="{'right-class': $i18n.locale === 'ar'}" class="container d-flex flex-column h-100">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"  :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}" src="../assets/icons/calendar-grey-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.Bookings') + ' ' + $t('Teller.Online').toLowerCase() }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.BookingsList') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>

          <div class="row justify-content-center" :class="{'right-class': $i18n.locale === 'ar'}">
            <div v-if="!loading" class="popup-body">
              <div class="booking-header justify-content-between">
                <button id="previousWeek" class="calendar-button" @click="gotToPreviousWeek">
                    <img v-if="$i18n.locale !== 'ar'" class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/>
                    <img v-else class="calendar-button-icon" src="../assets/icons/right-arrow-white.svg"/>
                </button>
                <button id="previousDay" class="calendar-mobile-button" @click="goToPreviousDay">
                    <img v-if="$i18n.locale !== 'ar'" class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/>
                    <img v-else class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/>
                </button>
                <div id="currentMonth" class="selected-month">{{ moment(fromDate).locale($i18n.locale).format('MMMM') }},
                  {{ moment(fromDate).locale($i18n.locale).format('YYYY') }}
                </div>
                <button id="goToNextWeek" class="calendar-button" @click="gotToNextWeek">
                    <img v-if="$i18n.locale !== 'ar'" class="calendar-button-icon" src="../assets/icons/right-arrow-white.svg"/>
                    <img v-else class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/>
                </button>
                <button id="goToNextDay" class="calendar-mobile-button" @click="gotToNextDay">
                    <img v-if="$i18n.locale !== 'ar'" class="calendar-button-icon" src="../assets/icons/right-arrow-white.svg"/>
                    <img v-else class="calendar-button-icon" src="../assets/icons/left-arrow-white.svg"/>
                </button>
              </div>
              <div class="booking-days justify-content-between" :class="{'right-class': $i18n.locale === 'ar'}" style="min-height: 60vh;max-height: 60vh;overflow-y: auto">
                <div class="day-container" v-for="(date,index) in selectedDates" :key="index">
                  <lbrx-calendar-day @details-shown="showHideDetails" :date="date"></lbrx-calendar-day>
                </div>
              </div>
              <div class="signle-day justify-content-between" style="min-height: 70vh;max-height: 70vh;overflow-y: hidden" :class="{'right-class': $i18n.locale === 'ar'}">
                <lbrx-calendar-day @details-shown="showHideDetails" :date="selectedDate"></lbrx-calendar-day>
              </div>
                <div style="margin-top: 5px">
                <lbrx-sub-menu-button id="addBookingButton" icon="booking-add-white" :label="$t('BookingPopup.title')" color="white" padding="12"
                                      @click="this.showAppointmentPopup = true"
                                      background="#008FCA"
                                      height="10vmin" width="auto" font-size="1.1"></lbrx-sub-menu-button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <lbrx-appointment-popup v-if="showAppointmentPopup"
                          v-model="showAppointmentPopup"
                          :type="'new'"
                          @close="showAppointmentPopup = false; this.setDates(new Date(), 1, 7);"></lbrx-appointment-popup>
</template>

<script>
import LbrxCalendarDay from "@/components/LbrxCalendarDay.vue";
import {businessService} from "@/_services";
import moment from "moment/moment";
import LbrxSubMenuButton from "@/components/LbrxSubMenuButton.vue";
import LbrxAppointmentPopup from "@/components/LbrxAppointmentPopup.vue";

export default {
  name: 'LbrxBookingPopup',
  components: {LbrxAppointmentPopup, LbrxSubMenuButton, LbrxCalendarDay},
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      selectedDates: [],
      selectedDate: null,
      sending: false,
      showPopup: false,
      loading: true,
      fromDate: String,
      toDate: String,
      detailsShown: false,
      showAppointmentPopup: false
    }
  },
  computed: {
    moment() {
      return moment
    },
  },
  methods: {
    showHideDetails(value) {
      this.detailsShown = value;
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
    setDates(today, plusFrom, plusTo) {
      const date = new Date(today);
      this.fromDate = new Date(date.setDate(date.getDate() - date.getDay() + (plusFrom)));
      this.toDate = new Date(date.setDate(date.getDate() - date.getDay() + (plusTo)));
      const selected = []
      let dt = new Date(this.fromDate);
      dt = new Date(dt.setDate(dt.getDate() - dt.getDay()));
      businessService.BookingByRange(this.fromDate, this.toDate).then((response) => {
        for (let i = 0; i < 7; i++) {
          dt.bookings = response.bookings.filter((booking) => {
            return moment(dt).subtract(-1, "days").format('YYYY-MM-DD') === moment(booking.start_datetime).format('YYYY-MM-DD')
          }).sort((a,b)=>new Date(a.start_datetime).getTime()-new Date(b.start_datetime).getTime())
          selected.push(dt);
          dt = new Date(dt.setDate(dt.getDate() + 1));
        }
        this.selectedDates = [];
        this.selectedDates = selected;
        this.selectedDate = this.selectedDates[0];
      })

    },
    gotToNextWeek() {
      this.loading = true;
      this.setDates(this.fromDate, 8, 7);
      this.loading = false;
    },
    gotToNextDay() {
      if (this.selectedDates.indexOf(this.selectedDate) + 1 >= this.selectedDates.length) {
        this.gotToNextWeek();
      } else {
        this.selectedDate = this.selectedDates[this.selectedDates.indexOf(this.selectedDate) + 1];

      }
    },
    goToPreviousDay() {
      if (this.selectedDates.indexOf(this.selectedDate) - 1 < 0) {
        this.gotToPreviousWeek();
      } else {
        this.selectedDate = this.selectedDates[this.selectedDates.indexOf(this.selectedDate) - 1];
      }
    },
    gotToPreviousWeek() {
      this.loading = true;
      this.setDates(this.fromDate, -6, 7);
      this.loading = false;
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' && !this.detailsShown ? this.closeThis() : null;
    });

    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
  mounted() {
    this.setDates(new Date(), 1, 7);
  }
}
</script>

<style scoped>


@media (max-width: 815px) {
  .calendar-mobile-button {
    display: block !important;
  }

  .calendar-button {
    display: none !important;
  }

  .signle-day {
    display: flex !important;
  }

  .booking-days {
    display: none !important;
  }
}

.calendar-button-icon {
  height: 2.3vmin;
}

.signle-day {
  display: none;
  width: 100%;
  height: 100% !important;
  overflow: hidden;
  margin-top: 1vmin;
  margin-left: -0.5vmin;
}

.booking-days {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 1vmin;
  margin-left: -0.5vmin;
  display: flex;
}

.selected-month {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 3.8vmin;
  line-height: 29px;
  /* identical to box height */


  /* Primary */

  color: #008FCA;
}

.booking-header {
  margin-top: 8vmin;
  display: flex;
  width: 99%;
}

.calendar-mobile-button {
  margin-top: -0.2vmin;
  display: none;
  background: #008FCA;
  border: none;
  min-width: 4.5vmin;
  min-height: 4.2vmin;
}

.calendar-button {
  margin-top: -0.2vmin;
  background: #008FCA;
  border: none;
  min-width: 4.5vmin;
  min-height: 4.2vmin;
}

.popup-box {
  width: 70%;
    overflow-y: hidden;
    height: 100%;
}

.day-container {
  min-width: 13% !important;
  padding: 0 !important;
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
}
</style>