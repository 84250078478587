import { createStore } from 'vuex'
import { authentication } from './authentication.module';

export default createStore({
  state: {
    subscriberVideo: false,
    subscriberAudio: false,
    participantJoined: false,
    recordingNow: false,
    reconnecting: false,
    sessionDisconnected: false
  },
  getters: {
    getSubscriberVideo(state) {
      return state.subscriberVideo;
    },
    getSubscriberAudio(state) {
      return state.subscriberAudio;
    },
    getParticipantJoined(state) {
      return state.participantJoined;
    },
    getRecordingNow(state) {
      return state.recordingNow;
    },
    getReconnecting(state) {
      return state.reconnecting;
    },
    getSessionDisconnected(state) {
      return state.sessionDisconnected;
    }
  },
  mutations: {
    set_subscriberVideo(state, subscriberVideo) {
      state.subscriberVideo = subscriberVideo;
    },
    set_subscriberAudio(state, subscriberAudio) {
      state.subscriberAudio = subscriberAudio;
    },
    set_participantJoined(state, participantJoined) {
      state.participantJoined = participantJoined;
    },
    set_recordingNow(state, recordingNow) {
      state.recordingNow = recordingNow;
    },
    set_reconnecting(state, reconnecting) {
      state.reconnecting = reconnecting;
    },
    set_sessionDisconnected(state, sessionDisconnected) {
      state.sessionDisconnected = sessionDisconnected;
    }
  },
  actions: {
    setSubscriberVideo({ commit }, subscriberVideo){
      commit('set_subscriberVideo', subscriberVideo)
    },
    setSubscriberAudio({ commit }, subscriberAudio){
      commit('set_subscriberAudio', subscriberAudio)
    }
  },
  modules: {
    authentication
  }
})
