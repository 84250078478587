<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 14L8.5 17.5L19 6.5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>

export default {
  name: 'tickIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>