<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 6C5.35977 6.02555 4.96714 6.08952 4.58115 6.24967C3.79373 6.57636 3.15249 7.17983 2.77788 7.94671C2.47211 8.57266 2.42207 9.37463 2.322 10.9786L2.16519 13.4919C1.91633 17.4806 1.79191 19.4749 2.97594 20.7375C4.15998 22 6.1547 22 10.1442 22H14.1102C17.8027 22 19.7863 22 21 20.999" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17 7L16.5909 6.00049M16.5909 6.00049L16.0936 4.78543C15.6993 3.82207 15.3536 2.74195 14.3566 2.25955C13.8201 2 13.1746 2 11.8837 2C10.5928 2 9.94729 2 9.41085 2.25955C8.67692 2.61465 8.31282 3.27221 8 4M16.5909 6.00049H17C18.2206 6.00415 18.8962 6.03335 19.4513 6.26634C20.2288 6.59269 20.862 7.19552 21.2319 7.96158C21.5338 8.58687 21.5832 9.38799 21.682 10.9902L21.8369 13.5009C21.9381 15.1428 22.0185 16.4464 21.9963 17.5" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
<path d="M10.5 10.8369C9.31753 11.3986 8.5 12.6039 8.5 14.0001C8.5 15.9331 10.067 17.5001 12 17.5001C13.3962 17.5001 14.6015 16.6826 15.1632 15.5001" :stroke="color" stroke-width="1.5"/>
<path d="M2 2L22 22" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
<path d="M11.9998 6H12.0088" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIconOff',
  props: {
    size: Number,
    color: String
  },
  data() {
    return {
      showNotes: true,
      showUserData: true,
      sending: false,
      showPopup: false,
      loading: true,
      dt: new Date(this.date),
      dep: {}
    }
  }
}
</script>