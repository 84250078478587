<template>
  <div>
    <div class="main-container">
      <div style="display: inline-grid; height: 95vh; align-content: center;">
        <LbrxFeedback :isMobileView="isMobileView" :business="business" :member="member" :ratingConfig="ratingConfig" :rated="rated" @sendFeedback="sendFeedback" @leaveCall="leaveCall"/>
      </div>
    </div>
    <div class="footer">
      <img src="/images/liberrex_white.png" alt="" class="logo-liberrex">
    </div>
  </div>
</template>
  
<script>
import LbrxFeedback from "@/components/LbrxFeedback.vue";
import { meetService, vendorsService } from "@/_services";
import {notificationPlay} from '../../_helpers'
import router from "@/router";
import moment from "moment/moment";

export default {
  name: "FeedbackMeet",
  inject: ['echo'],
  components: {
    LbrxFeedback
  },
  data() {
    return {
      business: null,
      member: null,
      ratingConfig: null,
      meet: null,
      rated: false,
      room: {
        room_name: this.$route.params.room_name,
        date_time: ''
      },
      // Set mobile breakpoint threshold
      mobileBreakpoint: 768,
    };
  },
  computed: {
    isMobileView() {
      return window.innerWidth <= this.mobileBreakpoint;
    },
  },
  created() {
    this.business = JSON.parse(localStorage.getItem('business'));
    this.member = JSON.parse(localStorage.getItem('member'));
    this.meet = JSON.parse(localStorage.getItem('meet'));
    this.ratingConfig = this.meet.feedback_config;
    if(this.meet.feedback != null) {
      this.rated = true
    }
  },
  mounted() {
    // Add an event listener to update the computed property on window resize
    window.addEventListener('resize', this.handleWindowResize);
    // Initial check on component mount
    this.handleWindowResize();
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      // Update the computed property on window resize
      this.isMobileView = window.innerWidth < this.mobileBreakpoint;
    },
    sendFeedback(rating, ratingNote) {
      let payload = {
        rating: rating,
        note: ratingNote
      }
      meetService.sendFeedback(this.meet.id, payload).then(() => {
        this.rated = true;
      }).catch((err) => {
        console.log(err)
      })
    },
    leaveCall() {
      meetService.logoutParticipant();
      router.push({path: "/"+this.room.room_name});
    },
  },


};
</script>
  
<style scoped>
.main-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.footer {
  background-color: #000;
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  height: 5vh;
  margin: 3vh 0;
}
.logo-liberrex {
  position: relative;
  height: 100%;
}

</style>
  