<template>
  <div v-show="joined">
  <div class="home-container" :style="isMobileView ? 'display: grid;' : ''">
    <img src="/images/liberrex_white.png" alt="" :class="isMobileView ? 'logo-liberrex-mobile' : 'logo-liberrex'">
    <div :class="isMobileView ? 'main-container-mobile' : 'main-container'" :style="isMobileView && isPending ? 'height: 50vh;' : containerIsMinimized ? '' : 'height: 50vh;'">
      <div id="my-video-chat-window" :class="isMobileView ? 'main-video-mobile' : 'main-video'" :style="isPending ? 'background-color: #444444; border-radius: 10px; overflow-y: hidden;' : 'background-color: #444444; border-radius: 10px;'">
        <div v-if="recordingNow" style="position: absolute; top: 10px; z-index: 999;left: 50%; transform: translate(-50%, 0);"><span style="border-radius: 5px; background-color: #DC3838; color: #fff; padding: 3px 7px; font-size: 12px;">&#9679;	RECORDING</span></div>
        <img v-if="!subscriberAudio && participantJoined" src="/images/mic-off.png" :color="'#fff'" :size="isMobileView ? 21 : 38" style="position: absolute; top: 15px; left: 20px; width: 30px;"/>
        <div v-show="subscriberVideo && !isPending" id="primaryVideo" style="width: 100%; height: 100%; display: flex;">
          <!-- <div v-else class="dot-container">
            <div class="dot" id="dot1"></div>
            <div class="dot" id="dot2"></div>
            <div class="dot" id="dot3"></div>
          </div> -->
        </div>

        <!-- when member cam not on -->
        <div v-if="!subscriberVideo && participantJoined" class="main-video-photo-icon">
          <div v-if="isAgent" style="justify-self: center;">
            <img v-if="participant.photo != null && participant.photo.length != 0" :src="participant.photo" alt="" class="circle-image">
            <div v-else class="circle-image" style="background-color: var(--primaryOrg); height: 150px; width: 150px; display: grid;"><span style="align-self: center; font-size: 70px;" v-if="participant && participant.fname">{{ participant.fname.substring(0, 1).toUpperCase() }}</span></div>
          </div>
          <div v-else style="justify-self: center;">
            <img v-if="member && member.photo != null && member.photo.length != 0" :src="member.photo" alt="" class="circle-image">
            <div v-else class="circle-image" style="background-color: var(--primaryOrg); height: 150px; width: 150px; display: grid;"><span style="align-self: center; font-size: 70px;" v-if="member && member.fname">{{ member.fname.substring(0, 1).toUpperCase() }}</span></div>
          </div>
          <div v-if="isAgent"><span v-if="participant && participant.fname">{{ participant.fname + ' ' + participant.lname }}</span></div>
            <div v-else><span v-if="member && member.fname">{{ member.fname + ' ' + member.lname }}</span></div>
        </div>
        <!-- -------------- -->
        <!-- <div v-if="!subscriberVideo && !isAgent" class="main-video-photo-icon">
          <div v-if="!isMember">
            <img v-if="participant.photo != null && participant.photo.length != 0" :src="participant.photo" alt="" class="circle-image">
            <div v-else class="circle-image" style="background-color: var(--primaryOrg); height: 150px; width: 150px; display: grid;"><span style="align-self: center; font-size: 70px;" v-if="participant && participant.fname">{{ participant.fname.substring(0, 1).toUpperCase() }}</span></div>
          </div>
          <div v-else>
            <img v-if="member && member.photo != null && member.photo.length != 0" :src="member.photo" alt="" class="circle-image">
            <div v-else class="circle-image" style="background-color: var(--primaryOrg); height: 150px; width: 150px; display: grid;"><span style="align-self: center; font-size: 70px;" v-if="member && member.fname">{{ member.fname.substring(0, 1).toUpperCase() }}</span></div>
          </div>
          <div v-if="!isMember"><span v-if="participant && participant.fname">{{ participant.fname + ' ' + participant.lname }}</span></div>
            <div v-else><span v-if="member && member.fname">{{ member.fname + ' ' + member.lname }}</span></div>
        </div> -->
        <div v-else-if="isPending" :class="!showAds ? 'disclaimer' : ''" :style="isMobileView ? 'position: relative;' : ''">
          <Disclaimer v-if="!showAds" />
          <div v-else>
            <video loop :src="adUrl" autoplay style="width: 100%; object-fit: contain; height: 100%;"></video>
          </div>
        </div>
        <div class="main-video-band" v-if="!isPending">
          <div v-if="!isMobileView" class="left" :style="!subscriberVideo ? 'visibility: hidden;' : ''">
            <div :style="!participantJoined ? 'visibility: hidden;' : ''">
              <div v-if="isAgent"><span v-if="participant && participant.fname">{{ participant.fname + ' ' + participant.lname }}</span></div>
              <div v-else><span v-if="member && member.fname">{{ member.fname + ' ' + member.lname }}</span></div>
            </div>
          </div>
          <div class="middle">
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" :style="showChat ? 'background-color: #4BB0DA' : ''" @click="displayChat"><iconComment :color="showChat ? '#fff' : '#444444'" :size="isMobileView ? 24 : 26"/></button>
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" :style="!showChat ? 'background-color: #4BB0DA' : ''" @click="showChat = false"><iconInfo :color="!showChat ? '#fff' : '#444444'" :size="isMobileView ? 19 : 24"/></button>
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" :style="showMicIcon ? '' : 'background-color: var(--offline);'" @click="toggleAudio"><iconMic v-if="showMicIcon" :color="'#444444'" :size="isMobileView ? 19 : 24"/><iconMicOff v-else :color="'#fff'" :size="isMobileView ? 19 : 24"/></button>
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" :style="showCamIcon ? '' : 'background-color: var(--offline);'" @click="toggleVideo"><iconCamera v-if="showCamIcon" :color="'#444444'" :size="isMobileView ? 19 : 24"/><iconCameraOff v-else :color="'#fff'" :size="isMobileView ? 19 : 24"/></button>
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" style="background-color: var(--danger);" @click="endCallbyId"><iconPhone :color="'#fff'" :sizeW="20" :sizeH="16"/></button>
          </div>
          <div v-if="!isMobileView" class="right" style="visibility: hidden;">
            <div v-if="isAgent"><span v-if="participant && participant.fname">{{ participant.fname + ' ' + participant.lname }}</span></div>
            <div v-else><span v-if="member && member.fname">{{ member.fname + ' ' + member.lname }}</span></div>
          </div>
          <div v-if="!isMobileView" style="position: absolute; right: 15px;">
            <button :class="isMobileView ? 'circle-button-mobile' : 'circle-button'" @click="showSettings = !showSettings"><iconMenuH color="#000" :size="isMobileView ? 22 : 24"/></button>
            <div v-if="showSettings" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="camHover = false">
              <div class="setting-options" @click="showStartRecordingPopup();" style="color: #444;" :key="index"><img :src="getIcon(recordingNow ? 'record-on' : 'record')" style="margin: 0 10px; width: 15px;"/>{{ recordingNow ? 'Stop Recording' : 'Start recording' }}</div>
              <div class="setting-options" style="color: #444;" :key="index"><img :src="getIcon('meet-setting')" style="margin: 0 10px; width: 15px;"/>{{ 'Settings' }}</div>
            </div>
          </div>
        </div>

        <!-- <div id="participant-video-chat-window" ></div> -->
        <div v-if="!isPending" id="participant-video-chat-window" :class="participantJoined ? 'participant-window-style' : 'localuser-window-style'" :style="getDynamicStylesSecondVid">
          <img v-if="!showMicIcon" src="/images/mic-off.png" :size="isMobileView ? 21 : 28" style="position: absolute; top: 10px; right: 10px; z-index: 99; width: 30px;"/>
          <div v-show="secondVideoShow" id="secondVideo" style="width: 100%; height: 100%; object-fit: cover; border-radius: 7px; overflow: hidden;"></div>
          <div v-if="!secondVideoShow" :class="participantJoined ? 'second-video-photo-icon-participant' : 'second-video-photo-icon'">
            <img v-if="localUser.photo != null && localUser.photo.length != 0" :src="localUser.photo" alt="" :style="!isMobileView ? circleImageSecond : circleImageSecondMobile">
            <div v-else class="circle-image" :style="!isMobileView ? getDynamicStylesSecondVidAvatar : getDynamicStylesSecondVidAvatarM"><span :style="!participantJoined ? 'align-self: center; font-size: 70px;' : 'align-self: center; font-size: 40px;'">{{ localUser.fname.substring(0, 1).toUpperCase() }}</span></div>
            <span>{{ isMobileView ? localUser.fname : (localUser.fname + ' ' + localUser.lname).length <= 12 ? localUser.fname + ' ' + localUser.lname : (localUser.fname + ' ' + localUser.lname).length > 12 && !participantJoined ? localUser.fname + ' ' + localUser.lname : localUser.fname + '...' }}</span>
          </div>
          <div v-else-if="secondVideoShow && participantJoined && isMobileView" class="secondary-video-band">{{ isMobileView ? localUser.fname : (localUser.fname + ' ' + localUser.lname).length <= 12 ? localUser.fname + ' ' + localUser.lname : localUser.fname + '...' }}</div>
        </div>
      </div>

      <!-- <div id="my-video-chat-window" class="participant-video-window">
      
        <div id="participant-video-chat-window" ></div>
      </div> -->
    </div>


    <!-- message panel component -->
    <div class="chat-container" v-if="isMobileView ? false : true">
      <div class="chat-component">
        <div>
          <img :src="business.photo" alt="" style="width: auto;margin: 14px; height: 5vh;">
        </div>
        <div v-if="!isPending">
          
          <div v-if="showChat" @scroll="onScroll" style="height: 66vh; overflow-y: scroll; position: relative; margin-top: 20px; padding: 0 25px; padding-bottom: 5px; display: flex; flex-direction: column-reverse;" ref="messageContainer">
            <div class="messages-container">
              
              <div v-if="noMoreMessages" class="end-messages">no more messages</div>
              <loaderTail v-if="loadingMessages" :color="'#444444'" :size="18" style="margin: 5px 0;" />
              <div v-for="(item, index) in messages" :key="index" style="margin-bottom: 10px;">
                  <div v-if="index == 0 || item.sender_name != messages[index - 1].sender_name">
                    <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user-name' : 'message-guest-name'">{{ item.sender_name }}</div>
                    <div class="message-time" :style="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'margin-left: auto;' : 'margin-right: auto;'">{{ calculateTimeDifference(item.created_at) }}</div>
                  </div>
                  <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user' : 'message-guest'" :style="item.is_attachment ? 'padding: 10px 10px;' : ''">
                    <div v-if="!item.is_attachment" style="font-size: 14px;">{{ item.message }}</div>
                    <div v-else class="file-message" @click="downloadFile(item)">
                      <div style="display: flex; padding: 5px 5px;">
                        <img src="../../assets/icons/meet-attachment.svg" alt="">
                        <div style="display: grid; margin: 0 10px; color: #fff;">
                          <div class="file-name">{{ item.message.length <= 16 ? item.message : item.message.substring(0, 15)+'...' }}</div>
                          <div class="file-info">{{ convertBytes(item.attachment_size) }} | Click to download</div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div v-else style="height: 78%; overflow-y: scroll;">
            <div class="choices-container">
              <div style="display: grid;">
                <span style="color: var(--Dark-Gray, #444); font-size: 17px; font-weight: 400;">Hello <span style="font-weight: 600;">{{ localUser.fname+" "+localUser.lname }},</span></span>
                <span style="color: var(--Dark-Gray, #444); font-size: 13px; font-weight: 400;">Thank you for choosing <span style="font-weight: 700;">{{ business.name }}</span></span>
                <span style="color: var(--Dark-Gray, #444); font-size: 13px; font-weight: 400;">You are speaking to : </span>
              </div>
              <div v-if="member" class="agent-card">
                <img :src="member.photo ? member.photo : '/images/default_img.jpg'" alt="" style="width: 70px;">
                <div style="align-self: center; width: 100%;">
                  <h6 style="color: var(--Dark-Gray, #444); text-align: center; font-size: 15px; font-weight: 300;">Mr <span style="font-weight: 600;" v-if="member && member.fname">{{ member.fname+" "+member.lname }}</span></h6>
                  <h6 style="color: var(--Primary, #008FCA); text-align: center; font-size: 13px; font-weight: 400; line-height: 3px;">Customer Advisory Specialist</h6>
                </div>
              </div>
              <span style="color: var(--Dark-Gray, #444); font-size: 13px; font-weight: 400;">How can we help you today?</span>
              <div>
                <div v-for="(item, index) in topics" :key="index" style="margin-top: 5px;">
                  <button @click="selectService = item.title; showChat = true" class="services-button" :class="selectService == item.title ? 'services-button-selected' : ''">{{ item.title }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="choices-container" style="margin-top: 30px;">
            <div style="margin-bottom: 20px;">
            <span>Hello <span style="font-weight: 800;">{{ localUser.fname+' '+localUser.lname }}</span></span>
          </div>
          <div>
            <span>Please hold, while our agent approves your request to join the video conference call ...</span>
          </div>
          </div>
          <div style="position: absolute; bottom: 0; display: grid; width: 100%; padding: 10px 30px;">
            <button class="disclaimer-button" @click="showDisclaimer">Disclaimer</button>
            <button class="leave-button" @click="leaveCall">Leave the call</button>
          </div>
        </div>
        <div v-if="showChat" class="input-message">
          
          <input type="file" ref="fileInput" id="fileMessage" @change="getFile" style="display: none;" accept=".pdf, .png, .jpg">
          <textarea ref="textarea" placeholder="What's on your mind?" rows="1" @input="detectOverflow" v-model="message" type="text"></textarea>
          <!-- <button class="circle-button" :style="isMuted ? 'background-color: var(--danger);' : ''" @click="toggleMute"><img :src="require(isMuted ? '../../assets/icons/meet-button-mic-off.svg' : '../../assets/icons/meet-button-mic.svg')" alt=""></button> -->
          <button @click="sendMeetMessage" type="submit" style="background-color: var(--secondaryOrg);"><img src="../../assets/icons/meet-button-send-message.svg"/></button>
          <button @click="openFileInput" type="file">
            <iconAttachment :color="secondaryOrgColor" :size="22"/>
          </button>
                
        </div>
      </div>
    </div>

    <!-- message panel component mobile view-->
    <div class="chat-container-mobile" v-if="isMobileView ? true : false">
      <div class="chat-component-mobile" :style="containerIsMinimized && !isPending ? '' : 'height: 42vh; bottom: 2vh; display: grid;'">
        <div :style="containerIsMinimized ? 'align-items: center; display: flex; justify-content: center;' : ''">
          <img v-if="containerIsMinimized" :src="business.photo" alt="" style="width: 50px;margin: 14px;">
          <img v-else :src="business.photo" alt="" style="width: 40px;margin: 14px;">
        </div>
        <div v-if="!isPending" :style="containerIsMinimized ? 'width: 100%;' : 'width: 100%; height: inherit; padding-bottom: 90px;'">
          <div v-if="showChat" :style="containerIsMinimized ? 'height: 100%; position: relative; display: grid;align-content: center; margin-right: 10px;' : 'height: 100%; overflow-y: scroll; position: inherit; padding: 0 25px; padding-bottom: 40px; margin-right: 10px;'" ref="messageContainer">
            <div class="messages-container-mobile">
              <div v-if="containerIsMinimized">
                <iconArrowUp @click="showFullContainer" style="position: absolute; top: 0; right: 10px;" :color="'#444444'" :size="24"/>
                <!-- <div>
                    <div :class="messages[messages.length-1].sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user-name' : 'message-guest-name'">{{ messages[messages.length-1].sender_name }}</div>
                    <div class="message-time" :style="messages[messages.length-1].sender_name == (localUser.fname+' '+localUser.lname) ? 'margin-left: auto;' : 'margin-right: auto;'">{{ calculateTimeDifference(messages[messages.length-1].created_at) }}</div>
                  </div> -->
                  <div v-if="messages[messages.length-1]" :class="messages[messages.length-1].sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user' : 'message-guest'" :style="messages[messages.length-1].is_attachment ? 'padding: 10px 10px;' : ''" style="margin-left: inherit; margin-right: auto;">
                    <div v-if="!messages[messages.length-1].is_attachment" style="font-size: 14px;">{{ messages[messages.length-1].message }}</div>
                    <div v-else class="file-message" @click="downloadFile(messages[messages.length-1])">
                      <div style="display: flex; padding: 5px 5px;">
                        <iconAttachment :color="secondaryOrgColor" :size="22"/>
                        <div style="display: grid; margin: 0 10px; color: #fff;">
                          <div class="file-name">{{ item.message }}</div>
                          <div class="file-info">{{ convertBytes(item.attachment_size) }} | Click to download</div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div v-else>
                <iconArrowDown @click="minimizeContainer" style="position: absolute; top: 10px; right: 20px;" :color="'#444444'" :size="24"/>
                <div v-for="(item, index) in messages" :key="index" style="margin-bottom: 10px;">
                  <div v-if="index == 0 || item.sender_name != messages[index - 1].sender_name">
                    <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user-name' : 'message-guest-name'">{{ item.sender_name }}</div>
                    <div class="message-time" :style="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'margin-left: auto;' : 'margin-right: auto;'">{{ calculateTimeDifference(item.created_at) }}</div>
                  </div>
                  <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user' : 'message-guest'" :style="item.is_attachment ? 'padding: 10px 10px;' : ''">
                    <div v-if="!item.is_attachment" style="font-size: 14px;">{{ item.message }}</div>
                    <div v-else class="file-message" @click="downloadFile(item)">
                      <div style="display: flex; padding: 5px 5px;">
                        <iconAttachment :color="secondaryOrgColor" :size="22"/>
                        <div style="display: grid; margin: 0 10px; color: #fff;">
                          <div class="file-name">{{ getLastPartOfURL(item.attachment_link) }}</div>
                          <div class="file-info">{{ convertBytes(item.attachment_size) }} | Click to download</div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div v-else style="height: 100%; display: flex; align-items: center;">
            <div class="choices-container-mobile">
              <div style="display: grid;" v-if="containerIsMinimized">
                <span style="color: var(--Dark-Gray, #444); font-size: 14px; font-weight: 400;">Hello <span style="font-weight: 600;">{{ localUser.fname+" "+localUser.lname }},</span></span>
                <span style="color: var(--Dark-Gray, #444); font-size: 12px; font-weight: 400;">Thank you for choosing <span style="font-weight: 700;">{{ business.name }}</span></span>
                <iconArrowUp @click="showFullContainer" style="position: absolute; top: 10px; right: 20px;" :color="'#444444'" :size="24"/>
              </div>

              <div v-else style="display: grid; padding: 0 15px;">
                <iconArrowDown @click="minimizeContainer" style="position: absolute; top: 10px; right: 20px;" :color="'#444444'" :size="24"/>
              <div style="display: grid;">
                <span style="color: var(--Dark-Gray, #444); font-size: 14px; font-weight: 400;">Hello <span style="font-weight: 600;">{{ localUser.fname+" "+localUser.lname }},</span></span>
                <span style="color: var(--Dark-Gray, #444); font-size: 12px; font-weight: 400;">Thank you for choosing <span style="font-weight: 700;">{{ business.name }}</span></span>
                <span style="color: var(--Dark-Gray, #444); font-size: 12px; font-weight: 400;">You are speaking to : <span style="font-weight: 600;" v-if="member && member.fname">{{ member.fname+" "+member.lname }}</span></span>
              </div>
              <span style="color: var(--Dark-Gray, #444); font-size: 13px; font-weight: 400;">How can we help you today?</span>
              <div class="services-mobile">
                <div v-for="(item, index) in topics" :key="index" style="margin-top: 5px;">
                  <button @click="selectService = item.title; showChat = true" class="services-button-mobile" :class="selectService == item.title ? 'services-button-selected' : ''">{{ item.title }}</button>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="choices-container" :style="isMobileView ? 'margin-bottom: 100px; font-size: 14px;' : 'margin-top: 30px;'">
            <div style="margin-bottom: 20px;">
            <span>Hello <span style="font-weight: 800;">{{ localUser.fname+' '+localUser.lname }}</span></span>
          </div>
          <div>
            <span>Please hold, while our agent approves your request to join the video conference call ...</span>
          </div>
          </div>
          <div style="position: absolute; bottom: 0; display: grid; width: 100%; padding: 10px 30px;">
            <button class="disclaimer-button" @click="showDisclaimer">Disclaimer</button>
            <button class="leave-button" @click="leaveCall">Leave the call</button>
          </div>
        </div>
        <div v-if="showChat && !containerIsMinimized" class="input-message">
          
          <input type="file" ref="fileInput" id="fileMessage" @change="getFile" style="display: none;" accept=".pdf, .png, .jpg">
          <textarea ref="textarea" placeholder="What's on your mind?" rows="1" @input="detectOverflow" v-model="message" type="text"></textarea>
          <!-- <button class="circle-button" :style="isMuted ? 'background-color: var(--danger);' : ''" @click="toggleMute"><img :src="require(isMuted ? '../../assets/icons/meet-button-mic-off.svg' : '../../assets/icons/meet-button-mic.svg')" alt=""></button> -->
          <button @click="sendMeetMessage" type="submit" style="background-color: var(--secondaryOrg);"><img src="../../assets/icons/meet-button-send-message.svg"/></button>
          <button @click="openFileInput" type="file">
            <iconAttachment :color="secondaryOrgColor" :size="22"/>
          </button>
                
        </div>
      </div>
    </div>

    <!-- ---------------------- -->

    
  </div>
  <div class="footer">
      <div style="display: flex; align-items: center;
    height: 100%; justify-content: space-between;">
        <div style="font-size: 18px;">
          <span>{{ currentTime }}</span>
          <span>|</span>
          <span>{{ room.room_name }}</span>
        </div>
        <div v-if="!isMobileView" style="font-size: 13px; display: flex;">
          <div id="mySelect" @click="openOption('mic')" class="custom-dropdown" :style="this.noSpeakerDevice || this.permissionSpeaker ? getDynamicStylesOptionsMDenied : getDynamicStylesOptionsM" @mouseover="micHover = true"  @mouseout="micHover = false">
            <iconMic :color="'#fff'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
            <span v-if="this.noSpeakerDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Speaker not found</span>
            <span v-else-if="this.permissionSpeaker" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
            <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('mic', selectedAudioId)">{{ getDeviceName("mic", selectedAudioId)}}</span>
            <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
            <div v-if="showMicOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="micHover = false">
              <div @click="micHover = false;updateCamRoom(selectedCamId, mic.deviceId)" class="cam-options" :style="selectedAudioId == mic.deviceId ? 'color: var(--primary)' : ''" v-for="(mic, index) in audios" :key="index" :value="mic.deviceId"><iconTick :color="selectedAudioId == mic.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ mic.label || 'Audio ' + (index + 1) }}</div>
            </div>
          </div>
          <div id="mySelect" @click="openOption('speaker')" class="custom-dropdown" :style="this.noAudioDevice || this.permissionAudio ? getDynamicStylesOptionsSDenied : getDynamicStylesOptionsS" @mouseover="speakerHover = true"  @mouseout="speakerHover = false">
            <iconSpeaker :color="'#fff'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
            <span v-if="this.noAudioDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Audio not found</span>
            <span v-else-if="this.permissionAudio" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
            <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('speaker', selectedAudioOutputId)">{{ getDeviceName("speaker", selectedAudioOutputId)}}</span>
            <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
            <div v-if="showSpeakerOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="speakerHover = false">
              <div @click="micHover = false;updateSpeakerRoom(speaker.deviceId)" class="cam-options" :style="selectedAudioOutputId == speaker.deviceId ? 'color: var(--primary)' : ''" v-for="(speaker, index) in speakers" :key="index" :value="speaker.deviceId"><iconTick :color="selectedAudioOutputId == speaker.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ speaker.label.split('(')[0] || 'Speaker ' + (index + 1) }}</div>
            </div>
          </div>
          <div id="mySelect" @click="openOption('cam')" class="custom-dropdown" :style="this.noVideoDevice || this.permissionVideo ? getDynamicStylesOptionsCDenied : getDynamicStylesOptionsC" @mouseover="camHover = true"  @mouseout="camHover = false">
            <iconCamera :color="'#fff'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
            <span v-if="this.noVideoDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Camera not found</span>
            <span v-else-if="this.permissionVideo" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
            <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('cam', selectedCamId)">{{ getDeviceName("cam", selectedCamId) }}</span>
            <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
            <div v-if="showCamOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="camHover = false">
              <div @click="micHover = false;updateCamRoom(camera.deviceId, selectedAudioId)" class="cam-options" :style="selectedCamId == camera.deviceId ? 'color: var(--primary)' : ''" v-for="(camera, index) in cameras" :key="index" :value="camera.deviceId"><iconTick :color="selectedCamId == camera.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ camera.label.split('(')[0] || 'Camera ' + (index + 1) }}</div>
            </div>
          </div>
        </div>
        <div v-else style="font-size: 13px;">
          <span @click="showConfigMobile"><img src="../../assets/icons/meet-config.svg" alt=""></span>
          <div class="overlay" :style="isConfigVisible ? 'display: block;' : 'display: none;'" @click="hideConfigMobile()"></div>
          <div class="bottom-panel" :class="{ 'show': isConfigVisible }">
            <!-- Content of the div -->
            <div style="display: grid; margin: 0 10px; align-items: center;" class="footer-mobile-img">
              <img style="margin-right: 5px;" src="../../assets/icons/meet-config.svg" alt="">
              <img :src="getIcon(recordingNow ? 'record-on' : 'record')" style="width: 20px; margin: 0 auto;">
              <img src="../../assets/icons/meet-speaker.svg" alt="">
              <img src="../../assets/icons/meet-sound.svg" alt="">
              <img src="../../assets/icons/meet-cam.svg" alt="">
            </div>
            <div style="display: grid; justify-items: left; align-items: center;">
              <span style="opacity: 0.5; padding: 5px 0px;"> Config</span>
              <span @click="showStartRecordingPopup();" style="padding: 5px 0px;"> {{ recordingNow ? 'Stop Recording' : 'Start recording' }}</span>
              <div id="mySelect" @click="openOption('mic')" class="custom-dropdown-mobile" :style="this.noSpeakerDevice || this.permissionSpeaker ? getDynamicStylesOptionsMDenied : getDynamicStylesOptionsM" @mouseover="micHover = true"  @mouseout="micHover = false">
                <span v-if="this.noSpeakerDevice" title="Camera not found">Speaker not found</span>
                <span v-else-if="this.permissionSpeaker" title="Camera not found">Permission needed</span>
                <span v-else :title="getDeviceName('mic', selectedAudioId)">{{ getDeviceName("mic", selectedAudioId)}}</span>
                <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
                <div v-if="showMicOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="micHover = false">
                  <div @click="micHover = false;updateCamRoom(selectedCamId, mic.deviceId)" class="cam-options" :style="selectedAudioId == mic.deviceId ? 'color: var(--primary)' : ''" v-for="(mic, index) in audios" :key="index" :value="mic.deviceId"><iconTick :color="selectedAudioId == mic.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ mic.label || 'Audio ' + (index + 1) }}</div>
                </div>
              </div>
              <div id="mySelect" @click="openOption('speaker')" class="custom-dropdown-mobile" :style="this.noAudioDevice || this.permissionAudio ? getDynamicStylesOptionsSDenied : getDynamicStylesOptionsS" @mouseover="speakerHover = true"  @mouseout="speakerHover = false">
                <span v-if="this.noAudioDevice" title="Camera not found">Audio not found</span>
                <span v-else-if="this.permissionAudio" title="Camera not found">Permission needed</span>
                <span v-else  :title="getDeviceName('speaker', selectedAudioOutputId)">{{ getDeviceName("speaker", selectedAudioOutputId)}}</span>
                <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
                <div v-if="showSpeakerOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="speakerHover = false">
                  <div @click="micHover = false;updateSpeakerRoom(speaker.deviceId)" class="cam-options" :style="selectedAudioOutputId == speaker.deviceId ? 'color: var(--primary)' : ''" v-for="(speaker, index) in speakers" :key="index" :value="speaker.deviceId"><iconTick :color="selectedAudioOutputId == speaker.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ speaker.label.split('(')[0] || 'Speaker ' + (index + 1) }}</div>
                </div>
              </div>
              <div id="mySelect" @click="openOption('cam')" class="custom-dropdown-mobile" :style="this.noVideoDevice || this.permissionVideo ? getDynamicStylesOptionsCDenied : getDynamicStylesOptionsC" @mouseover="camHover = true"  @mouseout="camHover = false">
                <span v-if="this.noVideoDevice" title="Camera not found">Camera not found</span>
                <span v-else-if="this.permissionVideo" title="Camera not found">Permission needed</span>
                <span v-else :title="getDeviceName('cam', selectedCamId)">{{ getDeviceName("cam", selectedCamId) }}</span>
                <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down.svg" alt="">
                <div v-if="showCamOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="camHover = false">
                  <div @click="micHover = false;updateCamRoom(camera.deviceId, selectedAudioId)" class="cam-options" :style="selectedCamId == camera.deviceId ? 'color: var(--primary)' : ''" v-for="(camera, index) in cameras" :key="index" :value="camera.deviceId"><iconTick :color="selectedCamId == camera.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ camera.label.split('(')[0] || 'Camera ' + (index + 1) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="!joined" class="loading-container align-items-center pt-5">
        <div v-if="!errorConnectRoom">
            <img
            src="../../../public/images/loading.gif"
            style="transform: translate(0, 30vh);"
            alt=""
            />
        </div>
        <div v-else style="display: grid; transform: translate(0, 100%); font-size: x-large; color: #fff;">
          <span>Unable to connect to the room</span>
          <span>{{errorMessage}}</span>
        </div>
    </div>
    <div :class="reconnecting ? 'reconnecting-transition' : 'reconnecting'">
      <span style="display: flex;">Oops! Trying to reconnect to the session <div class="pending-dot-red"></div></span>
    </div>

    <!-- <lbrx-meet-chat ref="messagesSidebar" v-model="noStatus" :showMessageModel="true"
    :messages="messages" :localUser="localUser" :isMember="isMember" @sendMeetMessage="sendMeetMessage"
    @sendMeetAttachment="sendMeetAttachment" icon="send-icon" color="#eee" background="#fff" height="85vw"
    width="25vw"></lbrx-meet-chat> -->


  <lbrx-popup-meet :active="modal.active" :type="modal.type" :hint="modal.hint"
    :message="modal.message" :client="participant && participant.fname ? participant.fname+' '+participant.lname : ''" :confirmationButton="modal.confirmationButton" :cancelButton="modal.cancelButton"
    @confirm="acceptParticipant" @decline="declineRequest" :recordingNow="recordingNow" @cancelRecording="cancelRecording" @startRecording="startRecording" @stopRecording="stopRecording" 
    :hasAudio="!isMuted" :hasVideo="!isCamHidden" :isMobileView="isMobileView" @closeDisclaimer="closeDisclaimer"></lbrx-popup-meet>
</template>
  
<script>
import iconCamera from "@/components/icons/camera.vue";
import iconCameraOff from "@/components/icons/camera-off.vue";
import iconSpeaker from "@/components/icons/micV2.vue";
import iconInfo from "@/components/icons/info.vue";
import iconComment from "@/components/icons/comment.vue";
import iconPhone from "@/components/icons/phone.vue";
import iconArrowUp from "@/components/icons/arrowUp.vue";
import iconArrowDown from "@/components/icons/arrowDown.vue";
import iconMicOff from "@/components/icons/mic-off.vue";
import loaderTail from "@/components/icons/loader-tail.vue";
import iconMic from "@/components/icons/mic-meet.vue";
import iconAttachment from "@/components/icons/attachment.vue";
import iconMenuH from "@/components/icons/menu-horizontal.vue";
import LbrxSubMenuButton from "@/components/LbrxSubMenuButton.vue";
import LbrxMeetChat from "@/components/LbrxMeetChat.vue";
import LbrxPopupMeet from "@/components/LbrxPopupMeet.vue";
import Disclaimer from "@/components/LbrxDisclaimer";
import { meetService, vendorsService, openviduService, businessService } from "@/_services";
import {notificationPlay} from '../../_helpers'
import {joinSession, toggleAudio, toggleVideo, toggleCamera, leaveSession} from '../../_helpers/openvidu'
import router from "@/router";
import moment from "moment/moment";
import {store} from "@/store";
import MediaDevices from 'media-devices';
let bodyStyles = document.body.style;
let activeRoom;

export default {
  name: "MeetPage",
  inject: ['echo'],
  components: {
    // LbrxMeetChat,
    LbrxPopupMeet,
    Disclaimer,
    iconCamera,
    iconCameraOff,
    iconComment,
    iconInfo,
    iconPhone,
    iconArrowUp,
    iconArrowDown,
    iconMicOff,
    iconMic,
    iconSpeaker,
    iconMenuH,
    loaderTail,
    iconAttachment
  },
  data() {
    return {
      noStatus: null,
      showMessageModel: false,
      secondaryOrgColor: bodyStyles.getPropertyValue("--secondaryOrg"),
      primaryOrgColor: bodyStyles.getPropertyValue("--secondaryOrg"),
      joined: false,
      user: '',
      localUser: {
        fname: 'hichem',
        lname: 'dimassi'
      },
      business: {},
      meet: {},
      accessToken: '',
      room: {
        room_name: this.$route.params.room_name,
        date_time: ''
      },
      modal: {
        active: false,
        type: "request",
        title: "New notification",
        message:
          "",
        confirmationButton: "Approve",
        cancelButton: "Reject"
      },
      twilioRoomSid: '',
      roomVar: null,
      time: 0,
      isPending: false,
      showAds: false,
      adUrl: '',
      isRunning: false,
      callAccepted: false,
      interval: null,
      isMuted: false,
      isCamHidden: false,
      showMicIcon: true,
      showCamIcon: true,
      secondVideoShow: true,
      audioContext: null,
      gainNode: null,
      participant: {
        fname: "",
        lname: ""
      },
      member: {
        photo: '',
        fname: '',
        lname: ''
      },
      errorConnectRoom: false,
      errorMessage: null,
      isMember: false,
      isAgent: false,
      isCustomer: false,
      messages: [],
      currentTime: '',
      message: "",
      file: null,
      fileName: "",
      isOverflowing: false,
      textAreaRow: 1,
      showChat: false,
      showChoices: false,
      services: [
        "Account Opening",
        "Personal Loan",
        "Mobile Banking",
        "Other"
      ],
      topics: [],
      selectService: null,
      requestSound: null,
      localTrack: false,
      checkTextAreaRow: 1,
      // Set mobile breakpoint threshold
      mobileBreakpoint: 768,
      isConfigVisible: false,
      containerIsMinimized: true,
      devicesInUse: {},
      selectedCamId: null,
      selectedAudioOutputId: null,
      selectedAudioId: null,
      // 
      mesHeight: 0,
      connection: null,
      session: null,
      showCamOption: false,
      showMicOption: false,
      showSpeakerOption: false,
      noVideoDevice: false,
      noAudioDevice: false,
      noSpeakerDevice: false,
      permissionVideo: false,
      permissionAudio: false,
      permissionSpeaker: false,
      camPermissionDenied: false,
      micPermissionDenied: false,
      cameras: [],
      speakers: [],
      audios: [],
      page: 1,
      perPage: 10,
      noMoreMessages: false,
      loadingMessages: false,
      scrollPosition: 0,
      showSettings: false,
      meetConfig: null,
      settings: [
        {title: "Start recording", img:"record" },
        {title: "Settings", img: "meet-setting"},
      ]
    };
  },
  created() {
    businessService.getTopics().then((res) => {
      this.topics = res.topics;
    })
    .catch((err) => {
        console.log(err);
      });
    // Initialize the time immediately when the component is created
    if(JSON.parse(localStorage.getItem("cam")) == null) {
      this.isCamHidden = true;
      this.isMuted = true;
      localStorage.setItem("cam", false);
      localStorage.setItem("mic", false);
      this.showCamIcon = false;
      this.showMicIcon = false;
      this.secondVideoShow = false;
    } else {
      this.isCamHidden = !JSON.parse(localStorage.getItem("cam"));
      this.isMuted = !JSON.parse(localStorage.getItem("mic"));
      this.showCamIcon = JSON.parse(localStorage.getItem("cam"));
      this.showMicIcon = JSON.parse(localStorage.getItem("mic"));
      this.secondVideoShow = JSON.parse(localStorage.getItem("cam"));
    }

    this.updateTime();

    this.session = JSON.parse(localStorage.getItem('session'));
    this.connection = JSON.parse(localStorage.getItem('connection'));

    // if(this.session.recording) {
    //   this.recordingNow = true;
    // }

    // Set up a setInterval to update the time every minute
    setInterval(this.updateTime, 1000); // 60000 milliseconds = 1 minute
    if (JSON.parse(localStorage.getItem('devicesInUse'))) {
      this.devicesInUse = JSON.parse(localStorage.getItem('devicesInUse'));
      this.selectedCamId = this.devicesInUse.selectedCamId;
      this.selectedAudioId = this.devicesInUse.selectedAudioId;
      this.selectedAudioOutputId = this.devicesInUse.selectedAudioOutputId;
    }
    this.getDevices();
    this.meetConfig = JSON.parse(localStorage.getItem("config"));
    if(this.meetConfig) {
      bodyStyles.setProperty('--primaryOrg', this.meetConfig.primary_color);
      bodyStyles.setProperty('--secondaryOrg', this.meetConfig.secondary_color);
      this.secondaryOrgColor = this.meetConfig.secondary_color;
      this.primaryOrgColor = this.meetConfig.primary_color;
      if(JSON.parse(this.meetConfig.config).showAds) {
        this.showAds = JSON.parse(this.meetConfig.config).showAds;
        this.adUrl = JSON.parse(this.meetConfig.config).adUrl;
      }
    }
  },
  computed: {
    subscriberAudio() {
      return this.$store.getters.getSubscriberAudio;
    },
    subscriberVideo() {
      return this.$store.getters.getSubscriberVideo;
    },
    participantJoined() {
      return this.$store.getters.getParticipantJoined;
    },
    recordingNow() {
      return this.$store.getters.getRecordingNow;
    },
    reconnecting() {
      return this.$store.getters.getReconnecting;
    },
    sessionDisconnected() {
      return this.$store.getters.getSessionDisconnected
    },
    isMobileView() {
      return window.innerWidth <= this.mobileBreakpoint;
    },
    circleImageSecond() {
      return {
        border: '4px solid #3498db',
        borderRadius: '50%',
        height: this.participantJoined ? '90px' : '9vw',
        width: this.participantJoined ? '90px' : '9vw',
        objectFit: 'cover'
      };
    },
    circleImageSecondMobile() {
      return {
        border: '4px solid #3498db',
        borderRadius: '50%',
        height: this.participantJoined ? '60px' : '130px',
        width: this.participantJoined ? '60px' : '130px',
        objectFit: 'cover'
      };
    },
    getDynamicStylesSecondVid() {
      // Define your styles based on conditions
      return {
        backgroundColor: !this.secondVideoShow ? '#444444' : '',
        height: this.isMobileView && this.participantJoined ? '120px' : '',
        width: this.isMobileView && this.participantJoined ? '120px' : ''
      };
    },
    getDynamicStylesSecondVidAvatar() {
      // Define your styles based on conditions
      return {
        backgroundColor: 'var(--primaryOrg)',
        height: !this.participantJoined ? '150px' : '90px',
        width: !this.participantJoined ? '150px' : '90px',
        display: 'grid'
      };
    },
    getDynamicStylesSecondVidAvatarM() {
      // Define your styles based on conditions
      return {
        backgroundColor: 'var(--primaryOrg)',
        height: !this.participantJoined ? '130px' : '65px',
        width: !this.participantJoined ? '130px' : '65px',
        display: 'grid'
      };
    },
    getDynamicStylesOptionsM() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.micHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.micHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsS() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.speakerHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.speakerHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsC() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.camHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.camHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsCDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
    getDynamicStylesOptionsMDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
    getDynamicStylesOptionsSDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
  },
  mounted() {
      this.listenForDevicesChangeRoom();
      if (JSON.parse(localStorage.getItem('isAgent'))) {
        this.isAgent = JSON.parse(localStorage.getItem('isAgent'));
      }
      // 
    if (this.isAgent == true) {
      this.localUser = JSON.parse(localStorage.getItem('member'));
      this.business = JSON.parse(localStorage.getItem('user')).business;
      this.showChat = true
      this.getAccessToken();
    } 
    // customer route
    else {
        this.joined = true;
        if(JSON.parse(localStorage.getItem('isPending'))) {
          this.isPending = true;
          this.listenForRequest();
        } else {
          this.isPending = false;
          this.joined = false;
          this.getAccessToken();
        }
        // if(JSON.parse(localStorage.getItem('agent'))) {
        //   this.isPending = true;
        //   this.listenForRequest();
        // }
      this.localUser = JSON.parse(localStorage.getItem('customer'));
      this.member = JSON.parse(localStorage.getItem('member'));
      this.isCustomer = true;
      this.showChoices = true;

      vendorsService.getVendorById(this.localUser.business).then((res) => {
        this.business = res.data;
        localStorage.setItem("business", JSON.stringify(this.business));
      }).catch((err) => {
        console.log(err);
      });
    // } else {
    //   this.localUser = JSON.parse(localStorage.getItem('user')).user;
    //   this.business = JSON.parse(localStorage.getItem('user')).business;
    //   this.isMember = true;
    //   this.showChat = true;
    // }
    }
    // Add an event listener to update the computed property on window resize
    window.addEventListener('resize', this.handleWindowResize);
    // Initial check on component mount
    this.handleWindowResize();
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.handleWindowResize);
    leaveSession();
  },
  watch: {
    accessToken: {
      handler(val, old) {
        if (val !== '') {
          this.joinSession()
        }
      }
    },
    sessionDisconnected: {
      handler(val, old) {
        console.log("gggggggggggg : ", val)
        if(val) {
          this.getAccessToken();
          console.log("eeeeeeeeeeeeeee : ", val)
        }
      }
    },
  },
  methods: {
    displayChat() {
      this.showChat = true;
      // Scroll to the bottom of the chat container
      if(this.$refs.messageContainer != undefined) {
            this.$nextTick(() =>this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight);
        }
    },
    saveDevicesConfig() {
      localStorage.setItem("devicesInUse", JSON.stringify({ 
        selectedCamId: this.selectedCamId, 
        selectedAudioId: this.selectedAudioId, 
        selectedAudioOutputId: this.selectedAudioOutputId
      }));
    },
    async updateCamRoom(deviceIdCam, deviceIdMic) {
      if(this.selectedCamId != deviceIdCam || this.selectedAudioId != deviceIdMic) {
        this.selectedCamId = deviceIdCam;
        this.selectedAudioId = deviceIdMic;
        if(this.callAccepted) {
          this.secondVideoShow = false;
          await toggleCamera(deviceIdCam, deviceIdMic);
          this.secondVideoShow = true;
          this.saveDevicesConfig();
        } else {
          this.saveDevicesConfig();
        }
      }
    },
    async updateSpeakerRoom(deviceId) {
      // Set the audio output device for the video element
      this.selectedAudioOutputId = deviceId;
      if(this.callAccepted) {
        const videoElement = document.getElementById('participant-user');
        if (this.selectedAudioOutputId && videoElement) {
          try {
            await videoElement.setSinkId(this.selectedAudioOutputId);
            // After setting the audio output device, save the devices configuration
            this.saveDevicesConfig();
          } catch (error) {
            console.error('Error setting sink ID:', error);
          }
        }
      } else {
        this.saveDevicesConfig();
      }
      
    },
    async getDevices() {
      try {
        // Enumerate devices and filter for video input devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log("deeeeee : ", devices)
        this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
        this.speakerDevices = devices.filter((device) => device.kind === 'audiooutput');
        this.audioDevices = devices.filter((device) => device.kind === 'audioinput');

          // remove ducplicates
          this.cameras = this.videoDevices.reverse().filter((obj, index) => {
            return index === this.videoDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.speakers = this.speakerDevices.reverse().filter((obj, index) => {
            return index === this.speakerDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.audios = this.audioDevices.reverse().filter((obj, index) => {
            return index === this.audioDevices.findIndex(o => obj.groupId === o.groupId);
          });
          // console.log("====================", devices)
          console.log('booooooooooooo : ', this.getDevicesConfig())
          if(this.getDevicesConfig()) {
            const devicesStored = this.getDevicesConfig();
            this.selectedCamId = devicesStored.selectedCamId;
            this.selectedAudioOutputId = devicesStored.selectedAudioOutputId;
            this.selectedAudioId = devicesStored.selectedAudioId;

            if(!this.selectedCamId) {
              if(this.cameras.length != 0) {
                this.selectedCamId = this.cameras[0].deviceId;
              }
            }
            if(!this.selectedAudioOutputId) {
              if(this.speakers.length != 0) {
                this.selectedAudioOutputId = this.speakers[0].deviceId;
              }
            }
            if(!this.selectedAudioId) {
              if(this.audios.length != 0) {
                this.selectedAudioId = this.audios[0].deviceId;
              }
            }
            
          } else {
            if(this.cameras.length != 0) {
              this.selectedCamId = this.cameras[0].deviceId;
            }
            if(this.speakers.length != 0) {
              this.selectedAudioOutputId = this.speakers[0].deviceId;
            }
            if(this.audios.length != 0) {
              this.selectedAudioId = this.audios[0].deviceId;
            }
          }
          console.log('pooooooooooooo : ', this.selectedAudioId)
          this.devicesLoaded = true;
          this.saveDevicesConfig();
       

        
      } catch (error) {
        console.error('Error enumerating devices:', error);
      }
    },
    getDevicesConfig() {
      return JSON.parse(localStorage.getItem("devicesInUse"));
    },
    handleWindowResize() {
      // Update the computed property on window resize
      this.isMobileView = window.innerWidth < this.mobileBreakpoint;
    },
    detectOverflow() {
      // if(this.$refs.textarea.scrollHeight > this.$refs.textarea.offsetHeight) {
      //   this.textAreaRow = 2;
      // } else {
      //   this.textAreaRow = 1;
      // }
      const textarea = this.$refs.textarea;
      
      if(textarea.scrollHeight <= 70) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    openOption(type) {
      if(type == "cam") {
        this.showCamOption = !this.showCamOption;
      } else if(type == "mic") {
        this.showMicOption = !this.showMicOption;
      } else {
        this.showSpeakerOption = !this.showSpeakerOption;
      }
    },
    getDeviceName(type, id) {
      if(type == "cam") {
        const cam = this.cameras.find(obj => {
          if(obj.deviceId == id) {return obj}
        });
        return cam ? cam.label : 'No device selected';
      } else if(type == "mic") {
        const audio = this.audios.find(obj => {
          if(obj.deviceId == id) {return obj}
        });
        return audio ? audio.label : 'No device selected';
      } else {
        const speaker = this.speakers.find(obj => {
          if(obj.deviceId == id) {return obj.label}
        });
        return speaker ? speaker.label : 'No device selected';
      }
    },
    updateTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      this.currentTime = `${hours}:${minutes}`;
    },
    toggleCam() {
      // if(!this.participantJoined) {
      //   this.mainVideoShow = !this.mainVideoShow;
      // } else {
        this.secondVideoShow = !this.secondVideoShow;
      // }
      this.showCamIcon = !this.showCamIcon;


      activeRoom.localParticipant.videoTracks.forEach(track => {
          console.log('eee : ', track)
          console.log('eee222 : ', track.track)
          if (this.isCamHidden) {
            this.$nextTick(() => {
              track.track.enable();
              console.log('enable')
            });
          } else {
            track.track.disable();
            // this.$nextTick(() => {
            //   track.unpublish();
            //   track.track.stop();
              
            //   console.log('disable')
            // });
          }
          this.isCamHidden = !this.isCamHidden;
        });


    },
    toggleAudio() {
      this.showMicIcon = !this.showMicIcon;
      toggleAudio(this.isMuted ? true : false);
      this.isMuted = !this.isMuted;
    },
    toggleVideo() {
      this.secondVideoShow = !this.secondVideoShow;
      this.showCamIcon = !this.showCamIcon;
      toggleVideo(this.isCamHidden ? true : false);
      this.isCamHidden = !this.isCamHidden;
    },
    async joinSession() {
      try {
        const session = await joinSession(this.$refs.secondVideo, this.accessToken).then(() => {
          this.joined = true;
          this.connectWebsocket();
          this.getMeetMessages();
        });
      } catch (error) {
        console.error(error);
        this.errorMessage = error.message;
      }
    },
    getAccessToken: function () {
      // Request a new token
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      let payload = {
        room_name: this.$route.params.room_name
      }

      if (this.isAgent) {
        meetService.joinMember(payload)
          .then(function (response) {
            that.meet = response.meet;
            that.accessToken = response.token
            that.participant = response.customer;
            that.member = response.user;
            that.callAccepted = true;
            if(response.config) {
              localStorage.setItem("config", JSON.stringify(response.config));
              bodyStyles.setProperty('--primaryOrg', response.config.primary_color);
              bodyStyles.setProperty('--secondaryOrg', response.config.secondary_color);
              this.secondaryOrgColor = response.config.secondary_color;
              this.primaryOrgColor = response.config.primary_color;
            }
            // that.accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2ZjY2ViODMxOTMwYTRmN2ZlYzVmNjkyNGVlOGJlZjg1LTE2OTg1MjYyNjUiLCJpc3MiOiJTS2ZjY2ViODMxOTMwYTRmN2ZlYzVmNjkyNGVlOGJlZjg1Iiwic3ViIjoiQUMzYTZiZmE0MjkyYTg1OTkwN2MwMWE2MzNlNDU3YTRiNyIsImV4cCI6MTY5ODUyOTg2NSwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiNjUzZDc0Mzk2N2RkZSIsInZpZGVvIjp7InJvb20iOiJMYnJ4LVZpcnR5LTVQdHktakNQNSJ9fX0.yXNt-N1bEOfMU8xCyi1F-iRTIzTSBXWZWtLhdw1y6AI"
          })
          .catch(function (error) {
            that.errorConnectRoom = true;
          })
      } else {
        meetService.joinParticipant(payload)
          .then(function (response) {
            that.meet = response.meet;
            that.accessToken = response.token
            that.callAccepted = response.meet.call_accepted;
            if(response.config) {
              bodyStyles.setProperty('--primaryOrg', response.config.primary_color);
              bodyStyles.setProperty('--secondaryOrg', response.config.secondary_color);
            }
            // that.participant = response.customer;
            // that.member = response.member;
            // that.accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2ZjY2ViODMxOTMwYTRmN2ZlYzVmNjkyNGVlOGJlZjg1LTE2OTg1MjYyNjUiLCJpc3MiOiJTS2ZjY2ViODMxOTMwYTRmN2ZlYzVmNjkyNGVlOGJlZjg1Iiwic3ViIjoiQUMzYTZiZmE0MjkyYTg1OTkwN2MwMWE2MzNlNDU3YTRiNyIsImV4cCI6MTY5ODUyOTg2NSwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiNjUzZDc0Mzk2N2RkZSIsInZpZGVvIjp7InJvb20iOiJMYnJ4LVZpcnR5LTVQdHktakNQNSJ9fX0.yXNt-N1bEOfMU8xCyi1F-iRTIzTSBXWZWtLhdw1y6AI"
          })
          .catch(function (error) {
            console.log(error.message);
            that.errorConnectRoom = true;
          })
      }
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        console.log('timer stops');
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },

    connectWebsocket() {
      if(this.isAgent) {
        this.echo.channel("meet-room-" + this.meet.id).listen(
        ".participant-request",
        function (socket_data) {
          console.log(socket_data);
          this.modal.active = true;
          notificationPlay.request();
          this.requestSound = setInterval(() => {
            notificationPlay.request();
          }, 20000)
        }.bind(this)
      )
      }

      this.echo.channel("meet-room-" + this.meet.id).listen(
        ".meet-message",
        function (socket_data) {
          console.log('aaaaa')
          this.messages.push(socket_data.message);
          // Scroll to the bottom of the chat container
          if(this.$refs.messageContainer != undefined) {
            this.$nextTick(() =>this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight);
          }
          
          if(socket_data.message.sender_name != this.localUser.fname + ' ' + this.localUser.lname) {
            notificationPlay.message();
          }
        }.bind(this)
      )

      this.echo.channel("meet-room-" + this.meet.id).listen(
        ".organizer-end-meet",
        function (socket_data) {
          console.log(socket_data);
          if(this.isCustomer) {
            meetService.logoutParticipant();
            router.push({path: "/"+this.room.room_name});
          }
        }.bind(this)
      )
      
      this.echo.channel("meet-room-" + this.meet.id).listen(
        ".participant-end-meet",
        function (socket_data) {
          console.log(socket_data);
        }.bind(this)
      )

        // if(!this.isMember) {
          this.echo.channel("meet-room-" + this.meet.id).listen(
            ".organizer-change-camera-status",
            function (socket_data) {
              console.log("camera : ", socket_data);
              // setTimeout(() => {
              //   this.connectPart()
              // }, 500);
              
            }.bind(this)
          )
        // } else {
          this.echo.channel("meet-room-" + this.meet.id).listen(
            ".participant-change-camera-status",
            function (socket_data) {
              console.log("camera : ", socket_data);
              // this.connectPart()
            }.bind(this)
          )
        // }
      
    },

    checkMeetStatus(meet) {
      if (meet.call_accepted === 1) {
        this.startCall()
      }
    },

    startCall() {
      // localStorage.removeItem("meet");
      localStorage.removeItem("isPending");
      this.isPending = false;
      this.isCustomer = true;
      this.showChoices = true;

      this.getAccessToken();
    },

    acceptParticipant() {
      this.modal.active = false;
      clearInterval(this.requestSound)
      let payload = {
        id: this.meet.id
      }
      meetService.acceptParticipant(payload).then((res) => {
        console.log(res);
      }).catch((err) => {
        console.log(err);
      })
    },

    declineRequest() {
      clearInterval(this.requestSound)
      this.modal.active = false;
    },

    getMeetMessages(lazyLoad = false) {
      this.loadingMessages = true;
      meetService.getMeetMessages(this.meet.id, this.perPage, this.page).then((res) => {
        if(this.$refs.messageContainer) {
          this.mesHeight = this.$refs.messageContainer.scrollHeight;
        }
        if(res.results.messages.length < this.perPage && lazyLoad) {
          this.noMoreMessages = true;
        }
        this.messages = res.results.messages.slice().reverse().concat(this.messages);
        
        
        // Scroll to the bottom of the chat container
        if(this.$refs.messageContainer != undefined && !lazyLoad) {
            this.$nextTick(() =>this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight);
        } else {
          if(!this.noMoreMessages) {
            // stay at the same scroll height position when loading more messages
            if(this.$refs.messageContainer) {
              this.$nextTick(() =>this.$refs.messageContainer.scrollTop = this.mesHeight);
            }
          }
        }
        
      })
      .catch((err) => {
        console.log(err);
      })  
      .finally(() => {
        this.loadingMessages = false;
      })
    },

    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop === 0 && !this.noMoreMessages) {
        this.scrollPosition = scrollTop;
        this.page += 1;
        this.getMeetMessages(true);
      }
    },

    endCallbyId() {
      meetService.endCallbyId(this.meet.id).then((res) => {
        leaveSession();
        if(this.isAgent) {
          router.push({path: "/agent-dashboard"});
        } else {
          meetService.logoutParticipant();
          router.push({path: "/room/"+this.room.room_name+"/feedback"});
        }
      }).catch((err) => {
        console.log(err);
      })
    },

    leaveCall() {
      meetService.logoutParticipant();
      router.push({path: "/"+this.room.room_name});
    },

    showDisclaimer() {
      this.modal.active = true;
      this.modal.type = "disclaimer";
      this.modal.title = "Disclaimer";
      // this.modal.message = this.disclaimerMessage;
    },
    closeDisclaimer() {
      this.modal.active = false;
    },

    sendMeetMessage() {
      var payload = {
        room_id: this.meet.id,
        message: this.message
      }
      if (this.isAgent) {
        if(this.file) {
          payload.attachment = this.file
        }
        meetService.sendMeetMessage(payload).then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        })
      } else {
        payload.full_name = this.localUser.fname + ' ' + this.localUser.lname;
        if(this.file) {
          payload.attachment = this.file;
        }
        meetService.sendMeetMessageParticipant(payload).then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        })
      }
      this.clearMessage();
    },

    sendMeetAttachment() {
      if (this.isAgent) {
        let payload = {
          room_id: this.meet.id,
          message: this.fileName,
          attachment: this.file
        }
        meetService.sendMeetMessage(payload).then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        })
      } else {
        let payload = {
          room_id: this.meet.id,
          message: this.fileName,
          attachment: this.file,
          full_name: this.localUser.fname + ' ' + this.localUser.lname
        }
        meetService.sendMeetMessageParticipant(payload).then((res) => {
          console.log(res);
        }).catch((err) => {
          console.log(err);
        })
      }
      this.deleteFile()
      // this.$refs.messagesSidebar.deleteFile();
    },
    clearMessage() {
        this.message = "";
        this.textAreaRow = 1;
    },

    openFileInput() {
        // Trigger the click event on the hidden file input
        this.$refs.fileInput.click();
        },
        getFile() {
            if (
                document.getElementById("fileMessage") &&
                document.getElementById("fileMessage").files[0]
            ) {
                let rawImg;
                const file = document.getElementById("fileMessage").files[0];
                const reader = new FileReader();
                this.fileName = file.name;

                reader.onloadend = () => {
                rawImg = reader.result;
                this.file = rawImg;
                };
                reader.readAsDataURL(file);
            }
            setTimeout(() => {
              this.sendMeetAttachment();
            }, 200);
            
        },
        deleteFile() {
            const fileInput = document.getElementById("fileMessage");
            fileInput.value = ''; // Reset the file input
            this.file = null;
            this.fileName = "";
        },

        calculateTimeDifference(timestamp) {
          // Parse the provided timestamp and the current time using Moment.js
          const createTime = moment(timestamp);
          const currentTime = moment();

          // Calculate the difference in minutes
          const minutesDifference = currentTime.diff(createTime, 'minutes');

          if (minutesDifference >= 60) {
            // Convert minutes to hours if the difference is greater than or equal to 60 minutes
            const hoursDifference = Math.floor(minutesDifference / 60);
            return `${hoursDifference} hour ago`;
          } else if (minutesDifference == 0) {
            return `now`;
          } else {
            return `${minutesDifference} minutes ago`;
          }
        },

        getLastPartOfURL(url) {
          // Split the URL by '/'
          const parts = url.split('/');
          // Remove empty parts (caused by double slashes or leading/trailing slashes)
          const filteredParts = parts.filter(part => part.trim() !== '');
          // Get the last part
          const lastPart = filteredParts[filteredParts.length - 1];
          return lastPart;
        },

        async downloadFile(item) {
          if (item.is_attachment == 1) {
          //   try {
          //     const response = await fetch(item.attachment_link);
          //     const blob = await response.blob();
          //     const url = window.URL.createObjectURL(blob);

          //     // Create an invisible anchor element to trigger the download
          //     const link = document.createElement('a');
          //     link.href = url;
          //     link.target = '_blank';
          //     link.download = this.getLastPartOfURL(item.attachment_link); // You can set the default name for the downloaded file here

          //     // Trigger the click event on the anchor element
          //     link.click();

          //     // Clean up
          //     window.URL.revokeObjectURL(url);
          //   } catch (error) {
          //     console.error('Error downloading file:', error);
          //   }
          // } else {
          //   alert('Please enter a valid file URL');
          // }
          if (this.isAgent) {
            meetService.downloadFile(item.id).then((res) => {
              // Convert the base64 string to a Uint8Array
              const binaryString = atob(res.attachment_data.split(',')[1]);
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
              }

              // Create a Blob from the Uint8Array
              const blob = new Blob([bytes]);

              // Create a link element
              const a = document.createElement('a');
              a.href = URL.createObjectURL(blob);

              // Specify the filename for the download
              a.download = res.file_name;

              // Append the link element to the document
              document.body.appendChild(a);

              // Trigger a click on the link to start the download
              a.click();

              // Remove the link element from the document
              document.body.removeChild(a);
            }).catch((err) => {
              console.log(err);
            })
          } else {
            meetService.downloadFileParticipant(item.id).then((res) => {
              // Convert the base64 string to a Uint8Array
              const binaryString = atob(res.attachment_data.split(',')[1]);
              const len = binaryString.length;
              const bytes = new Uint8Array(len);
              for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
              }

              // Create a Blob from the Uint8Array
              const blob = new Blob([bytes]);

              // Create a link element
              const a = document.createElement('a');
              a.href = URL.createObjectURL(blob);

              // Specify the filename for the download
              a.download = res.file_name;

              // Append the link element to the document
              document.body.appendChild(a);

              // Trigger a click on the link to start the download
              a.click();

              // Remove the link element from the document
              document.body.removeChild(a);
            }).catch((err) => {
              console.log(err);
            })
          }
        }
        },

        getIcon(icon) {
          var images = require.context('../../assets/')
          return images('./icons/' + icon + ".svg")
        },

        listenForRequest() {
          this.echo.channel("meet-room-" + JSON.parse(localStorage.getItem('meet')).id).listen(
              ".participant-request-accepted",
              function (socket_data) {
                this.checkMeetStatus(socket_data.meet);
              }.bind(this)
          )
        },
        convertBytes(bytes) {
          // Convert bytes to megabytes (1 MB = 1024 * 1024 bytes)
          if (bytes < 1024) {
            // Display kilobytes
            return `${bytes} KB`;
          } else {
            // Display megabytes
            const megabytes = (bytes / (1024 * 1024)).toFixed(2);
            return `${megabytes} MB`;
          }
        },

    showConfigMobile() {
      this.isConfigVisible = true;
    },
    hideConfigMobile() {
      this.isConfigVisible = false;
    },
    showFullContainer() {
      this.containerIsMinimized = false;
    },
    minimizeContainer() {
      this.containerIsMinimized = true;
    },
    checkPermessions() {
      let videoStreamVar;
      let videoStreamVar2;
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((videoStream) => {
          videoStreamVar = videoStream;
          console.log('Video permission granted');
          // Revoke the tracks to turn off the camera
          if (videoStream) {
            const tracks = videoStream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionVideo = true;
              this.camPermissionDenied = true;
              this.isCamHidden = true;
              break;
            case 'NotFoundError':
              this.noVideoDevice = true;
              this.isCamHidden = true;
              this.camPermissionDenied = true;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
            // Revoke the tracks to turn off the camera
            if (videoStreamVar) {
              const tracks = videoStreamVar.getTracks();
              tracks.forEach((track) => track.stop());
            }
          }
        });

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((audioStream) => {
          console.log('audio permission granted');
          setTimeout(() => {
            audioStream.getTracks().forEach(track => track.stop());
            console.log('Audio stream stopped');
          },200);
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionAudio = true;
              this.isMuted = true;
              this.micPermissionDenied = true;
              break;
            case 'NotFoundError':
              this.noAudioDevice = true;
              this.isMuted = true;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
            
          }
        });

      navigator.mediaDevices.getUserMedia({ audio: { output: true } })
        .then((videoStream) => {
          console.log('Speaker permission granted');

          // Do something with the video stream if needed

          // Now, request permission for audio
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionSpeaker = true;
              break;
            case 'NotFoundError':
              this.noSpeakerDevice = true;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
          }
        });
    },
    showStartRecordingPopup() {
      this.showSettings = false;
      this.modal.active = true;
      this.modal.type = "recording-start";
      this.modal.message = "Make sure to follow all state/local call recording laws and inform partcicpants where required";
      this.modal.confirmationButton = "START RECORDING";
      this.modal.cancelButton = "STOP RECORDING";
    },
    cancelRecording() {
      this.modal.active = false;
    },
    startRecording() {
      let payload = {
        session: this.$route.params.room_name,
        name: this.meet.twilio_member_identity,
        outputMode: "COMPOSED",
        hasAudio: !this.isMuted,
        hasVideo: !this.isCamHidden,
        recordingLayout: "BEST_FIT",
        customLayout: "",
        resolution: "1280x720"
      }
      openviduService.startRecording(payload)
          .then((res) => {
            if (res == undefined) {
              console.log(res);
            } else {
              console.log(res);
              this.recordingNow = true;
              this.modal.active = false;
            }
          })
          .catch((err) => {
            console.log(err);
          })
    },
    stopRecording() {
      openviduService.stopRecording(this.$route.params.room_name)
          .then((res) => {
            if (res == undefined) {
              console.log(res);
            } else {
              console.log(res);
              this.recordingNow = false;
              this.modal.active = false;
            }
          })
          .catch((err) => {
            console.log(err);
          })
    },
    listenForDevicesChangeRoom() {
      MediaDevices.ondevicechange = ({ changes, devices }) => {
        console.log("changes, ", changes);
        console.log("devices, ", devices);
        this.getDevicesDynamically(devices);
        setTimeout(() => {
          changes.map((change) => {
            if(change.type == "add") {
              // if(change.newInfo.kind == "audioinput")
              //   this.updateCam(this.selectedCamId, change.newInfo.groupId);
              if(change.device.kind == "audiooutput") {
                console.log('eeeeeeeeeeeeeee, ', change.device.deviceId)
                this.updateSpeakerRoom(change.device.deviceId);
              }
              if(change.device.kind == "audioinput") {
                this.updateCamRoom(this.selectedCamId, change.device.deviceId);
              }
              // else
              //   this.updateCam(change.newInfo.groupId, this.selectedAudioId);
            }
            if(change.type == "remove") {
              if(change.device.kind == "audiooutput") {
                this.updateSpeakerRoom(this.speakers[0].deviceId);
              }
              if(change.device.kind == "audioinput") {
                this.updateCamRoom(this.selectedCamId, this.audios[0].deviceId);
              }
            }
          });
        }, 100);
          
      }
    },
    getDevicesDynamically(devices) {
      this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
        this.speakerDevices = devices.filter((device) => device.kind === 'audiooutput');
        this.audioDevices = devices.filter((device) => device.kind === 'audioinput');

          // remove ducplicates
          this.cameras = this.videoDevices.reverse().filter((obj, index) => {
            return index === this.videoDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.speakers = this.speakerDevices.reverse().filter((obj, index) => {
            return index === this.speakerDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.audios = this.audioDevices.reverse().filter((obj, index) => {
            return index === this.audioDevices.findIndex(o => obj.groupId === o.groupId);
          });
    },
    
  },


};
</script>
  
<style scoped>

.logo-liberrex {
  position: absolute;
  width: 10vw;
  left: 3vw;
  top: 4vh;
}
.logo-liberrex-mobile {
  position: absolute;
  width: 25vw;
  left: 3vw;
  top: 4vh;
}
.loading-container {
  overflow-y: hidden;
  overflow-x: hidden;
  background: #202124;
  margin: 0 auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}
.home-container {
  overflow-y: hidden;
  overflow-x: hidden;
  background: #202124;
  margin: 0 auto;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

.footer {
  background-color: #000;
  color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 5vh;
}

.footer span {
  margin: 0 10px;
}
.footer img {
  margin: 0 7px;
}

.top-video {
  width: 100%;
  height: 85%;
  margin: 0 auto 2vh auto;
  border-radius: 10px 10px 0 10px;
  border: 4px solid var(--online);
  display: flex;
  transition: width 0.3s;
}

.main-container {
  width: 75vw;
  height: 87vh;
}
.main-container-mobile {
  width: 100vw;
  height: 84vh;
  transition: height 0.3s ease;
}

.main-video {
  height: 91%;
    margin: 4vh 2vw 4vh 3vw;
    position: relative;
    top: 9%;
}
.main-video-mobile {
  height: 85%;
    margin: 4vh 4vw;
    margin-bottom: 0;
    position: relative;
    top: 6%;
  /* Set the parent div to relative positioning */
  /* transition: width 0.3s; */
  /* transform: translate(23%, 0); */
}

.chat-container {
  width: 27vw;
  height: 87vh;
  position: relative;
}
.chat-container-mobile {
  width: 100vw;
  height: 100%;
  position: relative;
}

.chat-component {
  margin: 4vh 3vw 4vh 0;
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.chat-component-mobile {
  margin: 0 4vw;
  background-color: #fff;
  height: 11vh;
  display: flex;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  bottom: 4vh;
  transition: height 0.3s ease;
}

.input-message {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 10px 10px;
  bottom: 0;
  background-color: var(--primaryOrg);
}
.input-message button {
  width: 40px;
    height: 40px;
    margin: 0 7px;
    border: none;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
.input-message textarea {
  font-size: 15px;
  width: 70%;
  border-radius: 5px;
  border: none;
  margin: 0 5px;
  padding: 3px 7px;
  outline: none;
  resize: none;
  overflow-y: hidden;
  line-height: normal;
}

.participant-window-style {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 150px;
  width: 200px;
  margin: 10px;
  border: 4px solid var(--primary);
  border-radius: 13px;
  display: flex;
}

.localuser-window-style {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
}

.participant-video-window {
  height: 20%;
  margin: auto;
  display: flex;
  transition: width 0.3s;
  transform: translate(43%, 0);
}

.main-video-minimized {
  width: 81%;
  height: 92%;
  margin: 1vh;
  transition: width 0.3s;
  position: relative;
}

.disclaimer {
  position: absolute;
  bottom: 0;
  display: flex;
  margin: 0 25px 10px 25px;
}

.main-video-photo-icon {
  position: absolute;
  display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  color: #fff;;
  font-family: 'Exo 2', serif;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  border-radius: 0 0 10px 10px;
  padding: 8px ;
}
.main-video-photo-icon img {
  width: 17vw;
  height: 17vw;
}

.second-video-photo-icon {
  display: grid;
    /* bottom: 27%; */
    justify-items: center;
    align-self: center;
    align-items: center;
    color: #fff;
    font-family: 'Exo 2', serif;
    width: 100%;
    /* height: 100%; */
    font-size: 22px;
    font-weight: 500;
    /* border-radius: 10px 10px 10px 10px; */
    padding: 8px;
}
.second-video-photo-icon-participant {
  display: grid;
    /* bottom: 27%; */
    justify-items: center;
    align-content: center;
    color: #fff;
    font-family: 'Exo 2', serif;
    width: 100%;
    height: 100%;
    /* font-size: 22px; */
    font-weight: 500;
    /* border-radius: 10px 10px 10px 10px; */
    padding: 8px;
}

.main-video-band {
  z-index: 9;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;;
  font-family: "Cairo";
  width: 100%;
  font-size: 19px;
  border-radius: 0 0 8px 8px;
  padding: 8px 16px;

  .left, .right {
    flex: 0 0 auto; /* Don't allow left and right elements to grow */
    width: auto;
    margin: auto;
  }

  .middle {
      flex: 1; /* Take up the remaining space in the middle */
  }
}

.circle-button {
    width: 50px; /* Set the width and height to create a circle */
    height: 50px;
    margin: 7px 7px;
    border: none;
    background-color: #fff; /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}
.circle-button svg {
  margin: inherit;
}
.circle-button-mobile {
    width: 40px; /* Set the width and height to create a circle */
    height: 40px;
    margin: 4px;
    padding: 8px;
    border: none;
    background-color: #fff; /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}
.circle-button img {
    margin: inherit;
}

.circle-button-message {
    width: 25px; /* Set the width and height to create a circle */
    height: 25px;
    margin: 7px 7px;
    border: none;
    background-color: #fff; /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}

.secondary-video-band {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: "Cairo";
  justify-content: center;
  margin: auto;
  font-size: 19px;
  padding: 2px 16px;
  border-radius: 0 0 8px 8px;
}

.user-video {
  border: 4px solid var(--secondary);
  height: 7.5rem;
  width: 13rem;
  margin-right: -3px;
  margin-bottom: -3px;
  margin-top: auto;
  margin-left: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.control-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-panel {
  color: var(--primary);
  font-family: "Cairo";
  font-size: 68px;
}

.time-text {
  font-family: "Digital Numbers", serif !important;
  font-weight: 600;
}

.logo {
  position: absolute;
  width: 10vw;
}

.my-video-chat-window {
  position: absolute;
  width: 100vw;
  height: 10vh;
  background: fixed no-repeat;
}


/* ------------------------ */

.messages-container {
    justify-content: flex-start;
    margin: 10px 0;
    margin-top: auto;
}
.messages-container-mobile {
    justify-content: flex-start;
    margin-top: auto;
}
.choices-container {
    justify-content: flex-start;
    margin: 10px 25px;
    padding-top: 10px;
    text-align: left;
    margin-top: auto;
}
.choices-container-mobile {
    margin: 5px 0;
    text-align: left;
    width: 100%;
}
.message-user {
    background-color: var(--primaryOrg);
    color: var(--secondary);
    margin-left: auto;
    border-radius: 20px 0 20px 20px;
    width: fit-content;
    max-width: 80%;
    height: fit-content;
    padding: 14px 17px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px #00000040;

}
.message-user-name {
    margin-left: auto;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--offline);
}
.message-guest {
    background: #D9D9D940;
    color: var(--primaryOrg);
    margin-right: auto;
    border-radius: 0 20px 20px 20px;
    width: fit-content;
    max-width: 80%;
    height: fit-content;
    padding: 14px 17px;
    text-align: left;
    box-shadow: 0px 4px 4px 0px #00000040;

}
.message-guest-name {
    margin-right: auto;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--offline);
}
.message-time {
    color: var(--offline);
    font-weight: 300;
    width: fit-content;
    height: fit-content;
    padding-bottom: 5px;
    font-size: 14px;
}

.file-tag {
    width: fit-content;
    background-color: var(--primary);
    color: #fff;
    border-radius: 15px;
    padding: 3px 8px;
    margin: 5px 7px;
    position: absolute;
    bottom: 40px;
}

.file-message {
  border-radius: 10px;
  background-color: var(--secondaryOrg);
  padding: 3px 8px;
}
.file-message:hover {
    text-decoration: underline;
    cursor: pointer;
}
.file-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.file-info {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

}

.circle-image {
  border: 4px solid #3498db; /* Add a border to the image */
    border-radius: 50%; /* Make the image a perfect circle */
    object-fit: none;
}
.circle-image-second {
  border: 4px solid #3498db; /* Add a border to the image */
    border-radius: 50%; /* Make the image a perfect circle */
    width: 9vw;
    height: 9vw;
    object-fit: cover;
}
.circle-image-second-participant {
  border: 4px solid #3498db; /* Add a border to the image */
    border-radius: 50%; /* Make the image a perfect circle */
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.agent-card {
  background: #F6F6F6;
  display: flex;
  margin: 20px 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.services-button {
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  border-radius: 10px;
  border-bottom: 4px solid var(--secondaryOrg);
  border-left: none;
  border-top: none;
  border-right: none;
  background: var(--primaryOrg);
}
.services-button-selected {
  border-bottom: 4px solid var(--primaryOrg);
  background: var(--secondaryOrg);
}
.services-button-mobile {
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  border-radius: 10px;
  border-bottom: 4px solid var(--secondaryOrg);
  border-left: none;
  border-top: none;
  border-right: none;
  background: var(--primaryOrg);
}
.leave-button {
  width: 100%; 
  padding: 12px 0; 
  border-radius: 10px; 
  background-color: var(--danger); 
  color: #fff; 
  border: none; 
  font-size: 20px;
}
.disclaimer-button {
  width: 100%; 
  padding: 12px 0;
  margin: 10px 0;
  border-radius: 10px; 
  background-color: var(--dark-gray); 
  color: #fff; 
  border: none; 
  font-size: 20px;
}

.bottom-panel {
  position: fixed;
  z-index: 999;
  display: inline-flex;
  justify-items: left;
  bottom: -400px;
  height: 200px;
  left: 0;
  width: 100%;
  background-color: #202124;
  padding: 20px 5px;
  transition: bottom 0.3s ease-in-out;
  border-radius: 10px 10px 0px 0px;
}
.bottom-panel.show {
  bottom: 0;
}
.bottom-panel img {
  margin-right: 10px;
}

.overlay {
    z-index: 990;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  .services-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 10px;
  }

  /*  */
  .dot-container {
    position: absolute;
    display: flex;
    top: 20px;
    left: 20px;
    margin: 10px 0;
}

.dot {
    width: 5px;
    height: 5px;
    background-color: #3498db;
    border-radius: 50%;
    margin: 0 2px;
}

.vibrating {
    animation: vibrate 1s infinite;
}

@keyframes vibrate {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.custom-dropdown {
  max-width: fit-content; position: relative; border: none; 
  z-index: 999;
  padding: 5px 5px;
    margin: 10px;
    border-radius: 20px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    color: #fff;
    top: -100%;
}
.custom-dropdown span {
  display: block ruby;
    width: 70px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-dropdown-mobile {
  max-width: fit-content; position: relative; border: none; 
  z-index: 999;
  padding: 5px 5px;
    margin: 10px;
    border-radius: 20px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    color: #fff;
    /* top: -100%; */
}
.custom-dropdown-mobile span {
  display: block ruby;
    width: 65vw;
    margin: 0 10px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}

.cam-options {
  background-color: #161616; 
  padding: 10px; 
  display: flex; 
  font-size: 14px;
}
.cam-options:hover {
  background-color: rgb(98, 98, 98);
}

.setting-options {
  background-color: #ffffff; 
  padding: 10px; 
  display: flex; 
  font-size: 14px;
  white-space: nowrap;
}
.setting-options:hover {
  background-color: rgb(226, 226, 226);
}

.reconnecting {
  position: absolute;
  position: absolute; 
  top: -40px; 
  right: 50%; 
  transform: translate(50%,-50%); 
  border-radius: 10px; 
  padding: 4px 9px; 
  background: rgb(67, 66, 66); 
  color: white;
  transition: all 0.5s linear;
}

.reconnecting-transition {
  position: absolute;
  position: absolute; 
  top: 30px; 
  right: 50%; 
  transform: translate(50%,-50%); 
  border-radius: 10px; 
  padding: 4px 9px; 
  background: rgb(67, 66, 66); 
  color: white;
  transition: all 0.3s linear;
}

.pending-dot-red{
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: red;
    margin: auto;
    margin: 7px;
  }

.end-messages {
  font-size: 13px;
    padding: 3px 8px;
    background-color: #969a9d;
    color: #fff;
    width: fit-content;
    margin: auto;
    margin-bottom: 15px;
    border-radius: 10px;
}

.footer-mobile-img img {
    padding: 5px 0 ;
}
</style>
  