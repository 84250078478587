<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L22 22" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
<path d="M14 6.25C13.5858 6.25 13.25 6.58579 13.25 7C13.25 7.41421 13.5858 7.75 14 7.75V6.25ZM7.75 7C7.75 6.58579 7.41421 6.25 7 6.25C6.58579 6.25 6.25 6.58579 6.25 7H7.75ZM15.4507 15.5999C15.7819 15.3511 15.8486 14.8809 15.5999 14.5497C15.3511 14.2186 14.8809 14.1518 14.5497 14.4006L15.4507 15.5999ZM15.787 12.9314C15.5984 13.3002 15.7446 13.752 16.1134 13.9406C16.4822 14.1291 16.934 13.983 17.1226 13.6142L15.787 12.9314ZM7.02522 4.11494C6.81713 4.47309 6.93877 4.93212 7.29692 5.14021C7.65507 5.34831 8.1141 5.22666 8.32219 4.86851L7.02522 4.11494ZM14.182 10.25C13.7678 10.25 13.432 10.5858 13.432 11C13.432 11.4142 13.7678 11.75 14.182 11.75V10.25ZM17 6.25H14V7.75H17V6.25ZM17.75 11V7H16.25V11H17.75ZM7.75 11V7H6.25V11H7.75ZM17.75 7C17.75 3.82436 15.1756 1.25 12 1.25V2.75C14.3472 2.75 16.25 4.65279 16.25 7H17.75ZM6.25 11C6.25 14.1756 8.82436 16.75 12 16.75V15.25C9.65279 15.25 7.75 13.3472 7.75 11H6.25ZM14.5497 14.4006C13.8395 14.9341 12.9577 15.25 12 15.25V16.75C13.2937 16.75 14.4896 16.3219 15.4507 15.5999L14.5497 14.4006ZM16.25 11C16.25 11.697 16.0828 12.3527 15.787 12.9314L17.1226 13.6142C17.5239 12.8291 17.75 11.9398 17.75 11H16.25ZM8.32219 4.86851C9.05917 3.60011 10.4307 2.75 12 2.75V1.25C9.87468 1.25 8.01963 2.40347 7.02522 4.11494L8.32219 4.86851ZM17 10.25H14.182V11.75H17V10.25Z" :fill="color"/>
<path d="M4 11C4 15.4183 7.58172 19 12 19M12 19C13.9545 19 15.7454 18.2991 17.1348 17.1348M12 19V22M20 11C20 12.6514 19.4996 14.1859 18.6422 15.4603M12 22H15M12 22H9" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
</svg>
</template>

<script>

export default {
  name: 'micOff',
  props: {
    size: Number,
    color: String
  }
}
</script>