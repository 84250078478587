<template>
    <div class="container-messages" :style="{'height': height,'width': width, 'background':background}">
        <div class="messages-container">
            <div v-for="(item, index) in messages" :key="index" style="margin-bottom: 10px;">
                <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user-name' : 'message-guest-name'">{{ item.sender_name }}</div>
        <div :class="item.sender_name == (localUser.fname+' '+localUser.lname) ? 'message-user' : 'message-guest'">{{ item.message }}</div>
    </div>
    </div>
    <div class="send-message">
        <div>
        <div :style="file ? 'display: grid;' : ''">
            <div class="file-tag" v-if="file">{{fileName}} <button @click="deleteFile" style="background: none; border: none;"><img class="icon-attachement" :src="getIconDelete()"/></button></div>
            <div style="display: flex; width: 100%; height: 6vh; margin: auto 10px;">
                <input v-model="message" @keyup.enter="sendMeetMessage" class="message-input" type="text" placeholder="Send a message ...">
                <input type="file" ref="fileInput" id="fileMessage" @change="getFile" style="display: none;">
                <button @click="openFileInput" type="file" class="send-file" ><img v-if="icon" class="icon-attachement" :src="getIconAtt()"/></button>
                <button @click="sendMeetMessage" type="submit" class="send-button" :style="message.length > 0 || file ? '' : 'opacity: 0.3;'"><img v-if="icon" class="icon-style" :src="getIcon()"/></button>
            </div>
        </div>
    </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'LbrxMeetChat',
    data() {
    return {
      message: "",
      iconAttachemnt: 'attachement',
      file: null,
      fileName: ""
    };
  },
    props: {
        fontSize:{
            required: true,
            value: String
        },
        height:{
            required: true,
            value: String
        },
        width:{
            required: true,
            value: String
        },
        background:{
            required: true,
            value: String
        },
        color:{
            required: true,
            value: String
        },
        label:{
            required: false,
            value: String
        },
        icon:{
            required: false,
            value: String
        },
        showMessageModel:Boolean,
        messages: Array,
        localUser: Object,
        isMember: Boolean
    },
    emits: ['clicked'],
    methods: {
        getIcon() {
            if(this.icon) {
                var images = require.context('../assets/')
                return images('./icons/' + this.icon + ".svg")
            }
        },
        getIconAtt() {
            if(this.icon) {
                var images = require.context('../assets/')
                return images('./icons/attachement' + ".svg")
            }
        },
        getIconDelete() {
            if(this.icon) {
                var images = require.context('../assets/')
                return images('./icons/delete-button-icon' + ".svg")
            }
        },
        sendMeetMessage() {
            console.log('kkkkkk')
            if(!this.file) {
                console.log('xxxxxx')
                if(this.message.length > 0) {
                    this.$emit('sendMeetMessage', this.message)
                }
            } else {
                console.log('mmmm')
                this.sendMeetAttachement();
            }
        },
        sendMeetAttachement() {
                this.$emit('sendMeetAttachement', this.file, this.message)
        },
        clearMessage() {
            this.message = "";
        },
        openFileInput() {
        // Trigger the click event on the hidden file input
        this.$refs.fileInput.click();
        },
        getFile() {
            if (
                document.getElementById("fileMessage") &&
                document.getElementById("fileMessage").files[0]
            ) {
                let rawImg;
                const file = document.getElementById("fileMessage").files[0];
                const reader = new FileReader();
                this.fileName = file.name;

                reader.onloadend = () => {
                rawImg = reader.result;
                this.file = rawImg;
                };
                reader.readAsDataURL(file);
            }
            setTimeout(() => {
                console.log("this.file", this.fileName)
            }, 1000);
            
        },
        deleteFile() {
            const fileInput = document.getElementById("fileMessage");
            fileInput.value = ''; // Reset the file input
            this.file = null;
            this.fileName = "";
        }
    }
}
</script>

<style scoped>
@media (max-width: 1200px) {
   .sub-label{
       display: none;
   }
    .icon-style{
        height: 3vmin !important;
        margin-top: 0.4vmin !important;
    }
    .icon-attachement{
        height: 3vmin !important;
        margin-top: 0.4vmin !important;
    }
    .status {
        margin-top: 1.7vmin !important;
    }
}

.container-messages {
    position: fixed;
    right: 0;
    margin: 2vh 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    transition: transform 0.3s;
    overflow-y: auto;
}
.container-messages-hide {

    right: 0;
    margin: 2vh 1.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    transition: transform 0.3s;
}
.messages-container {
    justify-content: flex-start;
    margin: 10px;
    margin-top: auto;
}
.message-user {
    background-color: var(--primary);
    color: var(--secondary);
    margin-left: auto;
    border-radius: 15px;
    width: 80%;
    height: fit-content;
    padding: 5px 10px;
    padding: 5px 10px;
}
.message-user-name {
    color: var(--primary);
    margin-left: auto;
    width: fit-content;
    border-radius: 15px;
    height: fit-content;
    padding: 0 10px;
}
.message-guest {
    background-color: var(--grey-light);
    color: var(--secondary-dark);
    margin-right: auto;
    border-radius: 15px;
    width: 80%;
    height: fit-content;
    padding: 5px 10px;
}
.message-guest-name {
    color: var(--grey-light);
    margin-right: auto;
    border-radius: 15px;
    width: fit-content;
    height: fit-content;
    padding: 0 10px;
}
.send-message {
    background-color: #474747;
    height: 65px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    display: flex;
}
.message-input {
    background-color: #fff;
    width: 100%;
    border: none;
    outline: none;
}
.send-button {
    background-color: var(--primary);
    border: none;
}
.send-file {
    background-color: #fff;
    border: none;
}
.file-tag {
    width: fit-content;
    background-color: var(--primary);
    color: #fff;
    border-radius: 15px;
    padding: 3px 8px;
    margin: 5px 10px;
}
</style>