import { OpenVidu } from "openvidu-browser";
import store from '@/store';

let OV: any;
let session: any;
let subscriber: any;
let publisher: any;
let camOpen: any;
let micOpen: any;
let selectedCamId: any;
let selectedAudioId: any;
let selectedAudioOutputId: any;

const OPENVIDU_SERVER_URL = process.env.VUE_APP_API_OPENVIDU;
const OPENVIDU_SERVER_SECRET = process.env.VUE_APP_PWD_OPENVIDU;

async function getDeviceInuse() {
  camOpen = JSON.parse(localStorage.getItem("cam") || 'false');
  micOpen = JSON.parse(localStorage.getItem("mic") || 'false');

  const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        const speakerDevices = devices.filter((device) => device.kind === 'audiooutput');
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
          
  
  if(localStorage.getItem("devicesInUse")) {
    selectedCamId = JSON.parse(localStorage.getItem("devicesInUse") || 'null').selectedCamId;
    selectedAudioId = JSON.parse(localStorage.getItem("devicesInUse") || 'null').selectedAudioId;
    selectedAudioOutputId = JSON.parse(localStorage.getItem("devicesInUse") || 'null').selectedAudioOutputId;
  } else {
    selectedCamId = undefined;
    selectedAudioId = undefined;
    selectedAudioOutputId = undefined;
  }

  // remove ducplicates
  const cameras = videoDevices.reverse().filter((obj, index) => {
    return index === videoDevices.findIndex(o => obj.groupId === o.groupId);
  });
  const speakers = speakerDevices.reverse().filter((obj, index) => {
    return index === speakerDevices.findIndex(o => obj.groupId === o.groupId);
  });
  const audios = audioDevices.reverse().filter((obj, index) => {
    return index === audioDevices.findIndex(o => obj.groupId === o.groupId);
  });
 
  if(cameras.length != 0) {
    const camExists = cameras.find((element) => element.deviceId == selectedCamId);
    if(camExists == undefined) {
      selectedCamId = undefined;
    }
  }
  if(audios.length != 0) {
    const audioExists = audios.find((element) => element.deviceId == selectedAudioId);
    if(audioExists == undefined) {
      selectedAudioId = undefined;
    }
  }

  if(speakers.length != 0) {
    const speakerExists = speakers.find((element) => element.deviceId == selectedAudioOutputId);
    if(speakerExists == undefined) {
      selectedAudioOutputId = undefined;
    }
  }
  
  console.log('azertyuio', selectedCamId, selectedAudioId, camOpen, micOpen);
}

export function joinSession(publisherElement: any, token: string) {
  getDeviceInuse();
  return new Promise(function (resolve, reject) {

    OV = new OpenVidu();
    session = OV.initSession();
  
    session.on("streamCreated", function(event: any) {
      subscriber = session.subscribe(event.stream, "primaryVideo");
      store.commit('set_subscriberVideo', event.stream.videoActive);
      store.commit('set_subscriberAudio', event.stream.audioActive);
      store.commit('set_participantJoined', true);

      subscriber.on('videoElementCreated', (event: any) => {
        const videoElement = event.element;
        // Apply custom styles to the video element<
        videoElement.id = 'participant-user';
        videoElement.style.width = '100%';
        videoElement.style.height = '100%';
        videoElement.style.objectFit = "cover";
        videoElement.style.borderRadius = "10px";
        // Add any other custom styles you want
      });
    });
  
      session
        .connect(token)
        .then(() => {
          publisher = OV.initPublisher("secondVideo", { videoSource: selectedCamId, audioSource: selectedAudioId, publishAudio: micOpen, publishVideo: camOpen, insertMode: 'replace' });
          publisher.on('videoElementCreated', (event: any) => {
            store.commit('set_sessionDisconnected', false);
            store.commit('set_reconnecting', false);
            const videoElement = event.element;
            // Apply custom styles to the video element<
            videoElement.id = 'local-user';
            videoElement.style.width = '100%';
            videoElement.style.height = '100%';
            videoElement.style.objectFit = "cover";
            videoElement.style.borderRadius = "10px";
            // Add any other custom styles you want
          });
          session.publish(publisher);
          resolve(session);
        })
        .catch((error: any) => {
          reject(error);
        });

        session.on("streamPropertyChanged", function(event: any) {
          console.log('vvvvvvvvvvvvvvv : ', event.stream.audioActive)
          if(event.stream.streamId != publisher.stream.streamId) {
            store.commit('set_subscriberVideo', event.stream.videoActive)
            store.commit('set_subscriberAudio', event.stream.audioActive)
            console.log('dddddddddddd : ', event.stream.audioActive)
          }
        });

        session.on("recordingStarted", function(event: any) {
          // if(event.stream.streamId != publisher.stream.streamId) {
            store.commit('set_recordingNow', true)
          // }
        });

        session.on("recordingStopped", function(event: any) {
          // if(event.stream.streamId != publisher.stream.streamId) {
            store.commit('set_recordingNow', false)
          // }
        });

        session.on("streamDestroyed", function(event: any) {
          // if(event.stream.streamId != publisher.stream.streamId) {
            store.commit('set_participantJoined', false);
          // }
        });

        session.on('reconnecting', () => {
          store.commit('set_reconnecting', true);
        });

        session.on('reconnected', () => {
          store.commit('set_reconnecting', false);
        });
        session.on('sessionDisconnected', (event: any) => {
            if (event.reason === 'networkDisconnect') {
                store.commit('set_sessionDisconnected', true);
            } else {
                // Disconnected from the session for other reason than a network drop
            }
        });
  })
}

export function toggleAudio(audioEnabled: boolean) {
  
  localStorage.setItem("mic", String(audioEnabled));
  publisher.publishAudio(audioEnabled);
}

export function toggleVideo(videoEnabled: boolean) {
  localStorage.setItem("cam", String(videoEnabled));
  publisher.publishVideo(videoEnabled, true);
}

export function leaveSession() {
  try {
    session.disconnect(); 
  } catch (error) {
    console.log(error);
  }
}

window.onbeforeunload = function() {
  if (session) session.disconnect();
};

/**
 * --------------------------
 * SERVER-SIDE RESPONSIBILITY
 * --------------------------
 * These methods retrieve the mandatory user token from OpenVidu Server.
 * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
 * the API REST, openvidu-java-client or openvidu-node-client):
 *   1) Initialize a session in OpenVidu Server	(POST /api/sessions)
 *   2) Generate a token in OpenVidu Server		(POST /api/tokens)
 *   3) The token must be consumed in Sessi1on.connect() method
 */

async function getToken(mySessionId: string) {
  const session = await createSession(mySessionId);
  const token = await createToken(session);
  console.log('token', token);
  return token.token;
}

async function createSession(sessionId: string) {
  // See https://openvidu.io/docs/reference-docs/REST-API/#post-apisessions
    return fetch(OPENVIDU_SERVER_URL + "/api/sessions", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ customSessionId: sessionId })
    })
      .then(async response => {
        if (response.status === 200) {
          const session = await response.json();
          return session.id;
        } else if (response.status === 409) {
          return sessionId;
        } else {
          confirm();
        }
      })
      .catch(error => {
        throw error;
      });
}

async function createToken(sessionId: string) {
    getDeviceInuse();
    // See https://openvidu.io/docs/reference-docs/REST-API/#post-apisessions
    return fetch(OPENVIDU_SERVER_URL + "/api/sessions", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ customSessionId: sessionId })
    })
      .then(async response => {
        if (response.status === 200) {
          const session = await response.json();
          return session.id;
        } else if (response.status === 409) {
          return sessionId;
        } else {
          confirm();
        }
      })
      .catch(error => {
        throw error;
      });
}

function confirm() {
  console.warn(
    "No connection to OpenVidu Server. This may be a certificate error at " +
      OPENVIDU_SERVER_URL
  );
  if (
    window.confirm(
      'No connection to OpenVidu Server. This may be a certificate error at "' +
        OPENVIDU_SERVER_URL +
        '"\n\nClick OK to navigate and accept it. ' +
        'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
        OPENVIDU_SERVER_URL +
        '"'
    )
  ) {
    location.assign(OPENVIDU_SERVER_URL + "/accept-certificate");
  }
}


export function toggleCamera(camDeviceId: string, micDeviceId: string) {

          getDeviceInuse();
          const newPublisher = OV.initPublisher('secondVideo', {
              audioSource: micDeviceId,
              videoSource: camDeviceId,
              publishAudio: micOpen, 
              publishVideo: camOpen,
              mirror: true // Setting mirror enable if front camera is selected
          });
          newPublisher.on('videoElementCreated', (event: any) => {
            const videoElement = event.element;
            // Apply custom styles to the video element<
            videoElement.id = 'local-user';
            videoElement.style.width = '100%';
            videoElement.style.objectFit = "cover";
            videoElement.style.borderRadius = "10px";
            // Add any other custom styles you want
          });
          try {
            return new Promise((resolve) => {
            // Unpublishing the old publisher
            session.unpublish(publisher).then(() => {
                console.log('Old publisher unpublished!');

                // Assigning the new publisher to our global variable 'publisher'
                publisher = newPublisher;

                // Publishing the new publisher
                session.publish(publisher).then(() => {
                    console.log('New publisher published!');
                    resolve('resolved');
                });
            });
            });
          } catch (error) {
            console.log(error);
          }
      
}
