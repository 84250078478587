<template>
<div class="button-container">
    <div :class="{'right-class': $i18n.locale === 'ar'}" class="form-check checkbox-container">
        <input id="lbxCheckbox" class="input-style" :style="{'margin-right': $i18n.locale !== 'ar' ? '4vmin !important': ''}" type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)"/>
        <div style="display: flex">
        <label :style="{'margin-right': $i18n.locale === 'ar' ? '2vmin !important': ''}" class="form-check-label" for="lbxCheckbox">
            {{label}}
        </label>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'LbrxCheckbox',
    props: {
      label:{
          required: true,
          value: String
      },
      modelValue:Boolean,
    },
    emits: ['update:modelValue'],
}
</script>

<style scoped>

@media (max-width: 822px) {
    .form-check-label {

        font-size: 19px !important;
        line-height: 19px!important;
    }
}

.form-check-label{
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Exo 2',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height */
    /* Medium Gray */

    color: #8A8A8A;
}
.checkbox-container{
    display: flex;
    padding-left: 0 !important;
    margin-top: 2vh;
    margin-bottom: auto;
}
input[type=checkbox]
{
    cursor: pointer;
    -webkit-appearance: none;
    width: 4.3vh;
    height: 4.3vh;
    border: 0.1vmin solid lightgray;
}
input[type=checkbox]:checked {
    background-color: #008FCA;
}
.button-container {
    width: 100% !important;
    text-align: left;
    height: 10vh;
    margin-top: -2.7vh;
    display: flex;
}
.input-style{
    border: 1px solid #E4E4E4;
}
</style>