<template>
    <div class="feedback-box">
        <div class="feedback-body">
            <div><img :src="business.photo" alt="" :class="isMobileView ? 'logo-mobile' : 'logo'"></div>
            <div v-if="!rated">
                <div class="feedback-text">
                    <span style="font-size: 17px;">Thank you for choosing <span style="font-weight: 700;">{{ business.name }}</span></span><br>
                    <span>We hope you had an excellent experience during your call with <span style="font-weight: 700;">{{ member.fname+' '+member.lname }}</span></span>
                    <span>As part of our commitment to optimise and enrich our service quality, we invite you to
                        fill the following satisfaction form.</span>
                </div>
                <div v-if="ratingConfig != 'emoji'" style="display: flex; justify-content: center;">
                    <div v-for="(item, index) in ratingEmoji" :key="index" class="feedback-emoji" @click="rating = item.rating" :style="`background-color: ${item.colorOnHover};`" @mouseover="item.colorOnHover = item.color"
                        @mouseout="itemSelected != index ? item.colorOnHover = '' : ''">
                        <div @click="selectEmoji(item.rating, index)">
                            <img :src="getIcon(item.name)" alt="">
                            <span :style="`color: ${item.colorText}; text-wrap: nowrap;`">{{ item.title }}</span>
                        </div>
                    </div>
                </div>
                <div v-else :style="isMobileView ? 'display: flex; justify-content: center; margin: 0px 10vw;' : 'display: flex; justify-content: center; margin: 0 80px;'">
                    <div v-for="(item, index) in ratingLimit" :key="index" class="feedback-stars" @click="rating = item">
                        <iconStar v-if="item <= rating" :color="'#FFCE00'" :size="isMobileView ? 30 : 74"/>
                        <iconStarEmpty v-else :color="'#E3E3E3'" :size="isMobileView ? 30 : 74"/>
                    </div>
                </div>
                <div>
                    <textarea id="feedback-textarea" rows="3" placeholder="Do you have anything to say?" v-model="ratingNote"></textarea>
                </div>
                <div class="feedback-buttons">
                    <button class="feedback-button-cancel" @click="leaveCall">Remind me later</button>
                    <button class="feedback-button-submit" @click="sendFeedback">Submit</button>
                </div>
            </div>
            <div v-else :style="isMobileView ? 'width: 75vw;' : 'width: 30vw;'">
                <div class="feedback-text" style="text-align: center;margin-top: 10px;">
                    <span style="font-size: 16px; color: var(--secondaryOrg); font-weight: 700; margin-bottom: 7px;">Thank you for sharing your thoughts!</span>
                    <span>Your feedback is invaluable to us. We're committed to providing you with the best experience possible, and your input helps us to continually improve our services.</span>
                </div>
                <div class="feedback-buttons" style="justify-content: center;">
                    <button class="feedback-button-home" @click="leaveCall">Go Home</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iconStar from "./icons/star.vue";
import iconStarEmpty from "./icons/star-empty.vue";
export default {
    name: 'LbrxFeedback',
    data() {
        return {
        rating: 1,
        ratingNote: '',
        ratingLimit: 5,
        ratingEmoji: [
            {name: 'meet-emoji-very-bad', title: 'very bad', rating: 1, color: 'rgba(255, 87, 87, 0.15)', colorOnHover: '', colorText: '#FF5757'},
            {name: 'meet-emoji-poor', title: 'poor', rating: 2, color: 'rgba(243, 152, 0, 0.15)', colorOnHover: '', colorText: '#F39800'},
            {name: 'meet-emoji-medium', title: 'medium', rating: 3, color: 'rgba(255, 206, 0, 0.15)', colorOnHover: '', colorText: '#FFCE00'},
            {name: 'meet-emoji-good', title: 'good', rating: 4, color: 'rgba(75, 176, 218, 0.15)', colorOnHover: '', colorText: '#4BB0DA'},
            {name: 'meet-emoji-excellent', title: 'excellent', rating: 5, color: 'rgba(0, 204, 106, 0.15)', colorOnHover: '', colorText: '#00CC6A'}
        ],
        currentColor: '',
        itemSelected: null
        };
    },
    components: {
        iconStar,
        iconStarEmpty
    },
    props: {
        business: Object,
        member: Object,
        ratingConfig: String,
        rated: Boolean,
        isMobileView: Boolean
    },
    methods: {
        selectEmoji(rating, index) {
            for (let i = 0; i < this.ratingEmoji.length; i++) {
                if(i != index) {
                    this.ratingEmoji[i].colorOnHover = '';
                }
            }
            this.itemSelected = index;
            this.rating = rating
        },
        getIcon(icon) {
          var images = require.context('../assets/')
          return images('./icons/' + icon + ".svg")
        },
        changeColor(color) {
            this.currentColor = color;
        },
        sendFeedback() {
            this.$emit('sendFeedback', this.rating, this.ratingNote);
        },
        leaveCall() {
            this.$emit('leaveCall');
        },
    }
}
</script>

<style scoped>
.feedback-box {
    background-color: #fff;
    width: fit-content;
    border-radius: 10px;
}

.feedback-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    display: grid;
}

.logo {
  width: 6vw;
  margin-top: 20px;
  margin-bottom: 25px;
}
.logo-mobile {
  width: 40px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.feedback-body {
    display: grid;
    height: 100%;
    width: min-content;
    padding: 3vh 3vw;
    overflow: auto;
}
.feedback-body textarea {
    width: 100%;
    border-radius: 10px;
    padding: 12px 17px;
    font-size: 13px;
    margin: 10px 0;
    background: linear-gradient(0deg, #F8F8F8, #F8F8F8);
    border: 1px solid #d1d1d1;
    resize: none;
    outline: none;
}

.feedback-buttons {
    display: flex;
    justify-content: space-between;
}
.feedback-button-submit {
    padding: 7px 55px;
    background: var(--secondaryOrg);
    border-radius: 10px;
    color: #fff;
    border: none;
}
.feedback-button-cancel {
    background: none;
    font-size: 15px;
    color: var(--secondaryOrg);
    border: none;
}
.feedback-button-home {
    background: #fff;
    padding: 7px 55px;
    font-size: 15px;
    color: var(--secondaryOrg);
    border: 1px solid var(--secondaryOrg);
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.feedback-stars {
    margin: 25px 5px; 
}
.feedback-emoji {
    margin: 15px 10px; 
    display: grid; 
    padding: 5px 15px;
    border-radius: 10px;
}
</style>