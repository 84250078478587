<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.3333 16.0002C29.3333 8.63637 23.3638 2.66683 16 2.66683C8.63616 2.66683 2.66663 8.63637 2.66663 16.0002C2.66663 23.364 8.63616 29.3335 16 29.3335C23.3638 29.3335 29.3333 23.364 29.3333 16.0002Z" :stroke="color" stroke-width="1.5"/>
<path d="M16.3228 22.6665V15.9998C16.3228 15.3713 16.3228 15.057 16.1276 14.8618C15.9323 14.6665 15.618 14.6665 14.9895 14.6665" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.9892 10.6665H16.0012" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>