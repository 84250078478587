<template>
  <div class="loading-wrapper" v-if="isActive">
      <div :class="'loading-popup popup-' + type">
          <div class="h-100 mt-1 ml-5 mr-5 mb-1" style="padding: 10px 15px; display: flex; width: fit-content;">
            <iconClose @click="close" :color="'black'" :size="isMobileView ? 14 : 26" style="margin: 15px 15px; position: absolute; top: 0; right: 0; cursor: pointer;"/>

              <div style="align-self: center; text-align: left;">
                <img src="../assets/icons/meet-permissions.svg" width="300" alt="">
              </div>
              <div style="display: grid; margin: 50px 15px; max-width: 300px; text-align: left; align-items: center;">
                <span style="font-size: 18px; font-weight: 700;">Liberrex Meet is blocked from using your {{ device }}</span>
                <span>1. Click the <img src="../assets/icons/meet-lock.svg" width="20" alt=""> lock icon in your browser's address bar</span>
                <span>2. Turn on {{ device }}</span>
                
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import iconClose from "./icons/close.vue";
  export default {
      name: 'LbrxPopupPermssion',
      props: {
          active: Boolean,
          hint: String,
          type: String,
          isMobileView: String,
          device: String
      },
      components: {
        iconClose
      },
      methods: {
          close(){
              this.$emit('close');
          }
      },
      computed: {
          isActive(){
              return this.active;
          }
      }
  }
</script>

<style>
  .loading-wrapper{
      background: rgba(0, 0, 0, 0.70);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999999999999999999999999998;
  }

  .loading-popup{
      /* width: 90%; */
      min-width: 320px;
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #264076;
      z-index: 9999999999999999999999999999999;
  }

  .loader{
      width: 50px;
      height: 50px;
  }

  .popup-request .popup-title {
    color: var(--Medium-Gray, #8A8A8A);
    font-size: 24px;
    font-weight: 300;
    margin: 0 15px;
  }

  .popup-request  .icon {
      width: 50px;
      margin: 10px 10px;
  }

  .popup-request  .message {
    color: var(--Dark-Gray, #444);
    font-size: 20px;
    font-weight: 200;
    line-height: 25px;
    text-align: left;
  }

  .popup-request  .hint {
      color: #333;
      font-weight: bold;
      font-size: 16px;
  }

  .popup-request .btn-confirm {
    color: var(--primary);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    border: none;
    background-color: #fff;
    padding: 0;
  }

  .popup-request .btn-cancel {
    color: var(--offline);
    font-size: 24px;
    font-weight: 300;
    border: none;
    background-color: #fff;
    padding: 0;
  }

  .popup-request .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
      color: #fff;
      background-color: #72b53a;
      border-color: #72b53a;
  }

  .popup-request .btn-confirm:focus, .btn-confirm.focus {
      box-shadow: none;
  }

  .popup-danger .popup-title {
      color: #DC3545 !important;
      margin-bottom: 0px;
      font-size: 20px !important;
      display: flex;
  }

  .popup-danger  .icon {
      width: 50px;
      margin: 10px 10px;
  }

  .popup-danger  .message {
      color: #444;
      font-weight: lighter;
      font-size: 18px;
  }

  .popup-danger  .hint {
      color: #333;
      font-weight: bold;
      font-size: 16px;
  }

  .popup-danger .btn-confirm {
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0px 0px 20px 20px;
      padding: 15px;
      width: 100%;
      border: none;
      background: #DC3545;
      color: #fff;
      font-weight: 400;
      font-size: 22px;
  }

  .popup-danger .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
      color: #fff;
      background-color: #DC3545;
      border-color: #DC3545;
  }

  .popup-danger .btn-confirm:focus, .btn-confirm.focus {
      box-shadow: none;
  }

  .circle-button-confirm {
    width: 50px; /* Set the width and height to create a circle */
    height: 50px;
    margin: 7px 7px;
    border: none;
    background-color: var(--success); /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}
  .circle-button-close {
    width: 50px; /* Set the width and height to create a circle */
    height: 50px;
    margin: 7px 7px;
    border: none;
    background-color: var(--danger); /* Button background color */
    border-radius: 50%; /* Make the button a perfect circle */
    cursor: pointer;
}

</style>