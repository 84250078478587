import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

import {createApp} from 'vue'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import App from './App.vue'
import router from './router'
import {ApiConfigs, authHeader} from './_helpers';
import Vue3TouchEvents from "vue3-touch-events";
// Vuex Store
import store from "./store";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

//Bootstrap
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-icons/font/bootstrap-icons';
// import 'bootstrap-icons/font/bootstrap-icons.css'

// import i18n from './i18n/i18n.js'
if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'en')
}

import Echo from 'laravel-echo';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Pusher from 'pusher-js';
import {createI18n} from "vue-i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fr from "./locales/fr.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from "./locales/en.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ar from "./locales/ar.json";

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: `${ApiConfigs.broadcast_url}${ApiConfigs.pusher.auth}`,
    auth: {
        headers: {...authHeader()}
    },
});

const bodyStyles = document.body.style;

bodyStyles.setProperty('--primary', "#008FCA");
bodyStyles.setProperty('--primaryOrg', "#ff7900");
bodyStyles.setProperty('--secondaryOrg', "#008fc9");
bodyStyles.setProperty('--secondary', "#E3E3E3");
bodyStyles.setProperty('--secondary-dark', "#333333");
bodyStyles.setProperty('--secondary-grey', "#4F4F4F");
bodyStyles.setProperty('--dark-gray', "#444444");
bodyStyles.setProperty('--grey-light', "#FDFDF2");
bodyStyles.setProperty('--online', "#84BC26");
bodyStyles.setProperty('--offline', "#8A8A8A");
bodyStyles.setProperty('--success', "#00CC6A");
bodyStyles.setProperty('--danger', "#FF5757");


const i18n = createI18n({
    locale: localStorage.getItem('Language') || 'fr',
    fallbackLocale: "fr",
    messages: {fr, en, ar},
});



app.provide('echo', echo)

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

app.component('VueDatePicker', VueDatePicker);
app.provide('direction', localStorage.getItem('Language') === 'ar' ? 'rtl' : 'ltr')
app.provide('text_direction', localStorage.getItem('Language') === 'ar' ? 'right' : 'left')
app.use(store).use(router).use(Vue3TouchEvents).use(i18n).mount('#app')
