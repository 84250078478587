<template>
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.992 12H12.001" :stroke="color" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9842 18H11.9932" :stroke="color" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9998 6H12.0088" :stroke="color" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>