<template>

  <table v-if="stat" class="table-auto">
    <tr>
      <td class="td-title">
        <div class="user-sub-info text-white"> {{ $t('Teller.freq_visit') }}:</div>
      </td>
      <td class="td-item info-title">
        <div
            class="text-info">{{ stat.visit_freq !== '--' ? Math.round(stat.visit_freq) : '--' }} / {{
            $t('Teller.week')
          }}
        </div>
      </td>
    </tr>
    <tr>
      <td class="td-title">
        <div class="user-sub-info text-white"> {{ $t('Teller.satisfaction') }}:</div>
      </td>
      <td class="td-item info-title">
        <div style="display: flex" class="text-info">
          <div style="margin: auto" v-for="index in 5" :key="index">
            <img style="width: 60%;height: 60%" v-if="index<=stat.satisfaction?stat.satisfaction:0"
                 src="./../assets/icons/star-yellow.svg">
            <img style="width: 80%;height: 80%" v-else src="./../assets/icons/star-grey.svg">
          </div>
          <div class="text-info">
            ({{ stat.satisfaction ? stat.satisfaction : 0 }} {{ $t('Teller.rate') }})
          </div>
        </div>

      </td>
    </tr>
    <tr>
      <td class="td-title">
        <div class="user-sub-info text-white"> {{ $t('Teller.ser_consumed') }}:</div>
      </td>
      <td class="td-item info-title">
        <div
            class="text-info">{{
            stat.consumed_service.service ? stat.consumed_service.service.title : '--'
          }} ({{ stat.consumed_service.percentage }}%)
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  name: 'LbrxStatisticsTable',
  props: {
    stat:{required:true,type:Object},
  }
}
</script>

<style scoped>

@media (max-width: 680px) {
  .ticket-class {
    height: 55vh !important;
  }

  .no-data-container {
    height: 100%;
    margin-top: -10vmin !important;
  }

  .statistics-image-container img {
    height: 25vmin !important;
  }

  .statistics-image-container {
    margin-top: 20vmin !important;
  }

  .no-data-label {
    margin-top: 3vmin !important;
    font-size: 5.4vmin !important;
    line-height: 6vmin;
    color: #E3E3E3;
  }

  .text-info {
    font-size: 13px !important;
  }

  .user-sub-info {
    font-size: 13px !important;
  }

  .user-email, .user-phone {
    font-size: 20px !important;
  }
}

.td-title {

  background: linear-gradient(127.19deg, #008FCA 0.56%, #4BB0DA 76.01%);
  opacity: 0.9;
}

tr {
  border: 4px solid white;
}

td {
  border: 4px solid white;
  width: fit-content;
  height: fit-content;
}

td:first-child {
  border: 4px solid white;
  width: fit-content;
}

.td-item {
  background: #F8F8F8;
  /* Primary */

  border-bottom: 1px solid #008FCA;

}

.pub-image {
  height: v-bind(getImageHeight);
}

.text-info {
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-size: 16px;
}

.user-image {
  width: 50%;
  height: 60%;
}

.user-info {
  word-break: break-all;
  margin-left: 10px;
  margin-top: -1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 24px;
  line-height: 20px;

  /* Gray 4 */

  color: #BDBDBD;
}

.user-sub-info {
  word-break: keep-all !important;
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 16px;

  /* Gray 4 */

  color: #BDBDBD;
}


.service-name {
  line-height: 3vmin !important;
  font-weight: 400;
  font-size: 22px !important;
}

.user-phone {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-email {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-name {
  font-weight: 700;
}

.image-container {
  box-sizing: border-box;
  border-radius: 80px;
  width: 70px;
  height: 70px;
  display: flex;
  padding-top: 18px;
  justify-content: center;
  margin: 0;


  border: double 4px transparent;
  background-image: linear-gradient(#e5e5e5, #e5e5e5), radial-gradient(circle at top left, #008FCA, #4BB0DA);
  background-origin: border-box;
  background-clip: padding-box, border-box;


}

.cursor:hover {
  cursor: pointer;
}

.cursor {
  width: 15px;
}

.user-class {
  display: flex;
  margin: 2vmin;
  margin-bottom: 1vmin;
}

.ticket-title {
  width: 100%;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26.5px;
  display: flex;

  /* Medium Gray */

  color: #8A8A8A;

}

.ticket-class {
  width: auto;
  padding: 20px;
  height: 100%;
  background: white;
}

.no-data-container {
  margin: 4vmin;
  min-height: 28vmin;
}

.pub-container {
  text-align: end;
}

@media (max-width: 1100px) {
  .pub-container {
    display: none !important;
  }
}

.statistics-image-container img {
  height: 16vmin;
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 9vmin;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.info-title {
  color: #008FCA !important;
}

.service-item {
  font-weight: 300;
  line-height: 30px;
  font-size: 2vmin;
  margin: unset;
  word-break: keep-all;
}

.details-container {
  overflow-y: clip;
  max-height: 37vmin;
}

hr {
  margin: 10px 0 !important;
}
</style>