export function authHeader(): { [key: string]: string } {
    const user = JSON.parse(localStorage.getItem('user') || 'null');

    if (user && user.token) {
        return {
            'Authorization': 'Bearer ' + user.token,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'fr'
        };
    } else {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'fr'
        };
    }
}

export function authHeaderOpenvidu(): { [key: string]: string } {
    return {
        Authorization: "Basic " + btoa(process.env.VUE_APP_USERNAME_OPENVIDU+":" + process.env.VUE_APP_PWD_OPENVIDU),
    };
}