<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title just">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}"
                     src="../assets/icons/calendar-grey-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('BookingDetails.title') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.Booking') }} : {{ booking.fname }} {{ booking.lname }} -
                {{ moment(booking.start_datetime).locale(this.$i18n.locale).format('dddd Do MMMM YYYY, H:mm') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>

          <div class="row">
            <div v-if="!loading" class="popup-body">

              <div class="user-name">
                {{ booking.fname }} {{ booking.lname }}
              </div>
              <div class="services">
                <span class="title">{{ $t('BookingDetails.Services') }}: </span>
                <span :id="'bookingService-'+service.id" v-for="(service,index) in booking.services_array" :key="index">{{ service.title }}<span
                    v-if="index+1 < booking.services_array.length">, </span></span>
              </div>
              <div class="services hori-container">
                <div>
                  <span class="title">{{ $t('BookingDetails.Department') }}: </span>
                  <span id="bookingDep">{{ booking.dep ? booking.dep.name : '' }}</span>
                </div>
                <div class="margin-left">
                  <span class="title">{{ $t('BookingDetails.agent') }}: </span>
                  <span id="bookingMember" v-if="booking.member">{{ booking.member.fname + ' ' + booking.member.lname }}</span>
                </div>
              </div>

              <div @click="showUserData = !showUserData" class="title-container">
                <img v-if="showUserData" class="arrow-icon" src="../assets/icons/down-arrow.svg"/>
                <img v-else class="arrow-icon" src="../assets/icons/left-arrow.svg"/>
                {{ $t('Teller.CustomerLabel') }}
              </div>
              <div v-if="showUserData" class="user-data">
                <div class="first-data justify-content-between">
                  <span><span class="title">{{ $t('BookingDetails.lname') }}: </span><span
                      class="value">{{ booking.lname }} </span></span>
                  <span><span class="title">{{ $t('BookingDetails.fname') }}: </span><span
                      class="value">{{ booking.fname }}</span></span>
                  <span><span class="title">{{ $t('BookingDetails.phone') }}: </span><span
                      class="value">{{ booking.phone_number }}</span></span>
                  <span><span class="title">{{ $t('BookingDetails.country') }}: </span><span
                      class="value">{{ booking.country }}</span></span>
                </div>
                <span class="first-data"><span class="title">{{ $t('BookingDetails.mail') }}: </span><span
                    style="margin-left: 5px"
                    class="value">{{
                    booking.email
                  }}</span></span>
              </div>
              <div @click="showNotes = !showNotes" class="title-container">
                <img v-if="showNotes" class="arrow-icon" src="../assets/icons/down-arrow.svg"/>
                <img v-else class="arrow-icon" src="../assets/icons/left-arrow.svg"/>
                {{ $t('BookingDetails.notes') }}
              </div>
              <div v-if="showNotes" class="notes">
                <div v-if="booking.notes > 1" class="vertical-line"></div>
                <div>
                  <div v-for="(note, index) in booking.notes" :key="index" class="single-note justify-content-between">
                    <div class="marker">

                    </div>
                    <div style="width: 40%">
                      <div class="note-date">{{ getStringDate(note.date) }}</div>
                      <div class="note-agent">par: {{ note }}</div>
                    </div>
                    <div class="note-data">
                      {{ note.data }}
                    </div>
                  </div>
                </div>
              </div>
              <textarea v-if="showNotes" type="text" class="note-input" :class="{'right-class': $i18n.locale === 'ar'}"
                        :placeholder="$t('BookingDetails.addNote')+'...'"></textarea>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

import moment from "moment/moment";
import {businessService} from "@/_services";

export default {
  name: 'LbrxBookingDetailsPopup',
  components: {},
  props: {
    modelValue: Boolean,
    booking: {type: Object, required: true},
    date: {type: String, required: true},
    agencyInfo: {type: Object, required: true}
  },
  data() {
    return {
      showNotes: true,
      showUserData: true,
      sending: false,
      showPopup: false,
      loading: true,
      dt: new Date(this.date),
      dep: {}
    }
  },
  computed: {
    moment() {
      return moment
    },
    verticalLineHeight() {
      if (this.booking.notes) {
        if (this.booking.notes.length < 3)
          return 4.2 * this.booking.notes.length + "vmin";
        if (this.booking.notes.length === 3)
          return 5.8 * this.booking.notes.length + "vmin";
        return 6.5 * this.booking.notes.length + "vmin";
      }
      return 6.5 + "vmin";
    },
    getDate() {
      return this.getStringDate(this.dt);

    },
    getFullDate() {
      return moment(this.dt).locale(this.$i18n.locale).format('dddd Do MMMM YYYY, H:mm')
    }
  },
  methods: {
    getStringDate(date) {
      date = new Date(date);
      const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
      return date.toLocaleDateString('en-GB', options);
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
  },
  created() {
    this.dt = new Date(this.date);
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });

    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
}
</script>

<style scoped>
.popup-box {
  width: 65.6%;
}

.full-date {
  margin-top: 2vmin;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3vmin;
  line-height: 3.7vmin;

  /* Primary */

  color: #008FCA;
}

.user-name {
  margin-top: 2vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.7vmin;

  /* Dark Gray */

  color: #444444;
}

.first-data .title {
  font-weight: 700;
}

.services .title {
  font-weight: 700;
}

.services {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.8vmin;
  margin-top: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.hori-container {
  display: flex;

  font-size: 2.5vmin;
  line-height: 2.6vmin;
}

.title-container {
  background: #F2F2F2;
  width: 100%;
  height: 8vmin;
  margin-top: 3vmin;
  padding: 2vmin;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.2vmin;
  line-height: 3.2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.title-container:hover {
  opacity: 0.7;
}

.arrow-icon {
  margin-right: 4vmin;
  height: 3vmin;
}

.first-data {
  display: flex;
  width: 90%;
  margin-left: 3vmin;
  margin-top: 2vmin;
}

.first-data .value {
  color: #8A8A8A;
}

.note-date {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2.5vmin;
  /* identical to box height */


  /* Primary */

  color: #008FCA;
}

.note-agent {
  margin-top: 0.2vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.note-data {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.1vmin;
  line-height: 2.2vmin;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Gray 3 */

  color: #828282;
}

.single-note {
  display: flex;
  min-height: 6vmin;
  max-height: 8vmin;
  margin-top: 2vmin;
  overflow: hidden;
}

.notes {
  width: 90%;
  height: auto;
  display: flex;
  margin-top: -1vmin;
  margin-left: 3vmin;
}

.marker {
  margin-top: 1vmin;
  margin-right: 2vmin;
  background: #008FCA;
  border-radius: 300px;
  height: 1.8vmin;
  width: 2.6vmin;
}

.vertical-line {
  margin-top: 3vmin;
  height: v-bind(verticalLineHeight);
  background: #F2F2F2;
  border-radius: 5px;
  margin-left: 2vmin;
  margin-right: -1.225vmin;
  width: 0.8vmin;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  /* Gray 5 */

  color: #E0E0E0;
}

.note-input {
  margin-bottom: 5vmin;
  text-align: left;
  resize: none;
  padding: 20px;
  margin-top: 4vmin;
  min-height: 10vmin;
  margin-left: 4vmin;
  width: 95%;
  background: #FFFFFF;
  /* #E3E3E3 */
  border: 1px solid #E3E3E3;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  /* Gray 5 */

  color: black;
}

.margin-left {
  margin-left: 10vmin;
}

.user-data {
  font-size: 2.3vmin;
}
</style>