<template>
  <div>
    <div class="row p-0 m-0">
      <div class="col-md-7 p-0 m-0 d-none d-md-block">
        <lbrx-welcome-interface></lbrx-welcome-interface>
      </div>
      <div class="col-md-5 col-12 p-0 m-0 justify-content-center">
        <lbrx-login-logo></lbrx-login-logo>
        <div class="login-form-container">
          <div style="width: 80%">
            <div class="form-container ">
              <div class="auth-title">
                {{ $t('maintenance.title') }}
              </div>
              <img class="maintenance-img" src="../../assets/icons/web-browser-icon.svg"/>
              <div class="auth-sub-title">
                {{ $t('maintenance.title_2') }}<br>
                {{ $t('maintenance.title_3') }}
              </div>
              <div class="auth-code auth-second-code">
                +216 36 36 43 45<br>
                support@liberrex.com
              </div>
            </div>
          </div>
        </div>
        <div class="login-footer">
          <div class="footer-container">
            <div class="copyright">
              Liberrex.com © Copyright {{ new Date().getFullYear() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LbrxWelcomeInterface from "@/components/LbrxWelcomeInterface.vue";
import LbrxLoginLogo from "@/components/LbrxLoginLogo.vue";
import router from "@/router";

export default {
  name: "MaintenancePage",
  components: {
    LbrxLoginLogo,
    LbrxWelcomeInterface
  },
  data() {
    return {}
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('user')).user.maintenance === 0) {
      router.push('/agent-dashboard');
    }
  },
  methods: {}
}
</script>

<style scoped>

.footer-container {
  position: absolute;
  bottom: 10px;
}

.login-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-form-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 15%;
}

.auth-title {

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.7vmin;
  line-height: 4vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.auth-second-code {
  font-size: 3.4vmin !important;
  line-height: 4.2vmin !important;
  margin-top: 3vmin !important;
}

.auth-code {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13vmin;
  line-height: 14vmin;
  margin-top: 1.5vmin;

  color: #8A8A8A;
}

.auth-sub-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.1vmin;
  line-height: 3.8vmin;

  /* Primary */

  color: #008FCA;
  margin-top: 3vmin;
  margin-bottom: 1.6vmin;
}

.maintenance-img {
  height: 20vmin;
  margin-top: 3vmin;
  opacity: 0.1;
}
</style>
