<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33366 8.00061C7.70614 8.00549 6.80543 8.04443 6.0653 8.35508C5.02859 8.79021 4.18434 9.59398 3.69113 10.6154C3.28857 11.4491 3.22269 12.5173 3.09094 14.6536L2.88448 18.0012C2.55684 23.3137 2.39302 25.97 3.95191 27.6516C5.51079 29.3332 8.13702 29.3332 13.3895 29.3332H18.6112C23.8636 29.3332 26.4899 29.3332 28.0487 27.6516C29.6076 25.97 29.4438 23.3137 29.1162 18.0012L28.9097 14.6536C28.778 12.5173 28.7121 11.4491 28.3095 10.6154C27.8163 9.59398 26.9721 8.79021 25.9353 8.35508C25.1952 8.04443 24.2945 8.00549 22.667 8.00061" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
<path d="M22.6668 9.33329L21.4857 6.38053C20.9761 5.10658 20.5327 3.66143 19.2224 3.01269C18.5234 2.66663 17.6823 2.66663 16.0002 2.66663C14.318 2.66663 13.477 2.66663 12.778 3.01269C11.4676 3.66143 11.0242 5.10658 10.5146 6.38053L9.3335 9.33329" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.6668 18.6667C20.6668 21.244 18.5775 23.3333 16.0002 23.3333C13.4228 23.3333 11.3335 21.244 11.3335 18.6667C11.3335 16.0893 13.4228 14 16.0002 14C18.5775 14 20.6668 16.0893 20.6668 18.6667Z" :stroke="color" stroke-width="1.5"/>
<path d="M15.9997 8H16.0117" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    size: Number,
    color: String
  },
  data() {
    return {
      showNotes: true,
      showUserData: true,
      sending: false,
      showPopup: false,
      loading: true,
      dt: new Date(this.date),
      dep: {}
    }
  }
}
</script>