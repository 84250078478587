<template>
    <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.3333 15.4224C29.3333 22.4666 23.3629 28.178 16 28.178C15.1342 28.1791 14.2709 28.0991 13.4205 27.9394C12.8084 27.8244 12.5023 27.7669 12.2887 27.7996C12.075 27.8322 11.7722 27.9932 11.1666 28.3153C9.45348 29.2263 7.4559 29.548 5.53477 29.1907C6.26495 28.2926 6.76362 27.215 6.98366 26.0598C7.117 25.3532 6.78663 24.6667 6.29181 24.1642C4.0444 21.8821 2.66663 18.8069 2.66663 15.4224C2.66663 8.37817 8.637 2.66675 16 2.66675C23.3629 2.66675 29.3333 8.37817 29.3333 15.4224Z" :stroke="color" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M15.994 16H16.0059M21.3213 16H21.3333M10.6666 16H10.6786" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>

export default {
  name: 'cameraIcon',
  props: {
    size: Number,
    color: String
  }
}
</script>