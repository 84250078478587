import { authHeader, ApiConfigs } from '../_helpers';
import store from '@/store'

export const counterService = {
    getAll,
    get,
    update,
    create,
    open,
    close,
    delete: _delete
};


function getAll(queue_id: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.getAll.replace(':queue', queue_id)}`, requestOptions).then(handleResponse);
}


function get(queue_id: string, counter_id: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.get.replace(':queue', queue_id).replace(':counter', counter_id)}`, requestOptions).then(handleResponse);
}


function update(queue_id: string, counter_id: string, counter: object) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(counter)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.update.replace(':queue', queue_id).replace(':counter', counter_id)}`, requestOptions).then(handleResponse);
}


function create(queue_id: string, counter: object) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(counter)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.create.replace(':queue', queue_id)}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(queue_id: string, counter_id: string) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters._delete.replace(':queue', queue_id).replace(':counter', counter_id)}`, requestOptions).then(handleResponse);
}


function open(queue_id: string, counter_id: string) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.open.replace(':queue', queue_id).replace(':counter', counter_id)}`, requestOptions)
        .then(handleResponse);
}


function close(queue_id: string, counter_id: string) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.counters.close.replace(':queue', queue_id).replace(':counter', counter_id)}`, requestOptions)
        .then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                store.dispatch('authentication/logout')
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}