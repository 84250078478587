<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon" :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}" src="../assets/icons/dollar-grey.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('Teller.Payment') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.Payment_subtitle') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>
        <div class="confirm-popup-body">
          <div class="my-auto">
            <div class="popup-elements">
              <div class="popup-title-text pop-up-body-title">
                {{ $t('Teller.Payment_type') }}
              </div>
              <div v-if="types" style="margin-bottom: 5vmin" class="types-list">
                      <span v-for="(type, index) in types" :key="index" class="m-1">
                      <lbrx-radio-button :id="'paymentType'+index" @select="checkType(type)" :item="type"></lbrx-radio-button>
                      </span>
              </div>

              <div class="popup-title-text pop-up-body-title">
                {{ $t('Teller.Amount') }}
                <input id="amount" :class="{'right-class': $i18n.locale === 'ar'}" v-model="amount" type="number" class="note-input"
                       :placeholder="'1.000.000 DT'">
              </div>
            </div>
          </div>
        </div>
        <div class="popup-buttons justify-content-between">
          <lbrx-button id="validatePayment" v-model="sending" icon="tick-mark-icon" :base-button="true" @clicked="triggerPopupAction()"
                       :label="$t('Teller.ConfirmButton')"
                       color="#FFFFFF" background="#00CC6A" height="9vmin" width="93%"
                       font-size="1.1"></lbrx-button>
          <lbrx-button id="cancelPayment" v-model="close" icon="delete-red-icon" :base-button="true" @clicked="closeThis()" :label="$t('Teller.Close')"
                       color="#FF5757" border="1px solid #FF5757" background="white" height="9vmin"
                       width="93%" font-size="1.1"></lbrx-button>

        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxRadioButton from "@/components/LbrxRadioButton.vue";

export default {
  name: 'LbrxPaymentPopup',
  components: {LbrxRadioButton, LbrxButton},
  props: {
    modelValue: Boolean,
    types: Array,
    ticket: Object
  },
  data() {
    return {
      sending: true,
      close: false,
      showPopup: false,
      selected_type: null,
      amount: ""
    }
  },
  watch: {
    amount: {
      handler: function (newVal) {
        if (newVal !== "" && this.selected_type !== null) {
          this.sending = false
        }
      },
      deep: true
    }
  },
  methods: {
    triggerPopupAction() {
      this.$emit('confirm-payment', {amount: this.amount, type: this.selected_type.id})
      this.closeThis()
    },
    checkType(type) {
      this.types.forEach((type) => type.selected = false);
      type.selected = true;
      this.selected_type = type
      if (this.amount !== "") {
        this.sending = false
      }

    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    setTimeout(() => {
      this.showPopup = true;
    }, 5)

    this.types.forEach((type) => type.id.toString() === this.ticket.type_payment.toString() ? type.selected = true : type.selected = false);
    this.amount = this.ticket.price
  }
}
</script>

<style scoped>
.popup-elements {
  overflow-x: hidden;
  height: 100%;
}

.popup-buttons{
  padding-right: 33px;
  padding-left: 25px;
}

.types-list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.confirm-popup-body {
  height: 100%;
  display: flex;

  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;

  color: #8A8A8A;
}

.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  /* Gray 5 */

  color: #E0E0E0;
}

.note-input {
  margin-bottom: 5vmin;
  text-align: left;
  resize: none;
  padding: 20px;
  width: 100%;
  background: #FDFDFD;
  border: 1px solid #E3E3E3;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  color: black;
}
</style>