<template>
  <div :class="{'right-class': $i18n.locale === 'ar'}" ref="customerTicketContainer" class="ticket-class">
    <div class="ticket-title justify-content-between">
      <div>{{ $t('Call.Schedule') }}</div>
      
    </div>
    <div v-if="modelValue.length != 0" class="ticket-container">
      <div class="col-sm">
        <div >
          <div v-for="(item, index) in modelValue" :key="index" style="display: flex; justify-content: space-between; margin: 15px 15px;">
            <div style="display: grid;">
              <span style="color: var(--primary); font-weight: 600;">{{ item.fname.toUpperCase() + "  " + item.lname.toUpperCase() }}</span>
              <span>{{ item.phone_number }}</span>
            </div>
            <div style="display: grid;">
              <span>{{ $t('Call.Starts') }}</span>
              <span>{{ returnDuration(item.start_datetime) }}</span>
            </div>
            <div style="display: flex;">
              <button v-if="!datePast(item.start_datetime)" @click="enterCallRoom(item.meet.room_name)" style="background-color: var(--success); border: none; border-radius: 30px; margin: 0 7px;">
                <img src="../assets/icons/call-phone.svg" alt="" style="height: 20px; margin: 3px 8px;">
              </button>
              <button v-else style="background-color: var(--secondary-grey); border: none; border-radius: 30px; margin: 0 7px; pointer-events: none;">
                <img src="../assets/icons/call-phone.svg" alt="" style="height: 20px; margin: 3px 8px;">
              </button>
              <button v-if="!datePast(item.start_datetime)" style="background-color: var(--primary); border: none; border-radius: 30px; margin: 0 7px;">
                <img src="../assets/icons/call-message.svg" alt="" style="height: 20px; margin: 3px 8px;">
              </button>
              <button v-else style="background-color: var(--secondary-grey); border: none; border-radius: 30px; margin: 0 7px; pointer-events: none;">
                <img src="../assets/icons/call-message.svg" alt="" style="height: 20px; margin: 3px 8px;">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 pub-container mx-auto my-auto d-none d-sm-block">
        <img v-if="stat.pub" :src="stat.pub" class="pub-image" alt="">
      </div>

    </div>
    <div id="noTicket" class="no-data-container" v-else>
      <div class="statistics-image-container">
        <img src="../assets/images/no-ticket-data.png"/>
      </div>
      <div class="no-data-label">{{ $t('Call.NoSchedule') }}</div>
    </div>
  </div>
</template>
<script>

import moment from "moment/moment";
export default {
  name: 'LbrxCurrentTicketInfoBox',
  components: {
  },
  data() {
    return {
      maxHeight: '350px',
    }
  },
  props: {
    modelValue: Array,
    stat: Object
  },
  methods: {
    returnDuration(datetime) {
      if (datetime) {
        var now = moment(new Date()); //todays date
        var end = moment(datetime); // another date
        var duration = moment.duration(end.diff(now));
        var duratinMin = duration.asMinutes();
        if(duratinMin >= 0) {
          return `${Math.floor(duratinMin / 60)} ${this.$t('Call.Hour')} ${Math.round(duratinMin % 60)} ${this.$t('Call.Minutes')}`;
        } else {
          return "--";
        } 
      } else {
        return "--";
      }
    },
    datePast(datetime) {
      var now = moment(new Date()); //todays date
      var end = moment(datetime); // another date
      var duration = moment.duration(end.diff(now));
      var duratinMin = duration.asMinutes();
      if(duratinMin >= 0) {
        return false;
      } else {
        return true;
      } 
    },
    enterCallRoom(room_name) {
      this.$emit("enterCallRoom", room_name)
    }
  },
  computed: {
    getImageHeight() {
      return (this.$refs.customerTicketContainer.offsetHeight / 1.4) + "px";
    }
  }
}
</script>

<style scoped>
.ticket-container{
  margin: 25px 0;
  min-height: 42vh;
}
@media (max-width: 767px) {
  .ticket-container{
    min-height: auto !important;
  }
  .statBox {
    display: none;
  }
}
@media (max-width: 680px) {
  .ticket-class {
    min-height: 39.6vh !important;
    height: fit-content !important;
  }

  .no-data-container {
    height: 100%;
    margin-top: -10vmin !important;
  }

  .statistics-image-container img {
    height: 25vmin !important;
  }

  .statistics-image-container {
    margin-top: 20vmin !important;
  }

  .no-data-label {
    margin-top: 3vmin !important;
    font-size: 5.4vmin !important;
    line-height: 6vmin;
    color: #E3E3E3;
  }

  .text-info {
    font-size: 13px !important;
  }

  .user-sub-info {
    font-size: 13px !important;
  }

  .user-email, .user-phone {
    font-size: 20px !important;
  }
}

.td-title {

  background: linear-gradient(127.19deg, #008FCA 0.56%, #4BB0DA 76.01%);
  opacity: 0.9;
}

tr {
  border: 4px solid white;
}

td {
  border: 4px solid white;
  width: fit-content;
  height: fit-content;
}

td:first-child {
  border: 4px solid white;
  width: fit-content;
}

.td-item {
  background: #F8F8F8;
  /* Primary */

  border-bottom: 1px solid #008FCA;

}

.pub-image {
  height: v-bind(getImageHeight);
}

.text-info {
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-size: 16px;
}

.user-image {
  width: 50%;
  height: 60%;
}

.user-info {
  word-break: break-all;
  margin-left: 10px;
  margin-top: -1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 24px;
  line-height: 20px;

  /* Gray 4 */

  color: #BDBDBD;
}

.user-sub-info {
  word-break: keep-all !important;
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-size: 16px;

  /* Gray 4 */

  color: #BDBDBD;
}


.service-name {
  line-height: 3vmin !important;
  font-weight: 400;
  font-size: 22px !important;
}

.user-phone {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-email {
  font-weight: 400;
  font-size: calc(0.7 * (1vh + 1vw));
}

.user-name {
  font-weight: 700;
}

.image-container {
  box-sizing: border-box;
  border-radius: 80px;
  width: 70px;
  height: 70px;
  display: flex;
  padding-top: 18px;
  justify-content: center;
  margin: 0;


  border: double 4px transparent;
  background-image: linear-gradient(#e5e5e5, #e5e5e5), radial-gradient(circle at top left, #008FCA, #4BB0DA);
  background-origin: border-box;
  background-clip: padding-box, border-box;


}

.cursor:hover {
  cursor: pointer;
}

.cursor {
  width: 15px;
}

.user-class {
  display: flex;
  margin: 2vmin;
  margin-bottom: 1vmin;
}

.ticket-title {
  width: 100%;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26.5px;
  display: flex;

  /* Medium Gray */

  color: #8A8A8A;

}

.ticket-class {
  width: auto;
  padding: 20px;
  height: 72vh;
  background: white;
}

.no-data-container {
  margin: 4vmin;
  min-height: 28vmin;
}

.pub-container {
  text-align: end;
}

@media (max-width: 1100px) {
  .pub-container {
    display: none !important;
  }
}

.statistics-image-container img {
  height: 16vmin;
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 9vmin;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.info-title {
  color: #008FCA !important;
}

.service-item {
  font-weight: 300;
  line-height: 30px;
  font-size: 2vmin;
  margin: unset;
  word-break: keep-all;
}

.details-container {
  overflow-y: clip;
  max-height: 37vmin;
}

hr {
  margin: 10px 0 !important;
}
</style>