<template>
  <div class="button-container" :style="{'height': height,'width': width}">
      <button class="lbrx-button" @click="$emit('clicked')" :style="{'background':background, 'color':color, 'font-size': 'calc('+fontSize+'*(1vh + 1vw))'}">
          <img v-if="icon" class="icon-style" :style="{'height':'calc(1.3*'+fontSize+'*(1vh + 1vw))'}" :src="getIcon()"/> <span class="sub-label">{{label}}</span>
      </button>
      <div v-if="modelValue != null" class="status" :class="{'online': modelValue}" :style="{'margin-top': 'calc(0.7*'+fontSize+'*(1vh + 1vw))', height: 'calc(0.5*'+fontSize+'*(1vh + 1vw))', width: 'calc(0.5*'+fontSize+'*(1vh + 1vw))'}"></div>
  </div>
</template>
<script>
export default {
  name: 'LbrxSubMenuButton',
  props: {
      fontSize:{
          required: true,
          value: String
      },
      height:{
          required: true,
          value: String
      },
      width:{
          required: true,
          value: String
      },
      background:{
          required: true,
          value: String
      },
      color:{
          required: true,
          value: String
      },
      label:{
          required: false,
          value: String
      },
      icon:{
          required: false,
          value: String
      },
      modelValue:Boolean,
  },
  emits: ['clicked'],
  methods: {
      getIcon() {
          if(this.icon) {
              var images = require.context('../assets/')
              return images('./icons/' + this.icon + ".svg")
          }
      },
  }
}
</script>

<style scoped>
@media (max-width: 1200px) {
 .sub-label{
     display: none;
 }
  .icon-style{
      height: 3vmin !important;
      margin-top: 0.4vmin !important;
  }
  .status {
      margin-top: 1.7vmin !important;
  }
}

.lbrx-button{
  border: none;
  font-family: 'Exo 2',serif;
  font-style: normal;
  /* identical to box height */
  border-radius: 50%;
  padding-left: 0.7vw;
  padding-right: 0.7vw;
  padding-bottom: 0.9vh;
}
.icon-style{
  z-index: 99999;
  margin-right: 0.3vmin;
}
.button-container {
  margin-bottom:1vmin;
  margin-left: 6vmin;
  margin-right: 6vmin;
  display: flex;
  justify-content: center;

}
</style>