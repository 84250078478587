import {ApiConfigs, authHeader} from "../_helpers";

export const meetService = {
    joinMember,
    joinParticipant,
    checkRoomName,
    checkPinCode,
    getBookingByRange,
    acceptParticipant,
    getMeetMessages,
    sendMeetMessage,
    sendMeetMessageParticipant,
    endCallbyId,
    logoutParticipant,
    sendFeedback,
    changeCamStatus,
    changeMicStatus,
    downloadFile,
    downloadFileParticipant,
};

function joinMember(payload: object) {
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.meet.join}`,
        requestOptions
    )
        .then(handleResponse)
        .catch(handleRejected);
}

function joinParticipant(payload: object) {
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.join}`,
        requestOptions
    )
        .then(handleResponse)
        .catch(handleRejected);
}


function checkRoomName(room_name: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.checkRoomName.replace(':room', room_name)}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}


function checkPinCode(payload: object) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.checkPinCode}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getBookingByRange(start: string, end: string) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({start: start, end: end})
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.meet.getByRange}`, requestOptions).then(handleResponse)
}

function acceptParticipant(payload: object) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.meet.acceptParticipant}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getMeetMessages(id: string, per_page: number, current_page: number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.getMeetMessages.replace(':id', id)}?per_page=${per_page}&current_page=${current_page}`,
        requestOptions
    ).then(handleResponse).catch(handleRejected);
}

function sendMeetMessage(payload: object) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.meet.sendMeetMessage}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function sendMeetMessageParticipant(payload: object) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.sendMeetMessage}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function endCallbyId(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.endCallbyId.replace(':id', id)}`,
        requestOptions
    ).then(handleResponse).catch(handleRejected);
}

function sendFeedback(id: string, payload: object) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_user_url+ApiConfigs.meet.sendFeedback.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function logoutParticipant() {
    // remove user from local storage to log user out
    localStorage.removeItem("customer");
}

function changeCamStatus(id: string, from: string, status: boolean) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader() }
    };
    const path = from == 'agent' ? ApiConfigs.base_url : ApiConfigs.base_user_url;
    return fetch(`${path + ApiConfigs.meet.changeCamStatus.replace(':id', id)+status}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function changeMicStatus(id: string, from: string, status: boolean) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader() }
    };
    const path = from == 'agent' ? ApiConfigs.base_url : ApiConfigs.base_user_url;
    return fetch(`${path + ApiConfigs.meet.changeMicStatus.replace(':id', id)+status}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function downloadFile(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.downloadFile.replace(':id', id)}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}

function downloadFileParticipant(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.meet.downloadFileParticipant.replace(':id', id)}`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleRejected(exception: any) {
    console.log(exception);
}
