<template>
    <div>
      <div class="main-container">
        <div style="display: inline-grid; height: 95vh; align-content: center;">
          <div class="text" style="display: grid">
            <span class="text-span">404</span>
            <p>The requested page is not found</p>
            <p>please <span style="color: #fff; text-decoration-line: underline; cursor: pointer;">click here</span> to go back to home page.</p>
          </div>
        </div>
      </div>
      <div class="footer">
        <img src="/images/liberrex_white.png" alt="" class="logo-liberrex">
      </div>
    </div>
  </template>
    
  <script>
  import router from "@/router";
  
  export default {
    name: "notFound",
    data() {
      return {
        business: null,
        member: null,
        ratingConfig: null,
        meet: null,
        rated: false,
        room: {
          room_name: this.$route.params.room_name,
          date_time: ''
        },
        // Set mobile breakpoint threshold
        mobileBreakpoint: 768,
      };
    },
    mounted() {
      // Add an event listener to update the computed property on window resize
      window.addEventListener('resize', this.handleWindowResize);
      // Initial check on component mount
      this.handleWindowResize();
    },
    beforeUnmount() {
      // Remove the event listener when the component is destroyed
      window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
      handleWindowResize() {
        // Update the computed property on window resize
        this.isMobileView = window.innerWidth < this.mobileBreakpoint;
      }
    },
  
  
  };
  </script>
    
  <style scoped>
  .main-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #202124;
  }
  .footer {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    height: 5vh;
    margin: 6vh 0;
  }
  .logo-liberrex {
    position: relative;
    height: 100%;
  }
  .text-span {
    font-size: 10rem;
    font-weight: 700;
    letter-spacing: 0em;
    height: 210px;
    color: var(--dark-gray);
  }
  .text p {
    font-size: 26px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0em;
    color: var(--dark-gray);
  }
  </style>
    