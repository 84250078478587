<template>
  <div v-if="showLoginPage" class="main-container" :style="isMobileView ? 'overflow-y: scroll; overflow-x: hidden;' : ''">
    <div class="top">
      <div class="top-div" :style="isMobileView ? 'top: 30%;' : ''">
        <div>
          <img src="/images/liberrex.png" :style="isMobileView ? 'padding: 0 40px; height: 30px;' : 'padding: 0 40px; height: 60px;'">
        </div>
        <div style="display: flex; align-items:center; margin: 0 50px;">
          <img src="../../assets/icons/help.svg" alt="" :style="isMobileView ? 'width: 20px;' : 'width: 30px;'">
          <span style="margin-left: 10px; min-width: fit-content;" :style="isMobileView ? 'font-size: 15px; text-wrap: nowrap;' : ''">I need help</span>
        </div>
      </div>
    </div>
    <div :class="isMobileView ? 'body-mobile' : 'body'">
      <div :class="isMobileView ? 'body-video-mobile' : 'body-video'">
        <div>
          <div class="video-div" :style="isMobileView ? 'position: relative; height: 40vh;' : 'position: relative; height: 64vh; width: 85vh; display: flex; margin: auto;'">
            <video :style="isCamHidden || camFeedStaring ? 'display:none' : ''" ref="video" id="video" autoplay></video>
            <div :style="!isCamHidden && !camFeedStaring ?'display:none':''" :id="isMobileView ? 'no-video-mobile' : 'no-video'">
              <div  class="no-cam-text">
                <span v-if="isCamHidden">Camera is off</span>
                <span v-if="camFeedStaring">camera starting</span>
              </div>

            </div>
            <div class="middle" style="position: absolute; bottom: 10px; width: 100%;">
              <button class="circle-button" :style="isMuted && noAudioDevice ? 'background-color: #4c4949' : isMuted ? 'background-color: rgb(255, 255, 255, 0.5);' : ''"
                      @click="toggleMute"><img
                  :src="require(isMuted ? '../../assets/icons/meet-button-mic-off-black.svg' : '../../assets/icons/meet-speaker.svg')"
                  alt="">
                  <div v-if="micPermissionDenied" class="circle-danger" style="position: absolute; top: -5px; right: -5px; padding-bottom: 20px;">!</div>
              </button>
              <button class="circle-button" :style="isCamHidden && noVideoDevice ? 'background-color: #4c4949' : isCamHidden ? 'background-color: rgb(255, 255, 255, 0.5);' : ''"
                      @click="toggleCam"><img
                  :src="require(isCamHidden ? '../../assets/icons/meet-button-camera-off-black.svg' : '../../assets/icons/meet-cam.svg')"
                  alt="">
                  <div v-if="camPermissionDenied" class="circle-danger" style="position: absolute; top: -5px; right: -5px; padding-bottom: 20px;">!</div>
                </button>
            </div>
          </div>


          <div class="footer">
            <div style="align-items: center;height: 100%; justify-content: space-between;">
              <div style="font-size: 20px;">

              </div>
              <div :style="isMobileView ? 'font-size: 10px; display: flex; justify-content: center;' : 'font-size: 13px; position: relative;display: inline-flex;'">
    
                    <div id="mySelect" @click="openOption('mic')" class="custom-dropdown" :style="this.noSpeakerDevice || this.permissionSpeaker ? getDynamicStylesOptionsMDenied : getDynamicStylesOptionsM" @mouseover="micHover = true"  @mouseout="micHover = false">
                      <iconSpeaker :color="'#000'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
                      <span v-if="this.noSpeakerDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Speaker not found</span>
                      <span v-else-if="this.permissionSpeaker" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
                      <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('mic', selectedAudioId)">{{ getDeviceName("mic", selectedAudioId)}}</span>
                      <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down-black.svg" alt="">
                      <div v-if="showMicOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="micHover = false">
                      <div @click="micHover = false;updateCam(selectedCamId, mic.deviceId)" class="cam-options" :style="selectedAudioId == mic.deviceId ? 'color: var(--primary)' : ''" v-for="(mic, index) in audios" :key="index" :value="mic.deviceId"><iconTick :color="selectedAudioId == mic.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ mic.label || 'Audio ' + (index + 1) }}</div>
                    </div>
                    </div>
                    <div id="mySelect" @click="openOption('speaker')" class="custom-dropdown" :style="this.noAudioDevice || this.permissionAudio ? getDynamicStylesOptionsSDenied : getDynamicStylesOptionsS" @mouseover="speakerHover = true"  @mouseout="speakerHover = false">
                      <iconMicV2 :color="'#000'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
                      <span v-if="this.noAudioDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Audio not found</span>
                      <span v-else-if="this.permissionAudio" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
                      <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('speaker', selectedAudioOutputId)">{{ getDeviceName("speaker", selectedAudioOutputId)}}</span>
                      <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down-black.svg" alt="">
                      <div v-if="showSpeakerOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="speakerHover = false">
                      <div @click="micHover = false;updateSpeaker(speaker.deviceId)" class="cam-options" :style="selectedAudioOutputId == speaker.deviceId ? 'color: var(--primary)' : ''" v-for="(speaker, index) in speakers" :key="index" :value="speaker.deviceId"><iconTick :color="selectedAudioOutputId == speaker.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ speaker.label.split('(')[0] || 'Speaker ' + (index + 1) }}</div>
                    </div>
                    </div>
                    <div id="mySelect" @click="openOption('cam')" class="custom-dropdown" :style="this.noVideoDevice || this.permissionVideo ? getDynamicStylesOptionsCDenied : getDynamicStylesOptionsC" @mouseover="camHover = true"  @mouseout="camHover = false">
                      <iconCamera :color="'#000'" :size="isMobileView ? 14 : 24" style="margin: 0 5px"/>
                      <span v-if="this.noVideoDevice" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Camera not found</span>
                      <span v-else-if="this.permissionVideo" :style="isMobileView ? 'margin: 0;' : ''" title="Camera not found">Permission needed</span>
                      <span v-else :style="isMobileView ? 'margin: 0;' : ''" :title="getDeviceName('cam', selectedCamId)">{{ getDeviceName("cam", selectedCamId) }}</span>
                      <img :style="isMobileView ? 'margin: 0;' : ''" src="../../assets/icons/meet-arrow-down-black.svg" alt="">
                      <div v-if="showCamOption" style="position: absolute; bottom: 100%; left: 0; box-shadow: 0px 4px 4px 0px #00000040;" @mouseover="camHover = false">
                      <div @click="micHover = false;updateCam(camera.deviceId, selectedAudioId)" class="cam-options" :style="selectedCamId == camera.deviceId ? 'color: var(--primary)' : ''" v-for="(camera, index) in cameras" :key="index" :value="camera.deviceId"><iconTick :color="selectedCamId == camera.deviceId ? 'var(--primary)' : 'none'" :size="isMobileView ? 14 : 24" style="margin: 0 10px"/>{{ camera.label.split('(')[0] || 'Camera ' + (index + 1) }}</div>
                    </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="isMobileView ? 'body-login-mobile' : 'body-login'">
        <div>
          <h3 :style="isMobileView ? 'font-size: 14px; font-weight: 700; min-width: max-content;' : 'font-size: 26px; font-weight: 700;'">Please enter to 6 digit PIN to join the call</h3>
          <v-otp-input
              ref="otpInput"
              v-model:value="pinCode"
              :input-classes="isMobileView ? 'otp-input-mobile' : 'otp-input'"
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
          />
          <div style="text-align: left; padding: 0 10px;">
            <span :class="isMobileView ? 'info-text-mobile' : 'info-text'">The 6 digit PIN should be sent to <span style="font-weight: 600;"> your phone number or E-mail address </span> by your agent. <span style="text-decoration: underline;">If you don’t have the 6 digit PIN please contact your agent to request a new PIN code</span> .</span>
          </div>
          <button @click="checkPinCode" class="join-button">
            <div v-if="loading"><iconLoader :color="'#fff'" :size="isMobileView ? 30 : 35" style="margin: 0 5px"/></div>
            <div v-else>join</div>
          </button>
          <div :style="!pinInvalid ? 'visibility: hidden;' : ''">
            <span style="color: #c12020;">Invalid PIN</span>
          </div>
        </div>
      </div>
      <div v-if="isMobileView" class="bottom-mobile">
      <span>By joining the meeting, you agree to the <span style="color: var(--primary);"> Terms of Service </span> and <span
          style="color: var(--primary);"> Privacy Policy</span>. </span>
    </div>
    </div>
    <div v-if="!isMobileView" class="bottom">
      <span>By joining the meeting, you agree to the <span style="color: var(--primary);"> Terms of Service </span> and <span
          style="color: var(--primary);"> Privacy Policy</span>. </span>
    </div>
    <lbrx-popup-meet :active="modal.active" :type="modal.type" :hint="modal.hint" @close="close" :device="modal.device"
     ></lbrx-popup-meet>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import router from "@/router";
import VOtpInput from "vue3-otp-input";
import {meetService, openviduService} from "@/_services";
import iconMicV2 from "../../components/icons/micV2.vue";
import iconCamera from "../../components/icons/camera.vue";
import iconSpeaker from "../../components/icons/speaker.vue";
import iconTick from "../../components/icons/tick.vue";
import iconLoader from "../../components/icons/loader.vue";
import LbrxPopupMeet from "@/components/LbrxPopupPermission.vue";
import MediaDevices from 'media-devices'

export default {
  name: "LoginPage",
  inject: ['echo'],
  data() {
    return {
      //
      pinCode: "",
      meet: null,
      customer: null,
      video: {},
      isMuted: false,
      isCamHidden: false,
      camPermissionDenied: false,
      micPermissionDenied: false,
      requestSent: false,
      camFeedStaring: true,
      loading: false,
      // Set mobile breakpoint threshold
      mobileBreakpoint: 768,
      cameras: [],
      videoDevices: [],
      speakerDevices: [],
      audioDevices: [],
      pinInvalid: false,
      selectedCamId: null,
      selectedAudioOutputId: null,
      selectedAudioId: null,
      showLoginPage: false,
      showCamOption: false,
      showMicOption: false,
      showSpeakerOption: false,
      camHover: false,
      micHover: false,
      speakerHover: false,
      devicesLoaded: false,
      modal: {
        active: false,
        type: "request",
        device: ""
      },
      noVideoDevice: false,
      noAudioDevice: false,
      noSpeakerDevice: false,
      permissionVideo: false,
      permissionAudio: false,
      permissionSpeaker: false,
    }
  },
  components: {
    VOtpInput,
    iconCamera,
    iconMicV2,
    iconSpeaker,
    iconTick,
    LbrxPopupMeet,
    iconLoader
  },
  computed: {
    isMobileView() {
      return window.innerWidth <= this.mobileBreakpoint;
    },
    getDynamicStylesOptionsM() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.micHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.micHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsS() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.speakerHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.speakerHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsC() {
      // Define your styles based on conditions
      return {
        backgroundColor: this.camHover ? 'rgba(0, 194, 255, 0.1)' : '',
        boxShadow: this.camHover ? '0 0 0 1px #dedede' : '',
        margin: this.isMobileView ? '0px' : '',
        padding: this.isMobileView ? '5px 0px' : ''
      };
    },
    getDynamicStylesOptionsCDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
    getDynamicStylesOptionsMDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
    getDynamicStylesOptionsSDenied() {
      // Define your styles based on conditions
      return {
        opacity: '0.7',
        boxShadow: '0 0 0 1px #dedede',
        pointerEvents: 'none'
      };
    },
  },
  watch: {},
  created() {
    this.checkPermessions();
    localStorage.setItem("cam", true);
    localStorage.setItem("mic", true);
    // if(JSON.parse(localStorage.getItem("cam")) == null) {
    //   this.isCamHidden = true;
    //   this.isMuted = true;
    //   localStorage.setItem("cam", false);
    //   localStorage.setItem("mic", false);
    // } else {
    //   this.isCamHidden = !JSON.parse(localStorage.getItem("cam"));
    //   this.isMuted = !JSON.parse(localStorage.getItem("mic"));
    // }

    this.camFeedStaring = !this.isCamHidden ? true : false;
    this.checkRoomCode();
    this.getDevices();
  },
  mounted() {
    localStorage.setItem('room_code', this.$route.params.room_name);
    this.initCam();
    // Add an event listener to update the computed property on window resize
    window.addEventListener('resize', this.handleWindowResize);
    // Initial check on component mount
    this.handleWindowResize();
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    listenForDevicesChange() {
      MediaDevices.ondevicechange = ({ changes, devices }) => {
        console.log("changes, ", changes);
        console.log("devices, ", devices);
        this.getDevicesDynamically(devices);
        setTimeout(() => {
          changes.map((change) => {
            if(change.type == "add") {
              // if(change.newInfo.kind == "audioinput")
              if(change.device.kind == "audiooutput") {
                console.log('eeeeeeeeeeeeeee, ', change.device.deviceId)
                this.updateSpeaker(change.device.deviceId);
              }
              if(change.device.kind == "audioinput") {
                console.log('eeeeeeeeeeeeeee, ', change.device.deviceId)
                this.updateCam(this.selectedCamId, change.device.deviceId);
              }
              // else
              //   this.updateCam(change.newInfo.groupId, this.selectedAudioId);
            }
            if(change.type == "remove") {
              if(change.device.kind == "audiooutput") {
                this.updateSpeaker(this.speakers[0].deviceId);
              }
              if(change.device.kind == "audioinput") {
                this.updateCam(this.selectedCamId, this.audios[0].deviceId);
              }
            }
          });
        }, 100);
          
      }
    },
    getDevicesDynamically(devices) {
      this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
        this.speakerDevices = devices.filter((device) => device.kind === 'audiooutput');
        this.audioDevices = devices.filter((device) => device.kind === 'audioinput');

          // remove ducplicates
          this.cameras = this.videoDevices.reverse().filter((obj, index) => {
            return index === this.videoDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.speakers = this.speakerDevices.reverse().filter((obj, index) => {
            return index === this.speakerDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.audios = this.audioDevices.reverse().filter((obj, index) => {
            return index === this.audioDevices.findIndex(o => obj.groupId === o.groupId);
          });
    },
    async getDevices() {
      try {
        // Enumerate devices and filter for video input devices
        const devices = await MediaDevices.enumerateDevices();
        console.log("deeeeee : ", devices)
        this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
        this.speakerDevices = devices.filter((device) => device.kind === 'audiooutput');
        this.audioDevices = devices.filter((device) => device.kind === 'audioinput');

          // remove ducplicates
          this.cameras = this.videoDevices.reverse().filter((obj, index) => {
            return index === this.videoDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.speakers = this.speakerDevices.reverse().filter((obj, index) => {
            return index === this.speakerDevices.findIndex(o => obj.groupId === o.groupId);
          });
          this.audios = this.audioDevices.reverse().filter((obj, index) => {
            return index === this.audioDevices.findIndex(o => obj.groupId === o.groupId);
          });
          // console.log("====================", devices)
          // console.log('booooooooooooo : ', this.getDevicesConfig())
          if(this.getDevicesConfig()) {
            const devicesStored = this.getDevicesConfig();
            this.selectedCamId = devicesStored.selectedCamId;
            this.selectedAudioOutputId = devicesStored.selectedAudioOutputId;
            this.selectedAudioId = devicesStored.selectedAudioId;
          }

          //   if(!this.selectedCamId) {
          //     if(this.cameras.length != 0) {
          //       this.selectedCamId = this.cameras[0].deviceId;
          //     }
          //   }
          //   if(!this.selectedAudioOutputId) {
          //     if(this.speakers.length != 0) {
          //       this.selectedAudioOutputId = this.speakers[0].deviceId;
          //     }
          //   }
          //   if(!this.selectedAudioId) {
          //     if(this.audios.length != 0) {
          //       this.selectedAudioId = this.audios[0].deviceId;
          //     }
          //   }
            
          // } else {
            if(this.cameras.length != 0) {
              const camExists = this.cameras.find((element) => element.deviceId == this.selectedCamId);
              if(camExists == undefined) {
                this.selectedCamId = this.cameras[0].deviceId;
              }
            }
            if(this.speakers.length != 0) {   
              const audioOutputExists = this.speakers.find((element) => element.deviceId == this.selectedAudioOutputId);
              if(audioOutputExists == undefined) {
                this.selectedAudioOutputId = this.speakers[0].deviceId;
              }
            }
            if(this.audios.length != 0) {
              const audioExists = this.audios.find((element) => element.deviceId == this.selectedAudioId);
              if(audioExists == undefined) {
                this.selectedAudioId = this.audios[0].deviceId;
              }
            }
          // }
          console.log('pooooooooooooo : ', this.selectedAudioId)
          this.devicesLoaded = true;
          this.saveDevicesConfig();
       

        
      } catch (error) {
        console.error('Error enumerating devices:', error);
      }
    },
    handleWindowResize() {
      // Update the computed property on window resize
      this.isMobileView = window.innerWidth < this.mobileBreakpoint;
    },
    getDeviceName(type, id) {

      if(type == "cam" && this.cameras) {
        const cam = this.cameras.find(obj => {
          if(obj.deviceId == id) {return obj}
        });
        return cam ? cam.label : 'No device selected';
      } else if(type == "mic" && this.audios) {
        const audio = this.audios.find(obj => {
          if(obj.deviceId == id) {return obj}
        });
        return audio ? audio.label : 'No device selected';
      } else if(this.speakers) {
        const speaker = this.speakers.find(obj => {
          if(obj.deviceId == id) {return obj.label}
        });
        return speaker ? speaker.label : 'No device selected';
      }
    },
    openOption(type) {
      if(type == "cam") {
        this.showCamOption = !this.showCamOption;
      } else if(type == "mic") {
        this.showMicOption = !this.showMicOption;
      } else {
        this.showSpeakerOption = !this.showSpeakerOption;
      }
    },
    checkPermessions() {
      let videoStreamVar;
      let videoStreamVar2;
      try {

      navigator.mediaDevices.getUserMedia({ video: true })
        .then((videoStream) => {
          videoStreamVar = videoStream;
          console.log('Video permission granted');
          // Revoke the tracks to turn off the camera
          if (videoStream) {
            const tracks = videoStream.getTracks();
            tracks.forEach((track) => track.stop());
          }
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionVideo = true;
              this.camPermissionDenied = true;
              this.isCamHidden = true;
              this.camFeedStaring = false;
              break;
            case 'NotFoundError':
              this.noVideoDevice = true;
              this.isCamHidden = true;
              this.camPermissionDenied = true;
              this.camFeedStaring = false;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
            // Revoke the tracks to turn off the camera
            if (videoStreamVar) {
              const tracks = videoStreamVar.getTracks();
              tracks.forEach((track) => track.stop());
            }
          }
        });

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((audioStream) => {
          console.log('audio permission granted');
          setTimeout(() => {
            audioStream.getTracks().forEach(track => track.stop());
            console.log('Audio stream stopped');
          },200);
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionAudio = true;
              this.isMuted = true;
              this.micPermissionDenied = true;
              break;
            case 'NotFoundError':
              this.noAudioDevice = true;
              this.isMuted = true;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
            
          }
        });

      navigator.mediaDevices.getUserMedia({ audio: { output: true } })
        .then((videoStream) => {
          console.log('Speaker permission granted');

          // Do something with the video stream if needed

          // Now, request permission for audio
        })
        .catch((err) => {
          console.error('Permission denied or error:', err);

          // You can check the error name to determine if it's a permission denied error
          switch (err.name) {
            case 'NotAllowedError':
              this.permissionSpeaker = true;
              break;
            case 'NotFoundError':
              this.noSpeakerDevice = true;
              break;
            // Handle other error cases as needed
            default:
              console.error('Unexpected error:', err);
          }
        });
      } catch (error) {
        console.warn(error)
      }
    },
    initCam() {
      setTimeout(() => {
      
      this.video = this.$refs.video;

      const constraints = {
            video: !this.isCamHidden ? { deviceId: { exact: this.selectedCamId } } : false,
            audio: !this.isMuted ? { deviceId: { exact: this.selectedAudioId } } : false,
        };
        console.log("mlmlmlmlmlmlmlm : " , !(!constraints.video && !constraints.audio))
        console.log("mlmlmlmlmlmlmlm22 : " , constraints.video, constraints.audio)
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && !(!constraints.video && !constraints.audio)) {
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.camFeedStaring = false;
          console.log("stream" , this.video)
            this.video.srcObject = stream;
            this.video.play();
 
  

          // Access the video track and get information about the camera
          // const videoTrack = stream.getVideoTracks()[0];
          // const videoSettings = videoTrack.getSettings();
          // this.selectedCamId = videoSettings.deviceId;

          // Access the audio track and get information about the microphone
          // const audioTrack = stream.getAudioTracks()[0];
          // const audioSettings = audioTrack.getSettings();
          // this.selectedAudioId = audioSettings.deviceId;
          
          // If you want to further process or manipulate the audio, you can do it here
          // For example, you can add an audio processing node to apply effects.
          
          // To stop the audio output completely, you can mute the video element
          this.video.muted = true;
          this.listenForDevicesChange();
        }).catch(error => {
          console.error('Error accessing media devices:', error);
        });
        // if(!this.video.srcObject) {
        //     this.camFeedStaring = false;
        //     this.isCamHidden = true;
        // }
      }
    }, 2000);
    },
    openCam() {
      this.video = this.$refs.video;
      if(!this.isCamHidden) {
        this.camFeedStaring = true;
      }
      const constraints = {
            video: !this.isCamHidden ? { deviceId: { exact: this.selectedCamId } } : false,
            audio: !this.isMuted ? { deviceId: { exact: this.selectedAudioId } } : false,
        };
        this.saveDevicesConfig();
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.camFeedStaring = false;
          console.log("stream" , this.video)
            this.video.srcObject = stream;
            this.video.play();
 
  

          // Access the video track and get information about the camera
          // const videoTrack = stream.getVideoTracks()[0];
          // const videoSettings = videoTrack.getSettings();
          // this.selectedCamId = videoSettings.deviceId;

          // Access the audio track and get information about the microphone
          // const audioTrack = stream.getAudioTracks()[0];
          // const audioSettings = audioTrack.getSettings();
          // this.selectedAudioId = audioSettings.deviceId;
          
          // If you want to further process or manipulate the audio, you can do it here
          // For example, you can add an audio processing node to apply effects.
          
          // To stop the audio output completely, you can mute the video element
          this.video.muted = true;
        }).catch(error => {
          console.error('Error accessing media devices:', error);
        });
      }
    },
    async updateSpeaker(deviceId) {
      // Set the audio output device for the video element
      this.selectedAudioOutputId = deviceId;
      if (this.selectedAudioOutputId) {
        await this.$refs.video.setSinkId(this.selectedAudioOutputId);
        this.saveDevicesConfig();
      }
    },
    updateCam(deviceIdCam, deviceIdMic) {
      if(this.selectedCamId != deviceIdCam || this.selectedAudioId != deviceIdMic) {
        this.video = this.$refs.video;
        if(!this.isCamHidden) {
          this.camFeedStaring = true;
        }
        this.selectedCamId = deviceIdCam;
        this.selectedAudioId = deviceIdMic;
        console.log("navigator.mediaDevices.getUserMedia", this.selectedCamId)
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: {deviceId: { exact: this.selectedCamId }}, audio: {deviceId: { exact: this.selectedAudioId }} }).then(stream => {
            this.camFeedStaring = false;
            console.log(stream)
            this.video.srcObject = stream;
            this.video.play();
            this.saveDevicesConfig();
            
            // To stop the audio output completely, you can mute the video element
            this.video.muted = true;
          }).catch(error => {
            console.error('Error accessing media devices:', error);
          });
        }
      }
    },
    updateAudioInput() {
      this.video = this.$refs.video;
      const constraints = {
            video: !this.isCamHidden ? { deviceId: { exact: this.selectedCamId } } : false,
            audio: !this.isMuted ? { deviceId: { exact: this.selectedAudioId } } : false,
        };
      console.log("navigator.mediaDevices.getUserMedia", this.selectedCamId)
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          this.camFeedStaring = false;
          console.log(stream)
          this.video.srcObject = stream;
          this.video.play();
          this.saveDevicesConfig();
          
          // To stop the audio output completely, you can mute the video element
          this.video.muted = true;
        }).catch(error => {
          console.error('Error accessing media devices:', error);
        });
      }
    },
    toggleCam() {
      if(this.camPermissionDenied) {
        this.modal.active = true;
        this.modal.device = "camera";
      } else {
        this.isCamHidden = !this.isCamHidden;
        localStorage.setItem('cam',!this.isCamHidden);
        if(this.isCamHidden) {
          this.closeCam();
        } else {
          this.openCam();
        }
      }
    },
    closeCam() {
      // Stop the video stream and release resources
      this.camFeedStaring = false;
      if (this.video && this.video.srcObject) {
        const tracks = this.video.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        this.video.srcObject = null;
      }
    },
    toggleMute: function () {
      if(this.micPermissionDenied) {
        this.modal.active = true;
        this.modal.device = "microphone";
      } else {
        this.isMuted = !this.isMuted;
        localStorage.setItem('mic',!this.isMuted)
        this.openCam();
      }
    },
    checkRoomCode() {
      meetService.checkRoomName(this.$route.params.room_name)
          .then((res) => {
            if(res.error) {
              router.push({path: "/404"});
            }
            this.meet = res.meet;
            this.customer = res.customer;
            localStorage.setItem("customer", JSON.stringify(this.customer));
            localStorage.setItem("member", JSON.stringify(res.member));

            localStorage.setItem("meet", JSON.stringify(this.meet));
            this.showLoginPage = true;
            if(res.config) {
              localStorage.setItem("config", JSON.stringify(res.config));
            }
          })
          .catch((err) => {
            console.log(err);
            router.push({path: "/404"});
          })
    },
    checkPinCode() {
      this.loading = true;
      this.pinInvalid = false;
      let payload = {
        id: this.meet.id,
        code_pin: this.pinCode
      }
      meetService.checkPinCode(payload)
          .then((res) => {
            if (res != undefined) {
              localStorage.removeItem("user");
              // this.$nextTick(() => {
              //   this.requestSent = true;
              // })
              localStorage.setItem("isPending", JSON.stringify(true));
              localStorage.setItem("isAgent", JSON.stringify(false));
              this.enterCallRoom(this.$route.params.room_name)
            } else {
              this.pinInvalid = true;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    saveDevicesConfig() {
      localStorage.setItem("devicesInUse", JSON.stringify({ 
        selectedCamId: this.selectedCamId, 
        selectedAudioId: this.selectedAudioId, 
        selectedAudioOutputId: this.selectedAudioOutputId
      }));
    },
    getDevicesConfig() {
      return JSON.parse(localStorage.getItem("devicesInUse"));
    },
    enterCallRoom(room_name) {
      this.closeCam();
      router.push({path: "/room/" + room_name});
    },
    handleOnChange() {
      console.log('rrrrrrrr')
    },
    handleOnComplete() {
      this.checkPinCode();
    },
    close() {
      this.modal.active = false;
    }
  }
}
</script>

<style scoped>


.main-container {
  width: 100vw;
  height: 100vh;
}

.top {
  height: 15vh;
  position: relative;
}

.top-div {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
}

.body {
  height: 80vh;
  display: flex;
}
.body-mobile {
  height: 80vh;
  display: grid;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

#no-video {
  /* margin-top: 5vmin !important;
  margin-bottom: 15px !important; */
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #202124;;
  margin: auto;
}
#no-video-mobile {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #202124;
}

.no-cam-text{
  font-size: 26px;
  color: white;
  height: 100%;
  width: 100%;
  align-content: center;
  display: grid;
}


.body-video {
  width: 57vw;
  top: 50%;
}
.body-video-mobile {
  width: 100vw;
  padding: 0 15px;
  top: 50%;
}

.body-login {
  width: 40vw;
  padding: 0 5vw;
  align-self: center;
}

.body-login-mobile {
  width: 100%;
  padding: 0 5vw;
  grid-row: 1;
}

.circle-button {
  width: 50px; /* Set the width and height to create a circle */
  height: 50px;
  margin: 7px 7px;
  position: relative;
  background-color: rgba(0, 0, 0, 0%); /* Button background color */
  border-radius: 50%; /* Make the button a perfect circle */
  border: 1px solid rgb(255, 255, 255, 0.5);;
  cursor: pointer;
}
.circle-danger {
  width: 20px; /* Set the width and height to create a circle */
  height: 20px;
  margin: 3px 3px;
  position: relative;
  font-size: 13px;
  background-color: red; /* Button background color */
  color: #fff;
  border-radius: 50%; /* Make the button a perfect circle */
  cursor: pointer;
}

.footer {
  width: 100%;
  margin-top: 10px;
}

.footer span {
  margin: 0 10px;
}

.footer img {
  margin: 0 7px;
}

.bottom {
  height: 5vh;
}
.bottom-mobile {
  height: 5vh;
  display: flex;
  font-size: 12px;
  width: 100%;
  margin: 20px 0;
  padding: 0 15vw;
}

.info-text {
  color: #8A8A8A;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: justified;
}
.info-text-mobile {
  color: #8A8A8A;
  font-size: 13px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  display: block;
}

.join-button {
  padding: 5px 0;
  width: 50%;
  background-color: var(--primary);
  color: #fff;
  border-radius: 10px;
  border: none;
  margin-top: 25px;
  margin-bottom: 10px;
  font-family: Exo 2;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;

}


</style>

<style>
/* otp */

.otp-input {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin: 20px 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input-mobile {
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  margin: 20px 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}

.custom-dropdown {
  max-width: fit-content; position: relative; border: none; 
  padding: 5px 5px;
    margin: 10px;
    border-radius: 20px;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.7);
    top: -100%;
}
.custom-dropdown span {
  display: block ruby;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cam-options {
  background-color: #fff; 
  padding: 10px; 
  display: flex; 
  font-size: 14px;
}
.cam-options:hover {
  background-color: rgb(231, 231, 231);
}
</style>
    