import {ApiConfigs} from "../_helpers";

export const vendorsService = {
    getVendorById
};


function getVendorById(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: {"Content-Type": "application/json"},
    };

    return fetch(
        `${ApiConfigs.base_user_url + ApiConfigs.vendors.getVendorById.replace(':id', id)}?append=rating`,
        requestOptions
    )
        .then(handleResponse).catch(handleRejected);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleRejected(exception: any) {
    console.log(exception);
}
