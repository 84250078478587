import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginPageWithCaptcha from '../views/login/LoginPageWithCaptcha.vue'
import agentDashboard from "@/views/home/AgentDashboard.vue";
import MaintenacePage from "@/views/maintenance/MaintenacePage.vue";
import customerLogin from "@/views/login/CustomerLogin.vue";
import MeetPage from "@/views/meet/meetPage.vue";
import MeetPageNew from "@/views/meet/meetPageNew.vue";
import Feedback from "@/views/meet/feedback.vue";
import notFound from "@/views/notfound/notFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPageWithCaptcha
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: MaintenacePage
  },
  {
    path: '/agent-dashboard',
    name: 'agentDashboard',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: agentDashboard
  },
  {
    path: '/:room_name',
    name: 'userLogin',
    meta: {
      authRequired: false,
      authorize: ["*"],
    },
    component: customerLogin
  },
  {
    path: '/room/:room_name',
    name: 'meet',
    meta: {
      authRequired: false,
      authorize: ["*"],
    },
    component: MeetPageNew
  },
  {
    path: '/room/:room_name/feedback',
    name: 'meetFeddback',
    meta: {
      authRequired: false,
      authorize: ["*"],
    },
    component: Feedback
  },
  {
    path: '/room/:room_name/feedback',
    name: 'meetFeddback',
    meta: {
      authRequired: false,
      authorize: ["*"],
    },
    component: Feedback
  },
  // Redirect to 404 page, if no match found
  {
    path: '/404',
    component: notFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  const userLoggedIn = localStorage.getItem('user') || null;
  const customerLoggedIn = localStorage.getItem('customer') || null;
  const isPending = localStorage.getItem('isPending') || null;
  const room_code = localStorage.getItem('room_code') || "123-123";
    if (to.meta.authRequired && userLoggedIn === null) {
      return next('/login');
    }

  if (to.path == '/login' && userLoggedIn !== null) {
    return next('/agent-dashboard');
  }

  if (to.name == 'meet') {
    if (userLoggedIn == null && (customerLoggedIn == null && isPending == null)) {
      return next(`/${room_code}`);
    }
    
  }

    if (to.name == "Host") {
        if(!process.env.VUE_APP_EDIT_HOST || process.env.VUE_APP_EDIT_HOST == "false") {
            return next('/home');
        }
    }

    next();
})

export default router
